import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ReactComponent as Home } from 'assets/img/home.svg'
import { ReactComponent as Doc } from 'assets/img/document.svg'
import { ReactComponent as User } from 'assets/img/user.svg'
import { ReactComponent as Post } from 'assets/img/post.svg'
import { ReactComponent as Settings } from 'assets/img/settings.svg'
import { ReactComponent as Message } from 'assets/img/message.svg'
import { ReactComponent as Logout } from 'assets/img/logout.svg'
import classNames from 'classnames'
import styles from './SideBarLink.scss'

const cx = classNames.bind(styles)

const iconPrint = text => {
	switch (text.toLowerCase()) {
		case 'home':
			return <Home />
		case 'doc':
			return <Doc />
		case 'user':
			return <User />
		case 'post':
			return <Post />
		case 'settings':
			return <Settings />
		case 'message':
			return <Message />
		case 'logout':
			return <Logout />
		default:
			return <Home />
	}
}

const SideBarLink = ({ text, icon, href, onClick = () => {} }) => {
	const location = useLocation()

	return (
		<Link
			onClick={onClick}
			className={cx(
				'sideBarLink',
				location.pathname.split('/')[1] === href.split('/')[1] &&
					'sideBarLink__active'
			)}
			to={href}
		>
			{iconPrint(icon)}
			<span className='sideBarLink__text'>{text}</span>
		</Link>
	)
}

export default SideBarLink

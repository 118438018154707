export const dashboardOptions = [
	{ value: 'tutorDiet', label: 'tutorDiet' },
	{ value: 'game', label: 'Тренировка памяти' },
	{ value: 'avitorg', label: 'Avitorg' },
	{ value: 'telegram', label: 'Telegram' },
	{ value: 'astrology', label: 'Астрология' },
	{ value: 'spy-heart', label: 'SpyHeart' },
	{ value: 'matrix', label: 'Матрица судьбы' },
	{ value: 'taro', label: 'Таро' },
	{ value: null, label: 'Без дашборда' }
]

export const variantPageOptions = [
	{ label: 'По дефолту', value: 'default' },
	{ label: 'Интервальное голодание', value: 'golodaem' },
	{ label: 'Crush Scanner', value: 'crushScanner' },
	{ label: 'Калькулятор-инвестиций', value: 'invest' },
	{ label: 'Finebelly', value: 'perfectBelly' },
	{ label: 'Мужские тренировки', value: 'brutalMale' },
	{ label: 'Озон', value: 'marketBaza' },
	{ label: 'Спина', value: 'heal4back' },
	{ label: 'Evo Visage', value: 'evoVisage' },
	{ label: 'Шпагат', value: 'naShpagat' },
	{ label: 'Bubby Buns', value: 'bubbyBuns' },
	{ label: 'Ya Brand', value: 'yaBrand' },
	{ label: 'Advertclass', value: 'advert' },
	{ label: 'How Eat Plan', value: 'howEatPlan' },
	{ label: 'ALIEXRPESS', value: 'marketPlacePro' },
	{ label: 'Ritm Fit', value: 'ritmFit' },
	{ label: 'NFT', value: 'NFT' },
	{ label: 'Огород', value: 'naOgorod' },
	{ label: 'SUPER VOLOS', value: 'superVolos' },
	{ label: 'STOP KURENIE', value: 'stopKurenie' },
	{ label: 'Английский', value: 'bazaEnglish' },
	{ label: 'Avitorg', value: 'avitorg' },
	{ label: 'Be Hard Lover', value: 'behardLover' },
	{ label: 'Не Сгори', value: 'neSgori' },
	{ label: 'Yoga', value: 'yoga' },
	{ label: 'All NightMan', value: 'allNightMan' },
	{ label: 'Stop Loss Hair', value: 'stopLossHair' },
	{ label: 'Tilda', value: 'tilda' },
	{ label: 'MyArcanum', value: 'myArcanum' },
	{ label: 'Vamp Woman', value: 'vamWoman' },
	{ label: 'EasyInterval', value: 'easyInterval' },
	{ label: 'Жир Стоп', value: 'fatStop' },
	{ label: 'Таблички', value: 'table' },
	{ label: 'Кетоплан', value: 'ketoplan' },
	{ label: 'ВБселлер', value: 'wbsaller' },
	{ label: 'MARKETSELLER', value: 'marketseller' },
	{ label: 'Booty WOW', value: 'bootyWow' },
	{ label: 'ASTRO', value: 'astro' },
	{ label: 'Целлюлит', value: 'cellulit' },
	{ label: 'Зубы', value: 'teeth' },
	{ label: 'Be Hot Girl', value: 'beHotGirl' },
	{ label: 'Витрина', value: 'showCase' },
	{ label: 'Beseducer', value: 'beseducer' },
	{ label: 'Profellation', value: 'profellation' },
	{ label: 'Titan plan', value: 'titanPlan' },
	{ label: 'TutorPlace', value: 'tutorPlace' },
	{ label: 'Нумерология', value: 'numerology' },
	{ label: 'Mediacourse', value: 'mediacourse' },
	{ label: 'freeLance', value: 'freelance' }
]

export const variantFooterOptions = [
	{ label: 'По дефолту', value: 'default' },
	{ label: 'Новые ссылки', value: 'new' },
	{ label: 'Ritm Tela', value: 'ritmtela' },
	{ label: 'Harbor', value: 'harbor' }
	// { label: 'Mediacourse', value: 'mediacourse' },
]

export const landFooterOptions = [
	{ label: 'default', value: '' },
	{ label: 'uk', value: '2' },
	{ label: 'it', value: '3' },
	{ label: 'ishutin', value: '4' },
	{ label: 'ishutin-dark', value: '4-dark' },
	{ label: 'ishutin-v2-dark', value: '4-v2-dark' },
	{ label: 'ishutin-payment', value: '4-payment' },
	{ label: 'simple', value: '5' },
	{ label: 'tilda', value: '6' },
	{ label: 'ritmtela', value: '7' },
	{ label: 'knowlab', value: '8' },
	{ label: 'default2', value: 'default2' },
	{ label: 'chmyhov', value: 'chmyhov' }
]

import { createSlice } from '@reduxjs/toolkit';
import { request } from 'api';
import { setRedirect } from './redirect';
import axios from 'axios';

const initialState = {
  isLoading: false,
  gateways: [],
  gatewaysMove: [],
  lastTransactionChecked: [],
  lastTransactionList: {},
  gatewayData: {},
  error: null,
  moveResult: '',
  payments: null,
  statusList: [],
  banks: [],
  adminLogs: [],
  moveResultSuccessMessage: null,
  filters: {
    status: '',
    companyIds: '',
    sberbill: '',
    sberroute: '',
    dateFrom: null,
    dateTo: null,
    dirty: '',
    recurrentStoppedIds: '',
    scheme: '',
  },
  pagination: {
    limit: '200',
    page: 1,
    pages: 1,
    total: 0,
  },
  gatewayStatistics: [],
  massUpdateStatus: '',
  errorMassUpdate: null,
  massUpdateMessage: '',
  ratesOption: [],
};

const gatewaySlice = createSlice({
  name: 'gateway',
  initialState,
  reducers: {
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setGatewayData(state, action) {
      state.gateways = action.payload.items;
      state.pagination.page = action.payload.pagination?.page;
      state.pagination.pages = action.payload.pagination?.pages;
      state.pagination.total = action.payload.pagination?.total;
    },
    setGatewayDataPage(state, action) {
      state.gateways = action.payload.items;
    },
    setGatewayMoveData(state, action) {
      state.gatewaysMove = action.payload;
    },
    setGatewayLastTransactionErrorsChecked(state, action) {
      state.lastTransactionChecked = action.payload;
    },
    setGatewayLastTransactionErrorsList(state, action) {
      state.lastTransactionList = action.payload;
    },
    setGatewayDataId(state, action) {
      state.gatewayData = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setMoveResult(state, action) {
      state.moveResult = action.payload;
    },
    setMoveResultSuccessMessage(state, action) {
      state.moveResultSuccessMessage = action.payload;
    },
    setPaymentOptions(state, action) {
      state.payments = action.payload;
    },
    setAdminLogs(state, action) {
      state.adminLogs = action.payload.items;
    },
    setGatewayStatuses(state, action) {
      state.statusList = Object.keys(action.payload).map((el) => ({
        value: el,
        label: action.payload[el],
      }));
    },
    setBanks(state, action) {
      state.banks = action.payload;
    },
    setGatewayPage(state, action) {
      state.pagination.page = action.payload;
    },
    setGatewayFilter(state, action) {
      state.filters.status = action.payload.status;
      state.filters.companyIds = action.payload.companyIds;
      state.filters.sberbill = action.payload.sberbill;
      state.filters.sberroute = action.payload.sberroute;
      state.filters.dateFrom = action.payload.dateFrom;
      state.filters.dateTo = action.payload.dateTo;
      state.filters.dirty = action.payload.dirty;
      state.filters.recurrentStoppedIds = action.payload.recurrentStoppedIds;
      state.filters.scheme = action.payload.scheme;
      state.pagination.limit = action.payload.limit;
      state.pagination.page = 1;
    },
    setGatewaysStatistics(state, action) {
      state.gatewayStatistics = action.payload;
    },
    setGatewaysMassUpdateStatus(state, action) {
      state.massUpdateStatus = action.payload;
    },
    setGatewaysMassUpdateFailure(state, action) {
      state.errorMassUpdate = action.payload;
    },
    setGatewaysMassUpdateMessageSuccess(state, action) {
      state.massUpdateMessage = action.payload;
    },
    setRates(state, action) {
      state.rates = Object.entries(action.payload)?.map((item) => ({
        value: item[0],
        label: item[1]
      })) || [];
    }
  },
});

const {
  reducer: gateway,
  actions: {
    setGatewaysMassUpdateFailure,
    setGatewaysMassUpdateStatus,
    setMoveResultSuccessMessage,
    setGatewayLastTransactionErrorsChecked,
    setGatewayLastTransactionErrorsList,
    setGatewayDataPage,
    setMoveResult,
    setIsLoading,
    setGatewayData,
    setError,
    setPaymentOptions,
    setGatewayStatuses,
    setGatewayDataId,
    setGatewayMoveData,
    setGatewayPage,
    setGatewayFilter,
    setAdminLogs,
    setBanks,
    setGatewaysStatistics,
    setGatewaysMassUpdateMessageSuccess,
    setRates
  },
} = gatewaySlice;

const getAllGateways = () => async (dispatch) => {
  dispatch(setIsLoading(true));
  return request
    .get('/admin/gateways?limit=10000')
    .then((res) => dispatch(setGatewayDataPage(res.data)))
    .catch((err) => dispatch(setError(err.response?.data?.error?.message ?? 'Ошибка при загрузке шлюзы')))
    .finally(() => dispatch(setIsLoading(false)));
};

const getAllGatewaysPage = (params) => async (dispatch) => {
  dispatch(setIsLoading(true));
  return request
    .get('/admin/gateways', { params })
    .then((res) => dispatch(setGatewayData(res.data)))
    .catch((err) => dispatch(setError(err.response?.data?.error?.message ?? 'Ошибка при загрузке шлюзы')))
    .finally(() => dispatch(setIsLoading(false)));
};

const getGatewaysMoveInfo = () => async (dispatch) => {
  dispatch(setIsLoading(true));
  return request
    .get('/admin/gateways/move-info')
    .then((res) => {
      dispatch(setGatewayMoveData(res.data.gateways));
      dispatch(setGatewayLastTransactionErrorsChecked(res.data.lastTransactionErrors.checked));
      dispatch(setGatewayLastTransactionErrorsList(res.data.lastTransactionErrors.list));
    })
    .catch((err) => dispatch(setError(err.response?.data?.error?.message ?? 'Ошибка при загрузке шлюзы')))
    .finally(() => dispatch(setIsLoading(false)));
};

const getPaymentOptions = () => async (dispatch) => {
  dispatch(setIsLoading(true));
  return request
    .get('/admin/gateways/options')
    .then((res) => dispatch(setPaymentOptions(res.data)))
    .catch((err) => dispatch(setError(err.response?.data?.error?.message ?? 'Ошибка при загрузке платёжную систему')))
    .finally(() => dispatch(setIsLoading(false)));
};

const postCreateGateway = (payload) => async (dispatch) => {
  return request
    .post('/admin/gateways', payload)
    .then(() => {
      dispatch(getAllGateways());
      dispatch(setRedirect('/gateway'));
    })
    .catch((err) => dispatch(setError(err.response?.data?.error?.message ?? 'Ошибка при создании шлюза')));
};

const deleteGateway = (id) => async (dispatch) => {
  return request.delete(`/admin/gateways/${id}`).then(() => {
    dispatch(getAllGateways());
  });
};

const getAdminLogs = () => async (dispatch) => {
  return request
    .get('/admin/admin-log?filter[type]=1')
    .then((res) => dispatch(setAdminLogs(res.data)))
    .catch((err) => dispatch(setError(err.response?.data?.error?.message ?? 'Ошибка при загрузке логов')))
    .finally(() => dispatch(setIsLoading(false)));
};

const updateGateway = (id, payload) => async (dispatch) => {
  return request
    .put(`/admin/gateways/${id}`, payload)
    .then(() => {
      // dispatch(getAllGateways());
      dispatch(setRedirect('/gateway'));
    })
    .catch((err) => dispatch(setError(err.response?.data?.error?.message ?? 'Ошибка при редактировании шлюза')));
};

const getGatewayById = (id) => async (dispatch) => {
  dispatch(setIsLoading(true));
  return axios
    .all([request.get(`/admin/gateways/${id}`), request.get('/admin/gateways/options')])
    .then(
      axios.spread((res1, res2) => {
        dispatch(setPaymentOptions(res2.data));
        dispatch(setGatewayDataId(res1.data));
      }),
    )
    .catch((err) => dispatch(setError(err.response?.data?.error?.message ?? 'Ошибка при загрузке шлюзы')))
    .finally(() => dispatch(setIsLoading(false)));
};

const getBanks = () => async (dispatch) => {
  dispatch(setIsLoading(true));
  return request
    .get('admin/banks')
    .then((res) => dispatch(setBanks(res.data)))
    .catch((err) => dispatch(setError(err.response?.data?.error?.message ?? 'Ошибка при загрузке банков')))
    .finally(() => dispatch(setIsLoading(false)));
};

const getGatewayStatuses = (payload) => async (dispatch) => {
  return request.get('/admin/domains/statuses').then((res) => dispatch(setGatewayStatuses(res.data)));
};

const cancelGatewayLogs = (payload) => async (dispatch) => {
  return request
    .post('/admin/admin-log/cancel', payload)
    .then(() => {
      dispatch(getAdminLogs());
      dispatch(getGatewaysMoveInfo());
    })
    .catch((err) => {
      dispatch(setError(err.response?.data?.error?.message ?? 'Ошибка при переносе шлюза'));
    });
};

const moveGateways = (payload) => async (dispatch) => {
  return request
    .post('/admin/gateways/move', payload)
    .then((resp) => {
      dispatch(getAdminLogs());
      dispatch(getGatewaysMoveInfo());
      dispatch(setMoveResult('success'));
      dispatch(setMoveResultSuccessMessage(resp?.data?.movedOrdersCount));
    })
    .catch((err) => {
      dispatch(setError(err.response?.data?.error ?? 'Ошибка при переносе шлюза'));
      dispatch(setMoveResult('error'));
    });
};

const getStatisticsGateways = (params) => async (dispatch) => {
  dispatch(setIsLoading(true));
  return request
    .get('/admin/statistic/gateways', { params })
    .then((res) => dispatch(setGatewaysStatistics(res.data)))
    .catch((err) => dispatch(setError(err.response?.data?.error ?? 'Ошибка при получении статистики')))
    .finally(() => dispatch(setIsLoading(false)));
};

const gatewaysMassUpdate = (payload) => async (dispatch) => {
  return request
    .post(`/admin/gateways/mass-update`, payload)
    .then((resp) => {
      resp.data[0] && dispatch(setGatewaysMassUpdateMessageSuccess(resp.data[0]))
      dispatch(setGatewaysMassUpdateStatus('success'));
    })
    .catch((err) => {
      dispatch(setGatewaysMassUpdateFailure(err.response?.data?.error?.message));
      dispatch(setGatewaysMassUpdateStatus('error'));
    });
};

const getRatesList = () => async (dispatch) => {
  return request
    .get(`/admin/gateways/tariffs`)
    .then((resp) => {
      dispatch(setRates(resp.data));
    })
};

export {
  gateway,
  getAllGateways,
  getPaymentOptions,
  postCreateGateway,
  deleteGateway,
  updateGateway,
  getGatewayById,
  getGatewayStatuses,
  setGatewayData,
  getBanks,
  moveGateways,
  getGatewaysMoveInfo,
  setGatewayPage,
  setGatewayFilter,
  getAdminLogs,
  setMoveResult,
  setMoveResultSuccessMessage,
  cancelGatewayLogs,
  getAllGatewaysPage,
  getStatisticsGateways,
  gatewaysMassUpdate,
  setGatewaysMassUpdateFailure,
  setGatewaysMassUpdateStatus,
  setGatewaysMassUpdateMessageSuccess,
  getRatesList
};

import { createSlice } from '@reduxjs/toolkit';
import { request } from 'api';

const initialState = {
  isLoad: false,
  list: {},
  filters: {
    paymentSystem: '',
    currency: '',
    dateFrom: null,
    dateTo: null,
    companyId: '',
  },
};

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    setIsLoad(state, action) {
      state.isLoad = action.payload;
    },
    setAnalyticsData(state, action) {
      state.list = action.payload;
      state.isLoad = false;
    },
    setAnalyticsFilter(state, action) {
      state.filters.paymentSystem = action.payload.paymentSystem;
      state.filters.currency = action.payload.currency;
      state.filters.companyId = action.payload.companyId;
      state.filters.dateFrom = action.payload.dateFrom;
      state.filters.dateTo = action.payload.dateTo;
    },
  },
});

const {
  reducer: analytics,
  actions: { setIsLoad, setAnalyticsData, setAnalyticsFilter },
} = analyticsSlice;

export const analyticsGetAll = (params) => async (dispatch) => {
  dispatch(setIsLoad(true));
  return request
    .get('/admin/analytics/summary', { params })
    .then((res) => dispatch(setAnalyticsData(res.data)))
    .catch(() => dispatch(setIsLoad(false)));
};

export { analytics, setAnalyticsFilter };

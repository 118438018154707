import {
	setOrderClearSelected,
	orderGetOne,
	orderInfoUpdateStatus,
	orderInfoChargebackStatus,
	orderRedirectGetOne,
	orderRedirectGetReport,
	orderUnsubscribeOne,
	setOrderUnsubscribeOneIsSuccess,
	orderGetReportPDF,
	setPaymentStatus,
	setPaymentError,
	getStatusLabels
} from 'store/slices/order'
import { toast } from 'react-toastify'
import Loader from 'assets/components/Loader'
import Table from 'assets/components/Table/Table'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import PopupRefund from 'assets/components/PopupRefund'
import OrderPaymentModal from './OrderPaymentModal'
import { getDataFromArrayIds } from '../../utils/getDataFormArray'

function checkDatePassed(date) {
	if (date) {
		return format(new Date(date), 't') <= format(Date.now(), 't')
	}
}

function getStatusName(
	lites,
	deactivationDate,
	isDeactivationDateLite,
	expiresDate,
	expiresDateLite,
	deactivated,
	liteCreatedAt
) {
	const isLite = !!lites?.length

	if (isLite && !isDeactivationDateLite && !checkDatePassed(expiresDateLite))
		return 'Лайт'
	if (!isLite && expiresDate && !checkDatePassed(expiresDate) && liteCreatedAt)
		return 'Лайт'
	if (isLite && !isDeactivationDateLite && checkDatePassed(expiresDateLite))
		return 'Лайт (просрочен)'
	if (!isLite && expiresDate && checkDatePassed(expiresDate) && liteCreatedAt)
		return 'Лайт (просрочен)'

	if (
		(deactivationDate && !isLite) ||
		(isLite && isDeactivationDateLite) ||
		(deactivationDate && isLite && isDeactivationDateLite)
	)
		return 'Неактивный'

	if (!isLite && expiresDate && checkDatePassed(expiresDate))
		return 'Премиум (просрочен)'
	if (!isLite && expiresDate && !checkDatePassed(expiresDate)) return 'Премиум'
	if (!isLite && deactivated) return '-'

	return '-'
}

const OrderInfoPage = () => {
	const { id } = useParams()
	const dispatch = useDispatch()
	const {
		selectedOrder,
		isLoad,
		flag,
		paymentStatus,
		flagChargeback,
		errorMessage,
		isSuccessUnsubscribe
	} = useSelector(state => state.order)
	const paymentError = useSelector(state => state.order.paymentError)
	const availableEdit = useSelector(
		state => state.auth.role.permissions.payment
	)?.includes('create_payments')
	const [showPopupOrder, setShowPopupOrder] = useState(false)
	const [showPopupOrderError, setShowPopupOrderError] = useState(false)
	const [showPopupChargeback, setShowPopupChargeback] = useState(false)
	const [showPopupChargebackError, setShowPopupChargebackError] =
		useState(false)
	const [editItemOrder, setEditItemOrder] = useState(null)
	const [showUnsubscribePopup, setShowUnsubscribePopup] = useState(false)
	const [type, setType] = useState('')
	const [isDeactivationDateLite, setIsDeactivationDateLite] = useState(null)
	const [expiresAt, setExpiresAt] = useState(null)
	const statusLabels = useSelector(state => state.order.statusLabels)

	useEffect(() => {
		if (selectedOrder.childrenOrdersIds?.length) {
			getDataFromArrayIds(selectedOrder.childrenOrdersIds, 'admin/orders')
				.then(data => data?.find(order => order))
				.then(function (res) {
					if (res) {
						setIsDeactivationDateLite(!!res.deactivatedAt)
						setExpiresAt(res.expiresAt)
					}
				})
		}
	}, [selectedOrder])

	useEffect(() => dispatch(getStatusLabels()), [])

	const handleEditRefundOrder = item => {
		setEditItemOrder(item)
		setShowPopupOrder(true)
	}

	const handleEditChargebackOrder = (item, type) => {
		setEditItemOrder(item)
		setShowPopupChargeback(true)
		setType(type)
	}

	const handleSaveChangeChargeBackOrder = () => {
		dispatch(orderInfoChargebackStatus(Number(editItemOrder.id), type, id))
		setShowPopupChargeback(false)
	}

	const handleSaveChangeRefundOrder = () => {
		dispatch(orderInfoUpdateStatus(Number(editItemOrder.id), id))
		setShowPopupOrder(false)
	}

	useEffect(() => {
		dispatch(orderGetOne(id))
		return () => dispatch(setOrderClearSelected())
	}, [dispatch, id])

	useEffect(() => {
		setShowPopupOrderError(flag)
	}, [flag])

	useEffect(() => {
		setShowPopupChargebackError(flagChargeback)
	}, [flagChargeback])

	const handleUnsubscribe = () => {
		dispatch(orderUnsubscribeOne(Number(id)))
		setShowUnsubscribePopup(false)
	}

	useEffect(() => {
		if (paymentError) {
			toast.error(paymentError || 'Произошла ошибка ;(')
			dispatch(setPaymentError(''))
		}
	}, [paymentError])

	return (
		<div className='create'>
			{isLoad ? (
				<Loader />
			) : (
				<>
					<h2 className='create__title'>Детали заказа {id}</h2>

					<div className='order-info-entry'>
						<div className='order-info-entry__title'>ID</div>
						<div>{selectedOrder.id}</div>
						<button
							className='button-small'
							onClick={() => dispatch(orderRedirectGetOne(id))}
						>
							Войти
						</button>
						<button
							className='button-small'
							onClick={() => dispatch(orderRedirectGetReport(id))}
						>
							Отчёт
						</button>
						<button
							className='button-small'
							onClick={() => dispatch(orderGetReportPDF(id))}
						>
							Отчёт (PDF)
						</button>
						{selectedOrder.status !== 2 && (
							<button
								className='button-medium'
								onClick={() => setShowUnsubscribePopup(true)}
							>
								Отмена подписки
							</button>
						)}
					</div>
					{showUnsubscribePopup && (
						<PopupRefund
							message={`Вы уверены, что хотите отменить подписку ?`}
							onConfirm={handleUnsubscribe}
							onCancel={() => setShowUnsubscribePopup(false)}
						/>
					)}
					{isSuccessUnsubscribe && (
						<PopupRefund
							message={`Подписка успешно отменена`}
							onConfirm={() => dispatch(setOrderUnsubscribeOneIsSuccess())}
							oneButton
						/>
					)}

					{selectedOrder.parentOrder && (
						<div className='order-info-entry'>
							<div className='order-info-entry__title'>Родитель</div>
							<div>
								<Link
									to={`/order/${selectedOrder.parentOrder.id}`}
									target='_blank'
								>
									{selectedOrder.parentOrder.id}
								</Link>
							</div>
						</div>
					)}

					{!!selectedOrder.childrenOrdersIds?.length && (
						<div className='order-info-entry'>
							<div className='order-info-entry__title'>Лайты</div>
							<div className='order-info-entry__children'>
								{selectedOrder.childrenOrdersIds.map(order => (
									<Link key={order} to={`/order/${order}`} target='_blank'>
										{order}
									</Link>
								))}
							</div>
						</div>
					)}

					{!!selectedOrder.renewalOrdersIds?.length && (
						<div className='order-info-entry'>
							<div className='order-info-entry__title'>RENEWAL</div>
							<div className='order-info-entry__children'>
								{selectedOrder.renewalOrdersIds.map(order => (
									<Link key={order} to={`/order/${order}`} target='_blank'>
										{order}
									</Link>
								))}
							</div>
						</div>
					)}

					<div className='order-info-entry'>
						<div className='order-info-entry__title'>Номер</div>
						<div>{selectedOrder.number}</div>
					</div>

					<div className='order-info-entry'>
						<div className='order-info-entry__title'>ID клиента</div>
						<Link to={`/user/${selectedOrder.user?.id}`} target='_blank'>
							{selectedOrder.user?.id}
						</Link>
					</div>

					<div className='order-info-entry'>
						<div className='order-info-entry__title'>Email клиента</div>
						<div>{selectedOrder.user?.email}</div>
					</div>

					<div className='order-info-entry'>
						<div className='order-info-entry__title'>Таймзона</div>
						<div>{selectedOrder.timezone}</div>
					</div>

					<div className='order-info-entry'>
						<div className='order-info-entry__title'>UTM</div>
						<div>{`utm_campaign: ${selectedOrder.utm?.utm_campaign}, utm_content: ${selectedOrder.utm?.utm_content}, utm_medium: ${selectedOrder.utm?.utm_medium}, utm_source: ${selectedOrder.utm?.utm_source}, utm_term: ${selectedOrder.utm?.utm_term}`}</div>
					</div>

					<div className='order-info-entry'>
						<div className='order-info-entry__title'>Лэндинг</div>
						<div>{selectedOrder.landing}</div>
					</div>

					<div className='order-info-entry'>
						<div className='order-info-entry__title'>IP</div>
						<div>
							{selectedOrder.ip}{' '}
							{selectedOrder.country && '(' + selectedOrder.country + ')'}
						</div>
					</div>

					<div className='order-info-entry'>
						<div className='order-info-entry__title'>UserAgent</div>
						<div>{selectedOrder.ua}</div>
					</div>

					<div className='order-info-entry'>
						<div className='order-info-entry__title'>CPA</div>
						<div>{selectedOrder.cpa}</div>
					</div>

					<div className='order-info-entry'>
						<div className='order-info-entry__title'>Создан</div>
						<div>{selectedOrder.createdAt}</div>
					</div>

					<div className='order-info-entry'>
						<div
							className='order-info-entry__title'
							data-status={selectedOrder.status}
						>
							Статус заказа
						</div>
						<div>{statusLabels?.[selectedOrder.status]}</div>
					</div>

					{!!selectedOrder?.liteCreatedAt && (
						<div className='order-info-entry'>
							<div className='order-info-entry__title'>Переход на лайт</div>
							<div>{selectedOrder.liteCreatedAt}</div>
						</div>
					)}

					<div className='order-info-entry'>
						<div className='order-info-entry__title'>Продукт</div>
						<div className='order-info-entry__children'>
							<Link
								to={`/product/edit/${selectedOrder.product?.id}`}
								target='_blank'
							>
								{selectedOrder.product?.id} - {selectedOrder.product?.name}
							</Link>
							{selectedOrder.subscriptions?.map(subscription => (
								<Link
									to={`/product/edit/${subscription?.productId}`}
									target='_blank'
								>
									{subscription.productId} - {subscription.innerName} -{' '}
									{subscription.name} - {subscription.createdAt}
								</Link>
							))}
						</div>
					</div>

					<div className='order-info-entry'>
						<div className='order-info-entry__title'>Истекает</div>
						<div>{selectedOrder.expiresAt}</div>
					</div>

					<div className='order-info-entry'>
						<div className='order-info-entry__title'>След. платеж </div>
						<div>{selectedOrder.nextPaymentAt}</div>
					</div>

					<div className='order-info-entry'>
						<div className='order-info-entry__title'>Деактивирована</div>
						<div>
							{selectedOrder.liteDeactivatedAt &&
								!selectedOrder.statusSubscription &&
								`${selectedOrder.liteDeactivatedAt} ${selectedOrder.deactivatedType} ${selectedOrder.deactivatedBy}`}
						</div>
					</div>

					<div className='order-info-entry'>
						<div className='order-info-entry__title'>
							Дата деактивации текущего заказа
						</div>
						<div>
							{selectedOrder.deactivatedAt &&
								`${selectedOrder.deactivatedAt} ${selectedOrder.deactivatedType} ${selectedOrder.deactivatedBy}`}
						</div>
					</div>

					<div className='order-info-entry'>
						<div className='order-info-entry__title'>Платежная система</div>
						<div>{selectedOrder.paymentSystem}</div>
					</div>

					<div className='order-info-entry'>
						<div className='order-info-entry__title'>ID Платежный шлюз</div>
						<div>
							<Link
								to={`/gateway/edit/${selectedOrder.paymentGatewayId}`}
								target='_blank'
							>
								{selectedOrder.paymentGatewayId}
							</Link>
						</div>
					</div>

					<div className='order-info-entry'>
						<div className='order-info-entry__title'>Платежный шлюз</div>
						<div>{selectedOrder.paymentGateway}</div>
					</div>

					<div className='order-info-entry'>
						<div className='order-info-entry__title'>Тип карты</div>
						<div>{selectedOrder.cardType}</div>
					</div>

					<div className='order-info-entry'>
						<div className='order-info-entry__title'>Категория карты</div>
						<div>{selectedOrder.cardCategory}</div>
					</div>

					<div className='order-info-entry'>
						<div className='order-info-entry__title'>Номер карты</div>
						<div>
							{selectedOrder.cardFirstSix}** **** {selectedOrder.cardLastFour}
						</div>
					</div>

					<div className='order-info-entry'>
						<div className='order-info-entry__title'>Срок действия карты</div>
						<div>
							{selectedOrder?.cardExpDate &&
								format(new Date(selectedOrder.cardExpDate), 'MM/yy')}
						</div>
					</div>

					<div className='order-info-entry'>
						<div className='order-info-entry__title'>Банк карты</div>
						<div>
							[{selectedOrder.issuerBankCountry}] {selectedOrder.issuer}
						</div>
					</div>

					<div className='order-info-table'>
						<label className='order-info-label'>История платежей</label>
						<Table
							titles={[
								{ name: 'ID' },
								{ name: 'Внешний ID', mod: 'big' },
								{ name: 'Статус', mod: 'm' },
								{ name: 'Создан' },
								{ name: 'Тип' },
								{ name: 'Сумма' },
								{ name: 'Refund' },
								{ name: 'Chargeback' },
								{ name: 'Arbitration' },
								{ name: 'Текст ошибки' },
								{ name: 'Код ошибки' },
								{ name: 'Шлюз' }
							]}
							content={selectedOrder.payments}
							keyProp='orderPayments'
							handleEditRefundOrder={handleEditRefundOrder}
							handleEditChargebackOrder={handleEditChargebackOrder}
						/>

						{selectedOrder.state === 2 ? (
							<div className='order-info__paymnetText'>
								В данный момент по заказу идут платежи
							</div>
						) : null}

						{availableEdit ? (
							<button
								className='button-modal'
								onClick={() => dispatch(setPaymentStatus(true))}
							>
								Провести платеж
							</button>
						) : null}

						{showPopupOrder && (
							<PopupRefund
								message={`Вы уверены, что хотите провести возврат платежа id:${editItemOrder.id}`}
								onConfirm={handleSaveChangeRefundOrder}
								onCancel={() => setShowPopupOrder(false)}
							/>
						)}
						{showPopupOrderError && (
							<PopupRefund
								message={`Ошибка при отправке запроса: ${errorMessage}`}
								onConfirm={() => setShowPopupOrderError(false)}
								oneButton
							/>
						)}
						{showPopupChargeback && (
							<PopupRefund
								message={`Вы уверены, что хотите провести ${type} платежа id:${editItemOrder.id}`}
								onConfirm={handleSaveChangeChargeBackOrder}
								onCancel={() => {
									setShowPopupChargeback(false)
									setType('')
								}}
							/>
						)}
						{showPopupChargebackError && (
							<PopupRefund
								message={`Ошибка при отправке запроса: ${errorMessage}`}
								onConfirm={() => setShowPopupChargebackError(false)}
								oneButton
							/>
						)}

						{selectedOrder?.logs?.order?.length ? (
							<div className='order-info-table'>
								<label className='order-info-label'>
									Логи доступа к заказу
								</label>
								<Table
									titles={[
										{ name: 'Время' },
										{ name: 'IP' },
										{ name: 'UserAgent' }
									]}
									content={selectedOrder?.logs?.order}
									keyProp='orderLogs'
								/>
							</div>
						) : null}

						{selectedOrder?.logs?.auth?.length ? (
							<div className='order-info-table'>
								<label className='order-info-label'>Логи авторизаций</label>
								<Table
									titles={[
										{ name: 'ID' },
										{ name: 'Время' },
										{ name: 'IP' },
										{ name: 'UserAgent' }
									]}
									content={selectedOrder?.logs?.auth}
									keyProp='authLogs'
								/>
							</div>
						) : null}

						{selectedOrder?.logs?.files &&
						Object.values(selectedOrder.logs.files).length ? (
							<div className='order-info-table'>
								<label className='order-info-label'>
									Логи доступа к файлам
								</label>
								{Object.values(selectedOrder.logs.files).map(f => (
									<div key={f.fileId}>
										<div className='order-info-table__title'>
											ID - {f.fileId} | Название - {f.name}
										</div>
										<Table
											titles={[
												{ name: 'Время' },
												{ name: 'IP' },
												{ name: 'UserAgent' }
											]}
											content={f.logs}
											keyProp='fileLogs'
										/>
									</div>
								))}
							</div>
						) : null}
						<div className='buttonGroup'>
							<Link to='/orders/all' className='button order-info-button'>
								Назад
							</Link>
						</div>
					</div>
					{paymentStatus ? <OrderPaymentModal /> : null}
				</>
			)}
		</div>
	)
}

export { OrderInfoPage }

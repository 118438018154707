import React, { useEffect } from 'react'
import Header from 'assets/components/Header'
import Table from 'assets/components/Table'
import { modalCleanData } from 'store/slices/modal'
import { fileGetAll, fileDelete } from 'actions/file'
import { setProductCleanErrors, productGetOne } from 'store/slices/product'
import { useAppDispatch, useAppSelector } from 'store'
import { useHistory } from 'react-router-dom'
import Loader from 'assets/components/Loader'
import { useParams } from 'react-router-dom'

const titles = [
	{
		name: 'ID',
		mod: 'sm'
	},
	{
		name: 'Название папки'
	},
	{
		name: 'Номер'
	},
	{
		name: 'Путь к папке',
		mod: 'big'
	},
	{
		name: '...',
		mod: 'm'
	}
]

const FileExactProductPage = () => {
	const dispatch = useAppDispatch()
	const history = useHistory()
	const { productId } = useParams()

	const filesAll = useAppSelector(state => state.file.list)
	const filesOne = useAppSelector(state => state.product.curProduct.files)
	const productError = useAppSelector(state => state.product.errors?.product)
	const productName =
		useAppSelector(state => state.product.curProduct.name) || ''
	const modalStatus = useAppSelector(state => state.modal.modalStatus)
	const infoPayload = useAppSelector(state => state.modal.infoPayload)
	const isLoad = useAppSelector(state => state.file.isLoad)
	const availableEdit = useAppSelector(
		state => state.auth.role
	)?.permissions.file.includes('edit_files')

	useEffect(() => {
		if (productError) {
			dispatch(setProductCleanErrors('product'))
			history.push('/file')
		}
	}, [productError])

	useEffect(() => {
		if (productId === 'all') dispatch(fileGetAll())
		else dispatch(productGetOne(productId))
	}, [productId])

	useEffect(() => {
		if (modalStatus === 'yes') {
			dispatch(fileDelete(infoPayload.id, productId))
			dispatch(modalCleanData())
		}
	}, [modalStatus])

	if (productId === 'all')
		return (
			<>
				{isLoad ? <Loader /> : ''}
				<Header
					title='Все файлы'
					text={availableEdit ? 'Добавить файлы' : null}
					href={availableEdit ? '/file/all/add' : null}
				/>
				<Table
					titles={titles}
					content={productId !== 'all' ? filesOne : filesAll}
					keyProp={'fileExactProduct'}
				/>
			</>
		)
	else {
		return (
			<>
				{isLoad ? <Loader /> : ''}
				<Header
					title={`файлы продукта ${productName}`}
					text='Добавить файлы'
					href={`/file/${productId}/add`}
				/>
				<Table
					titles={titles}
					content={productId !== 'all' ? filesOne : filesAll}
					keyProp={'fileExactProduct'}
				/>
			</>
		)
	}
}

export default FileExactProductPage

import React, { useEffect } from 'react';
import Header from 'assets/components/Header';
import Table from 'assets/components/Table';
import Loader from 'assets/components/Loader';
import Filters from 'assets/components/Filters';
import Pagination from 'rc-pagination';
import {
  getBanksList,
  setBanksFilter,
  setBanksPage,
  updateBankIssuer,
  setUpdateIssuerStatus,
} from 'store/slices/banks';
import { useAppSelector, useAppDispatch } from 'store';
import EditBanksIssuerModal from './EditBanksIssuerModal';
import { toast } from 'react-toastify';

const titlesBanks = [
  {
    name: 'Первые 6 цифр',
    mod: 'big',
  },
  {
    name: 'Количество',
    mod: 'big',
  },
  {
    name: 'Банк',
  },
  {
    name: '...',
  },
];

const BanksPage = () => {
  const dispatch = useAppDispatch();
  const banksList = useAppSelector((state) => state.banks.banksList);
  const isLoading = useAppSelector((state) => state.banks.isLoading);
  const limit = useAppSelector((state) => state.banks.pagination.limit);
  const page = useAppSelector((state) => state.banks.pagination.page);
  const total = useAppSelector((state) => state.banks.pagination.total);
  const updateIssuerStatus = useAppSelector((state) => state.banks.updateIssuerStatus);
  const error = useAppSelector((state) => state.banks.error);
  const [selectCardfirstsix, setSelectCardfirstsix] = React.useState({
    cardfirstsix: '',
    fillEmptyValues: false,
    issuer: '',
  });
  const [activeEdit, setActiveEdit] = React.useState(false);
  const [reloadPage, setReloadPage] = React.useState(true);

  const handleClickCheck = (num) => {
    setSelectCardfirstsix({
      ...selectCardfirstsix,
      cardfirstsix: num,
    });
    setActiveEdit(true);
  };

  const handleClickCloseModal = () => {
    setActiveEdit(false);
  };

  const handleClickUpdate = () => {
    dispatch(updateBankIssuer(selectCardfirstsix));
  };

  useEffect(() => {
    const handleGetBanks = async () => {
      let params = {};

      params.limit = limit;
      params.page = page;

      dispatch(getBanksList(params));
    };
    if (reloadPage) {
      handleGetBanks();
      setReloadPage(false);
    }
  }, [reloadPage]);

  useEffect(() => {
    if (updateIssuerStatus === 'success') {
      handleClickCloseModal();
      toast.success('Редактирование прошло успешно');
      dispatch(setUpdateIssuerStatus(''));
    }
    if (updateIssuerStatus === 'error') {
      toast.error(error);
      dispatch(setUpdateIssuerStatus(''));
    }
  }, [updateIssuerStatus]);

  const handlePageChange = (current) => {
    dispatch(setBanksPage(current));
    setReloadPage(true);
  };

  return (
    <>
      {isLoading && <Loader />}
      <Header title='Банки' />
      <Filters
        banks={true}
        onFilterChange={(obj) => {
          dispatch(setBanksFilter(obj));
          setReloadPage(true);
        }}
      />
      <Table handleClickCheck={handleClickCheck} titles={titlesBanks} content={banksList} keyProp='banks' />
      <Pagination
        className='order__pagination'
        current={page}
        total={total}
        pageSize={limit}
        onChange={handlePageChange}
        showTitle={false}
      />
      {activeEdit && (
        <EditBanksIssuerModal
          handleClickUpdate={handleClickUpdate}
          setSelectCardfirstsix={setSelectCardfirstsix}
          selectCardfirstsix={selectCardfirstsix}
          handleClickCloseModal={handleClickCloseModal}
        />
      )}
    </>
  );
};

export default BanksPage;

import React, { useState, useCallback } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import cn from 'classnames';
import InputSelect from './components/InputSelect';

const FiltersProduct = ({ appliedFilters, onFilterChange }) => {
  const [statusOptions, setStatusOptions] = useState([{value: 0 , label: 'Показать все'},{value: 1, label: 'Активные'}, {value: 2, label: 'Неактивные'}])
  const [status, setStatus] = useState(1);

  const handleApplyFilters = () => {
    onFilterChange({
      status,
    });
  };

  // status selector optimization rendering
  const onChangeState = useCallback((e) => {
    setStatus(e)
  },[status]);

  return (
    <div className='filters'>
      <h2 className='filters__title'>Фильтры</h2>
      <div className='filters__inner'>

        {/* Row 1 */}
        <div className='row'>
          <div className='filters__select'>
            <label className='input__label'>Статус</label>
            <InputSelect
              options={statusOptions}
              value={status}
              onChange={onChangeState}
            />
          </div>
        </div>
        {/* Button */}
        <button
          className={cn('button', 'filters__btn')}
          onClick={handleApplyFilters}
        >
          Применить
        </button>
      </div>
    </div>
  );
};

export default FiltersProduct;


import React, { useEffect, useState } from 'react'
import Input from 'assets/components/Input'
import { FileInput } from 'assets/components/FileInput'
import ButtonLink from 'assets/components/ButtonLink'
import { Link, useHistory } from 'react-router-dom'
import DropDown from 'assets/components/DropDown'
import Table from 'assets/components/Table'
import Loader from '../../assets/components/Loader'
import ProductDescr from 'assets/components/ProductDescr'
import Select from 'react-select'
import { dashboardOptions } from '../../constants/dashboardOptions/dashboardOptions'
import { toast } from 'react-toastify'
import { useAppDispatch, useAppSelector } from 'store'
import { getAllCurrencies } from 'store/slices/currency'
import { getAllLanguages } from 'store/slices/language'
import {
	productGetOne,
	setProductCleanErrors,
	setProductCleanRedirect,
	productUpdate,
	productCategoriesGetAll,
	setProductUpdateFailure
} from 'store/slices/product'
import { fileGetAll } from 'actions/file'
import { pixelDelete } from 'store/slices/pixel'
import { modalCleanData } from 'store/slices/modal'
import { useParams } from 'react-router-dom'

const pixelTableTitles = [
	{
		name: 'ID'
	},
	{
		name: 'Название пикселя',
		mod: 'big'
	},
	{
		name: 'Значение',
		mod: 'big'
	},
	{
		name: '...',
		mod: 'm'
	}
]

const productFilesTitles = [
	{
		name: 'ID'
	},
	{
		name: 'Имя файла'
	},
	{
		name: 'Edited Имя файла'
	},
	{
		name: 'номер'
	},
	{
		name: 'Edited номер'
	},
	{
		name: 'путь',
		mod: 'big'
	},
	{
		name: '...'
	}
]

const productDomainsTitles = [
	{
		name: 'ID'
	},
	{
		name: 'домен'
	},
	{
		name: 'имя'
	}
]

const portionSizeOptions = [
	{ value: '24', label: 'День' },
	{ value: '168', label: 'Неделя' }
]
const workSchemeOptions = [
	{ value: 0, label: 'Обычная' },
	{ value: 1, label: 'Выкуп' }
]

const designPersonalAreaOption = [
	{ value: 0, label: 'Старый' },
	{ value: 1, label: 'Новый' }
]

const languageOption = [
	{ value: 'en', label: 'EN' },
	{ value: 'ru', label: 'RU' },
	{ value: 'ua', label: 'UA' },
	{ value: 'am', label: 'AM' },
	{ value: 'es', label: 'ES' },
	{ value: 'fr', label: 'FR' },
	{ value: 'pl', label: 'PL' },
	{ value: 'kz', label: 'KZ' }
]

const ProductEditPage = () => {
	const { id } = useParams()
	const dispatch = useAppDispatch()
	const categoriesOption = useAppSelector(state => state.product.categories)
	const errors = useAppSelector(state => state.product.errors)
	const curProduct = useAppSelector(state => state.product.curProduct)
	const file = useAppSelector(state => state.file.list)
	const redirect = useAppSelector(state => state.product.redirect)
	const modalStatus = useAppSelector(state => state.modal.modalStatus)
	const infoPayload = useAppSelector(state => state.modal.infoPayload)
	const isLoad = useAppSelector(state => state.product.isLoad)
	const currencies = useAppSelector(state => state.currency.data)
	const languages = useAppSelector(state => state.language.data)
	const productStatusList = useAppSelector(
		state => state.product.productStatusList
	)
	// debugger;
	const [availableLanguages, setAvailableLanguages] = useState('')
	const [name, setName] = useState('')
	const [innerName, setInnerName] = useState('')
	const [price, setPrice] = useState('')
	const [initialPrice, setInitialPrice] = useState('')
	const [initialPriceAMD, setInitialPriceAMD] = useState('')
	const [oldPrice, setOldPrice] = useState('')
	const [prepaidPrice, setPrepaidPrice] = useState(null)
	const [tariff, setTariff] = useState('')
	const [tariffAMD, setTariffAMD] = useState('')
	const [initialInterval, setInitialInterval] = useState('')
	const [followingInterval, setFollowingInterval] = useState('')
	const [allFiles, setAllFiles] = useState([])
	const [productFiles, setProductFiles] = useState([])
	const [firstRun, setFirstRun] = useState(true)
	const [refundInitial, setRefundInitial] = useState(true)
	const [description, setDescription] = useState('')
	const [tariffDescription, setTariffDescription] = useState('')
	const [shortDescription, setShortDescription] = useState('')
	const [addExistingFilesSelectValue, setAddExistingFilesSelectValue] =
		useState([])
	const [addExistingFilesSelectOptions, setAddExistingFilesSelectOptions] =
		useState([])
	const [dashboardSelectedOption, setDashboardSelectedOption] = useState(null)
	const [portionSizeSelectedOption, setPortionSizeSelectedOption] =
		useState(null)
	const [workSchemeSelectedOption, setWorkSchemeSelectedOption] = useState(null)
	const [productBackgroundFile, setProductBackgroundFile] = useState('')
	const [previewBackgroundURL, setPreviewBackgroundURL] = useState('')
	const [productCoverFile, setProductCoverFile] = useState('')
	const [previewCoverURL, setPreviewCoverURL] = useState('')
	const [currencySelectedOption, setCurrencySelectedOption] = useState('')
	const [recalculateDisplayPrice, setRecalculateDisplayPrice] = useState(true)
	const [displayCurrency, setDisplayCurrency] = useState('')
	const [displayPrice, setDisplayPrice] = useState('')
	const [displayOldPrice, setDisplayOldPrice] = useState('')
	const [languageSelectedOption, setLanguageSelectedOption] = useState('')
	const [status, setStatus] = useState(0)
	const [firstCheckbox, setFirstCheckbox] = useState('')
	const [secondCheckbox, setSecondCheckbox] = useState('')
	const [catalog, setCatalog] = useState(false)
	const [promo, setPromo] = useState(false)
	const [categories, setCategories] = useState([])
	const [designPersonalArea, setDesignPersonalArea] = useState(0)
	const [contentAccessDays, setContentAccessDays] = useState(7)
	const [slug, setSlug] = useState('')
	const [contentAvailable, setContentAvailable] = useState(true)
	const [certIssueAvailable, setCertIssueAvailable] = useState(true)
	const [rating, setRating] = useState(5)
	const [modulesCount, setModulesCount] = useState('')

	const [isPremium, setIsPremium] = useState(false)
	const [isLite, setIsLite] = useState(false)
	const [isRenewal, setIsRenewal] = useState(false)

	const history = useHistory()

	useEffect(() => {
		dispatch(getAllCurrencies())
		dispatch(getAllLanguages())
		dispatch(productCategoriesGetAll())
	}, [])

	useEffect(() => {
		if (errors) {
			Object.entries(errors).map(err => {
				toast.error(`${err[1]} - ${err[0]}`)
			})
		}
		return () => {
			dispatch(setProductUpdateFailure(null))
		}
	}, [errors])

	useEffect(() => {
		if (redirect) {
			dispatch(setProductCleanRedirect())
			history.push('/product')
		}
	}, [redirect])

	useEffect(() => {
		dispatch(productGetOne(id))
	}, [])

	useEffect(() => {
		setInnerName(curProduct.innerName)
		setName(curProduct.name)
		setRefundInitial(curProduct.refundInitial)
		setPrepaidPrice(curProduct?.prepaidPrice)
		setPrice(curProduct.price)
		setInitialPrice(curProduct.initialPrice)
		setInitialPriceAMD(curProduct.initialPriceAMD)
		setOldPrice(curProduct.oldPrice)
		setTariff(curProduct.tariff)
		setTariffAMD(curProduct.tariffAMD)
		setInitialInterval(curProduct.initialInterval)
		setFollowingInterval(curProduct.followingInterval)
		setDescription(curProduct.description)
		setTariffDescription(curProduct.tariffDescription)
		setShortDescription(curProduct.shortDescription)
		setDashboardSelectedOption(
			dashboardOptions.find(o => o.value === curProduct.dashboard)
		)
		setPortionSizeSelectedOption(
			portionSizeOptions.find(
				o => o.value === curProduct.portionSize?.toString()
			)
		)
		setWorkSchemeSelectedOption(
			workSchemeOptions.find(o => o.value === curProduct.workScheme)
		)
		setPreviewBackgroundURL(curProduct.background)
		setPreviewCoverURL(curProduct.cover)
		currencies &&
			curProduct &&
			setCurrencySelectedOption(
				currencies.find(item => item.label === curProduct.currency)
			)
		setRecalculateDisplayPrice(curProduct.recalculateDisplayPrice)
		currencies &&
			setDisplayCurrency(
				currencies.find(item => item.label === curProduct.displayCurrency)
			)
		setDisplayPrice(curProduct.displayPrice)
		setDisplayOldPrice(curProduct.displayOldPrice)
		languages &&
			curProduct &&
			setLanguageSelectedOption(
				languages.find(item => item.label === curProduct.language)
			)
		curProduct.files &&
			setProductFiles(curProduct.files.map(el => ({ ...el, disabled: true })))
		setStatus(curProduct.status)
		setFirstCheckbox(curProduct?.firstCheckbox || '')
		setSecondCheckbox(curProduct?.secondCheckbox || '')
		setCatalog(curProduct?.public)
		setCategories(curProduct?.categories || [])
		setDesignPersonalArea(curProduct?.designPersonalArea || 0)
		setContentAccessDays(curProduct?.contentAccessDays || 7)
		setPromo(curProduct?.promo || false)
		setSlug(curProduct?.slug || '')
		setAvailableLanguages(curProduct?.availableLanguages || '')
		setContentAvailable(curProduct?.contentAvailable || false)
		setCertIssueAvailable(curProduct?.certIssueAvailable || false)
		setRating(curProduct.rating || 5)
		setModulesCount(curProduct?.modulesCount || '')
		setIsRenewal(curProduct?.isRenewal || false)
		setIsPremium(curProduct?.isPremium || false)
		setIsLite(curProduct?.isLite || false)
	}, [curProduct, currencies, languages])

	useEffect(() => {
		if (firstRun) {
			setFirstRun(false)
		}
	}, [])

	useEffect(() => {
		if (firstRun && id) {
			dispatch(fileGetAll())
		} else {
			setAddExistingFilesSelectOptions(
				file
					.filter(f => !f.products.find(p => p.id === +id))
					.map(f => ({ value: f.id, label: `${f.id} | ${f.name} | ${f.path}` }))
			)
		}
	}, [file, fileGetAll, firstRun, id])

	useEffect(() => {
		if (modalStatus === 'yes') {
			dispatch(pixelDelete(infoPayload.id, +curProduct.id))
			dispatch(modalCleanData())
		}
		if (modalStatus === 'deleteFile') {
			setProductFiles(productFiles.filter(item => item.id !== infoPayload.id))
			dispatch(modalCleanData())
		}
	}, [modalStatus])

	const handleEditFiles = idx => {
		setProductFiles(
			productFiles.map((el, index) => {
				if (index === idx) el.disabled = !el.disabled
				return el
			})
		)
	}
	const handleFiles = (idx, value, parameter) => {
		setProductFiles(
			productFiles.map((el, index) => {
				if (index === idx) {
					el[parameter] = value
				}
				return el
			})
		)
	}

	const handleSave = () => {
		const currentFiles = productFiles.map(_ => ({
			id: _.id,
			'name-fd': _['name-fd'],
			'number-fd': _['number-fd']
		}))
		dispatch(
			productUpdate(id, {
				innerName,
				name,
				price,
				refundInitial,
				prepaidPrice,
				initialPrice,
				initialPriceAMD,
				oldPrice,
				tariff: tariff ? tariff : '',
				tariffAMD: tariffAMD ? tariffAMD : '',
				description,
				tariffDescription,
				shortDescription,
				followingInterval,
				initialInterval,
				files: addExistingFilesSelectValue.length
					? [
							...currentFiles,
							...addExistingFilesSelectValue.map(_ => ({ id: _.value }))
					  ]
					: currentFiles,
				portionSize: +portionSizeSelectedOption.value,
				dashboard: dashboardSelectedOption
					? dashboardSelectedOption.value
					: null,
				workScheme: workSchemeSelectedOption.value,
				background: productBackgroundFile,
				cover: productCoverFile,
				recalculateDisplayPrice,
				displayCurrency: displayCurrency.label,
				displayPrice,
				displayOldPrice,
				currency: currencySelectedOption.label,
				language: languageSelectedOption.label,
				status: +status,
				firstCheckbox,
				secondCheckbox,
				public: catalog,
				categories,
				designPersonalArea,
				contentAccessDays,
				promo,
				slug,
				availableLanguages: availableLanguages.toString(),
				contentAvailable,
				certIssueAvailable,
				rating,
				modulesCount: +modulesCount,
				isRenewal,
				isPremium,
				isLite
			})
		)
	}

	return (
		<div className='create'>
			{isLoad ? <Loader /> : ''}
			<h2 className='create__title'>Редактирование продукта</h2>
			<div className='create__inner'>
				<div className='create__inputs'>
					<Input
						placeholder=''
						type='text'
						name='innerName'
						inputCls='create__input'
						label='Имя'
						onChange={e => {
							setInnerName(e.target.value)
							if (errors?.innerName)
								dispatch(setProductCleanErrors('innerName'))
						}}
						value={innerName}
						error={errors?.innerName || ''}
					/>
					<Input
						placeholder=''
						type='text'
						name='name'
						inputCls='create__input'
						label='Имя для клиента'
						onChange={e => {
							setName(e.target.value)
							if (errors?.name) dispatch(setProductCleanErrors('name'))
						}}
						value={name}
						error={errors?.name || ''}
					/>
					<Input
						placeholder=''
						type='text'
						name='slug'
						inputCls='create__input'
						label='Slug'
						onChange={e => {
							setSlug(e.target.value)
							if (errors?.slug) dispatch(setProductCleanErrors('slug'))
						}}
						value={slug}
						error={errors?.slug || ''}
					/>
					<Input
						placeholder=''
						type='text'
						name='contentAccessDays'
						inputCls='create__input'
						label='Кол-во дней контента предоставляемых при покупке(билле)'
						onChange={e => {
							setContentAccessDays(parseInt(e.target.value) || '')
						}}
						value={contentAccessDays}
						error={errors?.contentAccessDays || ''}
					/>
					{/* Оценка на ленде */}
					<Input
						placeholder=''
						type='text'
						name='rating'
						inputCls='create__input'
						label='Оценка на ленде'
						onChange={e => {
							setRating(
								(e.target.value >= 0 &&
									e.target.value <= 5 &&
									String(e.target.value).length < 4 &&
									e.target.value) ||
									''
							)
						}}
						value={rating}
						error={errors?.rating || ''}
					/>
					{/* Язык */}
					<div className='product-crt-select'>
						<label className='input__label'>Язык</label>
						<Select
							options={languages}
							value={languageSelectedOption}
							onChange={setLanguageSelectedOption}
							placeholder='Выберите язык'
						/>
					</div>

					<div className='product-crt-select'>
						<label className='input__label'>Язык продукта</label>
						<Select
							isMulti={true}
							options={languageOption}
							value={languageOption.filter(lang =>
								availableLanguages.includes(lang.value)
							)}
							onChange={o =>
								setAvailableLanguages(o.map(item => item.value).toString())
							}
							placeholder='Выберите язык'
						/>
					</div>
					{/* Валюта */}
					<div className='product-crt-select'>
						<label className='input__label'>Валюта</label>
						<Select
							options={currencies}
							value={currencySelectedOption}
							onChange={setCurrencySelectedOption}
							placeholder='Выберите валюту'
						/>
					</div>
					{/* Изначальная цена */}
					<Input
						placeholder=''
						type='text'
						name='initialPrice'
						inputCls='create__input'
						label='Инициализационная цена'
						onChange={e => {
							setInitialPrice(e.target.value)
							if (errors?.initialPrice)
								dispatch(setProductCleanErrors('initialPrice'))
						}}
						value={initialPrice}
						error={errors?.initialPrice || ''}
					/>
					{/* Изначальная цена AMD */}
					<Input
						placeholder=''
						type='text'
						name='initialPriceAMD'
						inputCls='create__input'
						label='Инициализационная цена AMD'
						onChange={e => {
							setInitialPriceAMD(e.target.value)
							if (errors?.initialPriceAMD)
								dispatch(setProductCleanErrors('initialPriceAMD'))
						}}
						value={initialPriceAMD}
						error={errors?.initialPriceAMD || ''}
					/>
					<div className='input input-checkbox'>
						<label className='input__label'>
							Возврат инициализационного платежа
						</label>
						<input
							className='input__item'
							type='checkbox'
							checked={refundInitial}
							onChange={() => setRefundInitial(!refundInitial)}
						/>
					</div>

					<div className='input input-checkbox'>
						<label className='input__label'>Премиум</label>
						<input
							className='input__item'
							type='checkbox'
							checked={isPremium}
							onChange={() => setIsPremium(!isPremium)}
						/>
					</div>

					<div className='input input-checkbox'>
						<label className='input__label'>Лайт</label>
						<input
							className='input__item'
							type='checkbox'
							checked={isLite}
							onChange={() => setIsLite(!isLite)}
						/>
					</div>

					<div className='input input-checkbox'>
						<label className='input__label'>Реневал</label>
						<input
							className='input__item'
							type='checkbox'
							checked={isRenewal}
							onChange={() => setIsRenewal(!isRenewal)}
						/>
					</div>

					<Input
						placeholder=''
						type='text'
						name='price'
						inputCls='create__input'
						label='Цена'
						onChange={e => {
							setPrice(e.target.value)
							if (errors?.price) dispatch(setProductCleanErrors('price'))
						}}
						value={price}
						error={errors?.price || ''}
					/>
					<Input
						placeholder=''
						type='text'
						name='oldPrice'
						inputCls='create__input'
						label='Старая цена'
						onChange={e => {
							setOldPrice(e.target.value)
							if (errors?.oldPrice) dispatch(setProductCleanErrors('oldPrice'))
						}}
						value={oldPrice}
						error={errors?.oldPrice || ''}
					/>
					<Input
						placeholder=''
						type='text'
						name='prepaidPrice'
						inputCls='create__input'
						label='Сумма аванса'
						onChange={e => {
							setPrepaidPrice(e.target.value)
							if (errors?.prepaidPrice)
								dispatch(setProductCleanErrors('prepaidPrice'))
						}}
						value={prepaidPrice}
						error={errors?.prepaidPrice || ''}
					/>
					<Input
						placeholder=''
						type='text'
						name='tariff'
						inputCls='create__input'
						label='Тариф'
						onChange={e => {
							setTariff(e.target.value)
							if (errors?.tariff) dispatch(setProductCleanErrors('tariff'))
						}}
						value={tariff}
						error={errors?.tariff || ''}
					/>
					<Input
						placeholder=''
						type='text'
						name='modulesCount'
						inputCls='create__input'
						label='Количество модулей'
						onChange={e => {
							setModulesCount(e.target.value)
						}}
						value={modulesCount}
					/>
					<Input
						placeholder=''
						type='text'
						name='tariffAMD'
						inputCls='create__input'
						label='Тариф AMD'
						onChange={e => {
							setTariffAMD(e.target.value)
							if (errors?.tariffAMD) dispatch(setProductCleanErrors('tariff'))
						}}
						value={tariffAMD}
						error={errors?.tariffAMD || ''}
					/>
					<div className='product-crt-select'>
						<label className='input__label'>Валюта (на ленде)</label>
						<Select
							options={currencies}
							value={displayCurrency}
							onChange={setDisplayCurrency}
							placeholder='Выберите Валюту (на ленде)'
						/>
					</div>
					{/* Автоматически пересчитывать цену на ленде */}
					<div className='recalculate-checkbox input-checkbox'>
						<label>Автоматически пересчитывать цену на ленде</label>
						<input
							type='checkbox'
							checked={recalculateDisplayPrice}
							onChange={() =>
								setRecalculateDisplayPrice(!recalculateDisplayPrice)
							}
						/>
					</div>
					<div className='recalculate-checkbox input-checkbox'>
						<label>Показывать в витрине</label>
						<input
							type='checkbox'
							checked={catalog}
							onChange={() => setCatalog(!catalog)}
							disabled={id === '135' || id === '180'}
						/>
					</div>
					<div className='recalculate-checkbox input-checkbox'>
						<label>Промо</label>
						<input
							type='checkbox'
							checked={promo}
							onChange={() => setPromo(!promo)}
						/>
					</div>
					<div className='recalculate-checkbox input-checkbox'>
						<label>Не показывать вкладку содержание</label>
						<input
							type='checkbox'
							checked={!contentAvailable}
							onChange={() => setContentAvailable(!contentAvailable)}
						/>
					</div>
					<div className='recalculate-checkbox input-checkbox'>
						<label>Не давать сертификат</label>
						<input
							type='checkbox'
							checked={!certIssueAvailable}
							onChange={() => setCertIssueAvailable(!certIssueAvailable)}
						/>
					</div>
					<Input
						placeholder=''
						type='text'
						name='displayPrice'
						inputCls='create__input'
						label='Цена (на ленде)'
						onChange={e => {
							setDisplayPrice(e.target.value)
							if (errors?.displayPrice)
								dispatch(setProductCleanErrors('displayPrice'))
						}}
						value={displayPrice}
						error={errors?.displayPrice || ''}
						disabled={recalculateDisplayPrice}
					/>
					<Input
						placeholder=''
						type='text'
						name='displayOldPrice'
						inputCls='create__input'
						label='Старая цена (на ленде)'
						onChange={e => {
							setDisplayOldPrice(e.target.value)
							if (errors?.displayOldPrice)
								dispatch(setProductCleanErrors('displayOldPrice'))
						}}
						value={displayOldPrice}
						error={errors?.displayOldPrice || ''}
						disabled={recalculateDisplayPrice}
					/>
					<Input
						placeholder=''
						type='text'
						name='initialInterval'
						inputCls='create__input'
						label='Время до первого ребилла (в часах)'
						onChange={e => {
							setInitialInterval(e.target.value)
							if (errors?.initialInterval)
								dispatch(setProductCleanErrors('initialInterval'))
						}}
						value={initialInterval}
						error={errors?.initialInterval || ''}
					/>
					<Input
						placeholder=''
						type='text'
						name='followingInterval'
						inputCls='create__input'
						label='Время между ребиллами (в часах)'
						onChange={e => {
							setFollowingInterval(e.target.value)
							if (errors?.followingInterval)
								dispatch(setProductCleanErrors('followingInterval'))
						}}
						value={followingInterval}
						error={errors?.followingInterval || ''}
					/>

					<div className='create__textarea'>
						<div className='create__textfield-wrapper'>
							<label className='input__label'>Текст первого чекбокса</label>
							<div className='textarea-checkboxValue'>
								<textarea
									onChange={e => {
										setFirstCheckbox(e.target.value)
									}}
									placeholder=''
									name='firstCheckbox'
									value={firstCheckbox}
									wrap='soft'
								/>
								<div>
									{`{initial_interval_days} => первое списание в днях`} <br />
									{`{initial_interval_hours} => первое списание в часах`} <br />
									{`{initial_sum} => сумма первого списания`} <br />
									<br />
									{`{following_interval_days} => ребилл согласно тарифу в днях`}{' '}
									<br />
									{`{following_interval_hours} => ребилл согласно тарифу в часах`}{' '}
									<br />
									{`{following_interval_sum} => сумма ребилла за весь интервал`}{' '}
									<br />
									{`{following_day_sum} => сумма ребилла с пересчетом на день`}{' '}
									<br />
									<br />
									{`{link_permission} => url на защиту ПД`} <br />
									{`{link_offer} => url на офферту`} <br />
									{`{link_tariff} => url на тариф`} <br />
									{`{link_cabinet} => url на ЛК`} <br />
								</div>
							</div>
						</div>
					</div>
					<div className='create__textarea'>
						<div className='create__textfield-wrapper'>
							<label className='input__label'>Текст второго чекбокса</label>
							<div className='textarea-checkboxValue'>
								<textarea
									onChange={e => {
										setSecondCheckbox(e.target.value)
									}}
									placeholder=''
									name='secondCheckbox'
									value={secondCheckbox}
									wrap='soft'
								/>
								<div>
									{`{initial_interval_days} => первое списание в днях`} <br />
									{`{initial_interval_hours} => первое списание в часах`} <br />
									{`{initial_sum} => сумма первого списания`} <br />
									<br />
									{`{following_interval_days} => ребилл согласно тарифу в днях`}{' '}
									<br />
									{`{following_interval_hours} => ребилл согласно тарифу в часах`}{' '}
									<br />
									{`{following_interval_sum} => сумма ребилла за весь интервал`}{' '}
									<br />
									{`{following_day_sum} => сумма ребилла с пересчетом на день`}{' '}
									<br />
									<br />
									{`{link_permission} => url на защиту ПД`} <br />
									{`{link_offer} => url на офферту`} <br />
									{`{link_tariff} => url на тариф`} <br />
									{`{link_cabinet} => url на ЛК`} <br />
								</div>
							</div>
						</div>
					</div>

					<div className='product-crt-select'>
						<label className='input__label'>Тип дашборда</label>
						<Select
							options={dashboardOptions}
							value={dashboardSelectedOption}
							onChange={setDashboardSelectedOption}
							placeholder='Выберите тип дашборда'
							isClearable
						/>
					</div>

					<div className='product-crt-select'>
						<label className='input__label'>Размер выдачи контента</label>
						<Select
							options={portionSizeOptions}
							value={portionSizeSelectedOption}
							onChange={setPortionSizeSelectedOption}
							placeholder='Выберите размер выдачи'
						/>
					</div>

					<div className='product-crt-select'>
						<label className='input__label'>Схемы работы</label>
						<Select
							options={workSchemeOptions}
							value={workSchemeSelectedOption}
							onChange={setWorkSchemeSelectedOption}
							placeholder='Выберите схемы работы'
						/>
					</div>

					<div className='product-crt-select'>
						<label className='input__label'>Категория продукта</label>
						<Select
							isMulti={true}
							options={categoriesOption}
							getOptionLabel={option => option.name}
							getOptionValue={option => option.id}
							value={categories}
							// onChange={(o) => setCategories(o.map((item) => item.id))}
							onChange={setCategories}
							placeholder='Выберите категорию'
						/>
					</div>

					<div className='product-crt-select'>
						<label className='input__label'>Дизайн личного кабинета</label>
						<Select
							options={designPersonalAreaOption}
							value={
								designPersonalAreaOption[
									designPersonalAreaOption.findIndex(
										el => el.value === designPersonalArea
									)
								]
							}
							onChange={option => setDesignPersonalArea(option.value)}
							placeholder='Выберите дизайн ЛК'
						/>
					</div>

					<FileInput
						label='Загрузки файла фона'
						name='edit-product-background'
						previewURL={previewBackgroundURL ? previewBackgroundURL : ''}
						onChange={e => {
							let reader = new FileReader()
							let file = e.target.files[0]
							reader.onloadend = () => {
								setProductBackgroundFile(file)
								setPreviewBackgroundURL(reader.result)
							}
							reader.readAsDataURL(file)
						}}
						inputCls='create-file-input'
					/>
					<FileInput
						label='Загрузки файла обложки'
						name='create-product-cover'
						previewURL={previewCoverURL ? previewCoverURL : ''}
						onChange={e => {
							let reader = new FileReader()
							let file = e.target.files[0]
							reader.onloadend = () => {
								setProductCoverFile(file)
								setPreviewCoverURL(reader.result)
							}
							reader.readAsDataURL(file)
						}}
						inputCls='create-file-input'
					/>
					<div className='product-crt-select'>
						<label className='input__label'>Статус</label>
						<Select
							options={productStatusList}
							value={
								productStatusList[
									productStatusList.findIndex(el => el.value == status)
								]
							}
							onChange={option => {
								setStatus(option.value)
							}}
							placeholder='Выберите статус'
						/>
					</div>
					{curProduct.id ? null : (
						<DropDown
							text='Files'
							arrayOfValues={allFiles}
							onChange={setAllFiles}
						/>
					)}
				</div>
				<div className='create__inputs'>
					<div className='create__textfield-wrapper'>
						<label>Описание продукта</label>
						<ProductDescr
							onChange={e => {
								setDescription(e.target.value)
								if (errors?.description)
									dispatch(setProductCleanErrors('description'))
							}}
							value={description}
							error={errors?.description || ''}
						/>
					</div>
					<div className='create__textfield-wrapper'>
						<label>Архив тарифов</label>
						<ProductDescr
							onChange={e => {
								setTariffDescription(e.target.value)
								if (errors?.tariffDescription)
									dispatch(setProductCleanErrors('tariffDescription'))
							}}
							value={tariffDescription}
							error={errors?.tariffDescription || ''}
						/>
					</div>
					<div className='create__textfield-wrapper'>
						<label>Короткое описание</label>
						<ProductDescr
							onChange={e => {
								setShortDescription(e.target.value)
							}}
							value={shortDescription}
						/>
					</div>
				</div>
			</div>

			{curProduct.id ? (
				<>
					<span>Файлы:</span>
					<ButtonLink
						btnClass='create__btn'
						text='Добавить новый файл'
						href={`/file/${curProduct.id}/add`}
					/>
					<Select
						placeholder='Добавить существующие файлы'
						noOptionsMessage={() => 'Нет файлов'}
						options={addExistingFilesSelectOptions}
						value={addExistingFilesSelectValue}
						onChange={setAddExistingFilesSelectValue}
						isMulti
						closeMenuOnSelect={false}
					/>
					<Table
						titles={productFilesTitles}
						keyProp='productFiles'
						content={productFiles}
						btnEdit={handleEditFiles}
						handleChange={handleFiles}
					/>
					<span>Домены:</span>
					<Table
						titles={productDomainsTitles}
						keyProp='productDomains'
						content={curProduct.domains}
					/>
					<span>Пиксели:</span>
					<ButtonLink
						btnClass='create__btn'
						text='Добавить пискель'
						href={`/pixel/${curProduct.id}/add`}
					/>
					<Table
						style={{ marginBottom: 40 }}
						titles={pixelTableTitles}
						keyProp={'pixels'}
						content={curProduct.pixels}
						productId={curProduct?.id}
					/>
				</>
			) : null}
			<button
				className='button'
				disabled={!name && !price && !oldPrice}
				onClick={handleSave}
			>
				Сохранить
			</button>
			<Link className='create__prev' to='' onClick={() => history.goBack()}>
				Назад к списку
			</Link>
		</div>
	)
}

export default ProductEditPage

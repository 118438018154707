import React, { useEffect, useState } from 'react';
import Header from 'assets/components/Header';
import Table from 'assets/components/Table';
import Loader from 'assets/components/Loader';
import Pagination from 'rc-pagination';
import Filters from 'assets/components/Filters';
import EditGatewayModal from './EditGatewayModal';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store';
import { getAllGatewaysPage, deleteGateway, setGatewayPage, setGatewayFilter, getRatesList } from 'store/slices/gateway';
import { companyGetAll } from 'store/slices/company';
import { modalCleanData } from 'store/slices/modal';
import {
  setGatewaysMassUpdateStatus,
  gatewaysMassUpdate,
  setGatewaysMassUpdateMessageSuccess,
} from 'store/slices/gateway';

const titles = [
  {
    name: 'ID',
    mod: 'sm',
  },
  {
    name: 'ПЛАТЕЖКА',
  },
  {
    name: 'НАЗВАНИЕ',
  },
  {
    name: 'Статус',
  },
  {
    name: 'КОММЕНТАРИЙ',
    mod: 'big',
  },
  {
    name: 'REBILL',
    mod: 'big',
  },
  {
    name: 'URL',
  },
  {
    name: 'ЮрЛицо',
  },
  {
    name: 'Банк',
    mod: 'm',
  },
  {
    name: 'sberbill',
    mod: 'm',
  },
  {
    name: 'sberroute',
    mod: 'm',
  },
  {
    name: 'Тариф',
    mod: 'm',
  },
  {
    name: 'ДОМЕНЫ',
  },
  {
    name: 'ДАННЫЕ',
    mod: 'big',
  },
  // {
  //   name: 'APPLEPAY',
  // },
  {
    name: '...',
    mod: 'm',
  },
];

const GatewayPage = () => {
  const dispatch = useAppDispatch();
  const gateways = useAppSelector((state) => state.gateway.gateways);
  const isLoad = useAppSelector((state) => state.gateway.isLoading);
  const appliedFilters = useAppSelector((state) => state.gateway.filters);
  const companies = useAppSelector((state) => state.company.list);

  const limit = useAppSelector((state) => state.gateway.pagination.limit);
  const page = useAppSelector((state) => state.gateway.pagination.page);
  const total = useAppSelector((state) => state.gateway.pagination.total);

  const modalStatus = useAppSelector((state) => state.modal.modalStatus);
  const infoPayload = useAppSelector((state) => state.modal.infoPayload);

  const availableEdit = useAppSelector((state) => state.auth.role.permissions.gateway).includes('edit_gateways');
  const availableMove = useAppSelector((state) => state.auth.role.permissions.gateway).includes('move_gateways');

  const [checkGateways, setCheckGateways] = useState();
  const [isCheckGateway, setIsCheckGateway] = useState([]);
  const [isCheckAllGateway, setIsCheckAllGateway] = useState(false);
  const [activeEdit, setActiveEdit] = useState(false);

  const [sberbill, setSberbill] = useState('');
  const [sberroute, setSberroute] = useState('');
  const [isDirty, setIsDirty] = useState('');
  const [status, setStatus] = useState('');
  const [stopRecurrentByGateway, setStopRecurrentByGateway] = useState('');
  const [stopRecurrentByCompany, setStopRecurrentByCompany] = useState('');
  const [scheme, setScheme] = useState('');

  const [reloadPage, setReloadPage] = useState(true);

  const error = useAppSelector((state) => state.gateway.errorMassUpdate);
  const massUpdateStatus = useAppSelector((state) => state.gateway.massUpdateStatus);
  const massUpdateMessage = useAppSelector((state) => state.gateway.massUpdateMessage);

  useEffect(() => {
    dispatch(getRatesList())
  }, []);

  useEffect(() => {
    setCheckGateways(gateways);
  }, [gateways]);

  const handleClickCheck = (e) => {
    const checked = e.target.checked;
    const id = Number(e.target.id);
    setIsCheckGateway([...isCheckGateway, id]);
    if (!checked) {
      setIsCheckGateway(isCheckGateway.filter((item) => item !== id));
    }
  };

  const handleClickCheckSelectAll = (e) => {
    setIsCheckAllGateway(!isCheckAllGateway);
    setIsCheckGateway(checkGateways.map((li) => li.id));
    if (isCheckAllGateway) {
      setIsCheckGateway([]);
    }
  };

  const handleClickStartEdit = () => {
    setActiveEdit(true);
  };

  const handleCliсkCloseTable = () => {
    setActiveEdit(false);
    setIsCheckGateway([]);
    setIsCheckAllGateway(false);
    setSberbill('');
    setSberroute('');
    setIsDirty('');
    setStatus('');
    setStopRecurrentByGateway('');
    setStopRecurrentByCompany('');
    setScheme('');
  };

  const handleClickMassUpdate = () => {
    const obj = {
      ids: isCheckGateway,
      sberbill: sberbill,
      sberroute: sberroute,
      isDirty: isDirty,
      status: status,
      stopRecurrentByGateway: stopRecurrentByGateway,
      stopRecurrentByCompany: stopRecurrentByCompany,
      scheme: scheme,
    };

    for (let key in obj) {
      if (obj[key] === '') {
        delete obj[key];
      }
    }

    dispatch(gatewaysMassUpdate(obj));
  };

  useEffect(() => {
    if (massUpdateStatus === 'success') {
      handleCliсkCloseTable();
      toast.success(`Редактирование прошло успешно ${massUpdateMessage}`);
      dispatch(setGatewaysMassUpdateStatus(''));
      dispatch(setGatewaysMassUpdateMessageSuccess(''));
      setSberbill('');
      setSberroute('');
      setIsDirty('');
      setStatus('');
      setStopRecurrentByGateway('');
      setStopRecurrentByCompany('');
      setScheme('');
    }
    if (massUpdateStatus === 'error') {
      toast.error(error);
      dispatch(setGatewaysMassUpdateStatus(''));
      setSberbill('');
      setSberroute('');
      setIsDirty('');
      setStatus('');
      setStopRecurrentByGateway('');
      setStopRecurrentByCompany('');
      setScheme('');
    }
  }, [massUpdateStatus, dispatch]);

  const handleGetGateways = async () => {
    const params = {};

    for (let key in appliedFilters) {
      if (appliedFilters[key]) {
        params[`filter[${key}]`] = await appliedFilters[key];
      }
    }

    params.limit = limit;
    params.page = page;

    dispatch(getAllGatewaysPage(params));
  };

  useEffect(() => {
    dispatch(companyGetAll());
  }, []);

  useEffect(() => {
    if (reloadPage) {
      handleGetGateways();
      setReloadPage(false);
    }
  }, [reloadPage]);

  useEffect(() => {
    if (modalStatus === 'yes') {
      dispatch(deleteGateway(infoPayload.id));
      dispatch(modalCleanData());
    }
  }, [modalStatus, dispatch]);

  const handlePageChange = (current) => {
    dispatch(setGatewayPage(current));
    setReloadPage(true);
  };

  const companiesOption = companies.map((item) => ({ value: item.id, label: item.name }));

  return (
    <>
      {isLoad ? <Loader /> : ''}
      <Header
        title='Шлюзы'
        text={availableEdit ? 'Добавить шлюз' : null}
        href={availableEdit ? '/gateway/add' : null}
        secondText={availableMove ? 'Перенос' : null}
        secondHref={availableMove ? '/gateway/transfer' : null}
        thirdText={availableEdit ? 'CберРоут' : null}
        thirdHref={availableEdit ? '/gateway/sberout' : null}
        fourthText={availableEdit ? 'Статистика' : null}
        fourthHref={availableEdit ? '/gateway/statistics' : null}
      />
      <Filters
        appliedFilters={{ ...appliedFilters }}
        onFilterChange={(obj) => {
          dispatch(setGatewayFilter(obj));
          setReloadPage(true);
        }}
        gatewayPage={true}
        statusLabels={[
          { label: 'Активный', value: '1' },
          { label: 'Неактивный', value: '2' },
        ]}
        companiesOption={companiesOption}
        limitPage={limit}
      />
      <Table
        titles={titles}
        content={gateways}
        keyProp='gateway'
        isCheck={isCheckGateway}
        isCheckAll={isCheckAllGateway}
        handleClickCheck={handleClickCheck}
        handleClickCheckSelectAll={handleClickCheckSelectAll}
        handleClickStartEdit={handleClickStartEdit}
      />
      <Pagination
        className='order__pagination'
        current={page}
        total={total}
        pageSize={limit}
        onChange={handlePageChange}
        showTitle={false}
      />
      {activeEdit && (
        <EditGatewayModal
          handleCliсkCloseTable={handleCliсkCloseTable}
          sberbill={sberbill}
          setSberbill={setSberbill}
          sberroute={sberroute}
          setSberroute={setSberroute}
          handleClickMassUpdate={handleClickMassUpdate}
          isDirty={isDirty}
          setIsDirty={setIsDirty}
          status={status}
          setStatus={setStatus}
          stopRecurrentByGateway={stopRecurrentByGateway}
          setStopRecurrentByGateway={setStopRecurrentByGateway}
          stopRecurrentByCompany={stopRecurrentByCompany}
          setStopRecurrentByCompany={setStopRecurrentByCompany}
          scheme={scheme}
          setScheme={setScheme}
        />
      )}
    </>
  );
};

export default GatewayPage;

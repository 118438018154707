import React from 'react';
import Table from 'assets/components/Table/Table';
import Input from 'assets/components/Input';
import Select from 'react-select';
import { useAppSelector, useAppDispatch } from 'store';
import { showModal, modalCleanData } from 'store/slices/modal';
import {
  variantPageOptions,
  variantFooterOptions,
  landFooterOptions,
} from 'constants/dashboardOptions/dashboardOptions';
import styles from './EditModalDomain.scss';

const optionStatus = [
  { value: 1, label: 'Активный' },
  { value: 2, label: 'Неактивный' },
  { value: '', label: 'По дефолту' },
];

const styles1 = {
  container: (provided) => ({
    ...provided,
    display: 'inline-block',
    width: '100%',
    minWidth: '250px',
    minHeight: '1px',
    textAlign: 'left',
    border: 'none',
  }),
};

const selectStyles = {
  container: (provided) => ({
    ...provided,
    display: 'inline-block',
    width: '100%',
    height: '80px',
    minHeight: '1px',
    textAlign: 'left',
    border: 'none',
  }),
  control: (provided) => ({
    ...provided,
    height: '80px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '80px',
  }),
};

const EditModalDomain = ({
  formatOptionLabel,
  paymentSystem,
  setPaymentSystemToAdd,
  setPaymentSystemTitleToAdd,
  setPaymentGatewayToAdd,
  paymentChanceToAdd,
  handlePaymentChanceToAddChange,
  handlePaymentSettingsAdd,
  paymentSettingsAdd,
  paymentSystemTitleToAdd,
  setPaymentSettingsAdd,
  handleCliсkCloseTable,
  paymentGatewayToAdd,
  handleClickMassUpdate,
  noRef,
  setNoRef,
  res,
  setRes,
  sberbill,
  setSberbill,
  activeStatus,
  setActiveStatus,
  auth,
  setAuth,
  refund,
  setRefund,
  unsubscribe,
  setUnsubscribe,
  footer,
  setFooter,
  landFooter,
  setLandFooter,
}) => {
  const dispatch = useAppDispatch();
  const modalStatus = useAppSelector((state) => state.modal.modalStatus);

  React.useEffect(() => {
    if (modalStatus === 'changeStatus') {
      dispatch(modalCleanData());
    }
    if (modalStatus === 'No') {
      setActiveStatus('');
      dispatch(modalCleanData());
    }
  }, [modalStatus]);

  const handleResetVariantOfPage = () => {
    setAuth('');
    setRefund('');
    setUnsubscribe('');
    setFooter('');
    setLandFooter(null);
  };

  const disabledStyledPage =
    (auth === '' && auth === refund && auth === unsubscribe) ||
    (auth === refund && auth === unsubscribe && auth !== '') ||
    landFooter !== null ||
    footer === '';

  return (
    <div className='editModalDomain'>
      <div className='editModalDomain__container'>
        <div className='editModalDomain__header'>
          <div>Групповое редактирование</div>
          <div className='editModalDomain__header__close' onClick={handleCliсkCloseTable}>
            X
          </div>
        </div>
        <div>
          <h3 className='add-to-domain-payment-settings-title'>Добавить платежные настройки</h3>
          <div className='add-paymentSettingsDomain--maassUpdate'>
            <div className='add-paymentSettingsDomain__paymentSystem--massUpdate'>
              <label className='input__label'>Селектор</label>
              <Select
                value={paymentSystem.filter((option) => option.paymentSystemTitle === paymentSystemTitleToAdd)}
                options={paymentSystem}
                styles={selectStyles}
                formatOptionLabel={formatOptionLabel}
                onChange={(data) => {
                  setPaymentSystemToAdd(data.paymentSystem);
                  setPaymentGatewayToAdd(data.name);
                  setPaymentSystemTitleToAdd(data.paymentSystemTitle);
                }}
              />
            </div>
            <div className='add-paymentSettingsDomain__chance'>
              <label className='input__label'>Вероятность</label>
              <Input
                value={paymentChanceToAdd}
                onChange={handlePaymentChanceToAddChange}
                placeholder='Вероятность'
                type='number'
                inputCls='input__chance'
              />
            </div>
            <div className='input-checkbox-noRef'>
              <label className='input__label'>NoRef</label>
              <input checked={noRef} type='checkbox' onChange={() => setNoRef(!noRef)} />
            </div>
            <div className='input-checkbox-noRef'>
              <label className='input__label'>Res</label>
              <input checked={res} type='checkbox' onChange={() => setRes(!res)} />
            </div>
            <div className='input-checkbox-noRef'>
              <label className='input__label'>SberBill</label>
              <input checked={sberbill} type='checkbox' onChange={() => setSberbill(!sberbill)} />
            </div>
            <div className='add-paymentSettingsDomain__btn'>
              <button
                disabled={!paymentChanceToAdd || !paymentGatewayToAdd}
                onClick={handlePaymentSettingsAdd}
                className='button'
              >
                Добавить
              </button>
            </div>
          </div>
          <div className='editModalDomain__selectStylePage'>
            <div className='domain-crt-select--mb'>
              <label className='input__label'>Статус</label>
              <Select
                styles={styles1}
                options={optionStatus}
                value={optionStatus[optionStatus.findIndex((el) => el.value === activeStatus)]}
                onChange={(option) => {
                  setActiveStatus(option.value);
                  dispatch(showModal(`Домены изменят статус на: "${option.label}"?`, {}, 'changeStatus'));
                }}
                placeholder='Выберите статус'
              />
            </div>
            <div className='domain-crt-select--mb'>
              <label className='input__label'>Footer</label>
              <Select
                styles={styles1}
                options={variantFooterOptions}
                value={variantFooterOptions[variantFooterOptions.findIndex((el) => el.value === footer)] || ''}
                onChange={(option) => {
                  setFooter(option.value);
                }}
                placeholder='Выберите вариант footer'
              />
            </div>
            <div className='domain-crt-select--mb'>
              <label className='input__label'>LandFooter</label>
              <Select
                styles={styles1}
                options={landFooterOptions}
                value={landFooterOptions[landFooterOptions.findIndex((el) => el.value === landFooter)] || ''}
                onChange={(option) => {
                  setLandFooter(option.value);
                }}
                placeholder='Выберите вариант landFooter'
              />
            </div>
          </div>
          <div className='editModalDomain__selectStylePage'>
            <div className='domain-crt-select--mb'>
              <label className='input__label'>Unsubscribe Page</label>
              <Select
                styles={styles1}
                options={variantPageOptions}
                value={variantPageOptions[variantPageOptions.findIndex((el) => el.value === unsubscribe)] || ''}
                onChange={(option) => {
                  setUnsubscribe(option.value);
                }}
                placeholder='Выберите вариант страницы'
              />
            </div>
            <div className='domain-crt-select--mb'>
              <label className='input__label'>Refund Page</label>
              <Select
                styles={styles1}
                options={variantPageOptions}
                value={variantPageOptions[variantPageOptions.findIndex((el) => el.value === refund)] || ''}
                onChange={(option) => {
                  setRefund(option.value);
                }}
                placeholder='Выберите вариант страницы'
              />
            </div>
            <div className='domain-crt-select--mb'>
              <label className='input__label'>Auth Page</label>
              <Select
                styles={styles1}
                options={variantPageOptions}
                value={variantPageOptions[variantPageOptions.findIndex((el) => el.value === auth)] || ''}
                onChange={(option) => {
                  setAuth(option.value);
                }}
                placeholder='Выберите вариант страницы'
              />
            </div>
          </div>
          <div className='editModalDomain__buttons'>
            <button onClick={handleResetVariantOfPage} className='editModalDomain__buttons__add'>
              Сбросить
            </button>
          </div>
          <div className='editModalDomain__table'>
            <Table
              content={paymentSettingsAdd}
              keyProp='domainPaymentSettingsEdit'
              titles={[
                { name: 'Платежная система' },
                { name: 'Платежный шлюз', mod: 'big' },
                { name: 'Вероятность', mod: 'big' },
                { name: 'noref' },
                { name: 'res' },
                { name: 'sberbill' },
                { name: '...' },
              ]}
              btnDelete={(i) => setPaymentSettingsAdd((prev) => prev.filter((_, index) => i !== index))}
            />
          </div>
          <div className='editModalDomain__buttons'>
            <button type='button' onClick={handleCliсkCloseTable} className='editModalDomain__buttons__cancel'>
              Отмена
            </button>
            <button
              type='button'
              onClick={handleClickMassUpdate}
              className='editModalDomain__buttons__add'
              disabled={
                !disabledStyledPage ||
                (auth === '' &&
                  !paymentSettingsAdd.length &&
                  activeStatus === '' &&
                  footer === '' &&
                  landFooter === null)
              }
            >
              Сохранить
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { EditModalDomain };

import React from 'react'
import ButtonLink from '../ButtonLink'
import Avatar from 'assets/img/avatar.png'
import { Link } from 'react-router-dom'
import { ReactComponent as Plus } from 'assets/img/plus.svg'

const Header = ({
	title,
	href,
	text,
	secondHref,
	secondText,
	thirdHref,
	thirdText,
	fourthText,
	fourthHref
}) => (
	<header className='header'>
		<h1 className='header__title'>{title}</h1>
		{href &&
		secondHref &&
		secondText &&
		thirdText &&
		thirdHref &&
		fourthText &&
		fourthHref ? (
			<div className='header-third'>
				<ButtonLink
					plus={true}
					btnClass='header__btn'
					text={fourthText}
					href={fourthHref}
				/>
				<ButtonLink
					plus={true}
					btnClass='header__btn'
					text={thirdText}
					href={thirdHref}
				/>
				<ButtonLink btnClass='header__btn' text={text || href} href={href} />
				<ButtonLink
					plus={true}
					btnClass='header__btn'
					text={secondText}
					href={secondHref}
				/>
				<img src={Avatar} alt='avatar' className='header__ava' />
			</div>
		) : href && secondHref && secondText ? (
			<div className='header-second'>
				<ButtonLink btnClass='header__btn' text={text || href} href={href} />
				<ButtonLink
					plus={true}
					btnClass='header__btn'
					text={secondText || secondHref}
					href={secondHref}
				/>
				<img src={Avatar} alt='avatar' className='header__ava' />
			</div>
		) : href ? (
			<div className='header__container'>
				<Link to={href} className='header__link'>
					<Plus />
					<span>{text || href}</span>
				</Link>
				<img src={Avatar} alt='avatar' className='header__ava' />
			</div>
		) : (
			<img src={Avatar} alt='avatar' className='header__ava' />
		)}
	</header>
)

export default Header

import React, { useEffect } from 'react'
import Header from 'assets/components/Header'
import { useDispatch, useSelector } from 'react-redux'
import {
	auth2FaOnLogin,
	auth2FaQrCodeLogin,
	auth2FaTokenLogin
} from 'actions/auth'

const Settings2FaPage = () => {
	const dispatch = useDispatch()
	const errorMessage = useSelector(state => state.auth.auth2FaError)
	const qrCode = useSelector(state => state.auth.qrCode)
	const token = useSelector(state => state.auth.token)
	const statusAuth2Fa = useSelector(state => state.auth.statusAuth2Fa)

	useEffect(() => {
		if (statusAuth2Fa === 200) {
			dispatch(auth2FaTokenLogin())
			dispatch(auth2FaQrCodeLogin())
		}
	}, [statusAuth2Fa])

	return (
		<>
			<Header title='2FA Аутентификация' />
			<div className='autintification__group-button'>
				<div>Включить 2FA Аутентификацию</div>
				<button onClick={() => dispatch(auth2FaOnLogin())}>Включить</button>
			</div>
			{statusAuth2Fa === 200 ? (
				<div className='autintification__success'>
					2FA Аутентификация включена
				</div>
			) : null}
			{errorMessage !== '' ? (
				<div className='autintification__error'>
					{errorMessage === '2FA already enabled'
						? '2FA Аутентификация уже включна'
						: errorMessage}
				</div>
			) : null}
			{qrCode != null ? (
				<img className='autintification__qrCode' src={qrCode} alt='qr-code' />
			) : null}
			{token !== '' ? <div>Token: {token}</div> : null}
		</>
	)
}

export default Settings2FaPage

import { combineReducers } from 'redux'
import { refund } from 'store/slices/refund'
import { order } from 'store/slices/order'
import { domain } from 'store/slices/domain'
import { product } from 'store/slices/product'
import { admin } from 'store/slices/admin'
import { user } from 'store/slices/user'
import auth from './auth'
import file from './file'
import { modal } from 'store/slices/modal'
import { redirect } from 'store/slices/redirect'
import { sberalert } from 'store/slices/sberalert'
import { company } from 'store/slices/company'
import { sberout } from 'store/slices/sberout'
import { statistics } from 'store/slices/statistics'
import { currency } from 'store/slices/currency'
import { language } from 'store/slices/language'
import { gateway } from 'store/slices/gateway'
import { sberBill } from 'store/slices/sberbill'
import { chargebacks } from 'store/slices/chargebacks'
import { errors } from 'store/slices/errors'
import { banks } from 'store/slices/banks'
import { analytics } from 'store/slices/analytics'
import { analyticsmids } from 'store/slices/analyticsmids'
import { strikes } from 'store/slices/strikes'
import { pixel } from 'store/slices/pixel'
import { shaker } from 'store/slices/shaker'
import { promo } from 'store/slices/promo'

export default combineReducers({
	auth,
	refund,
	order,
	domain,
	product,
	analytics,
	analyticsmids,
	admin,
	user,
	file,
	strikes,
	pixel,
	modal,
	redirect,
	shaker,
	sberout,
	statistics,
	currency,
	language,
	gateway,
	sberalert,
	sberBill,
	company,
	chargebacks,
	errors,
	banks,
	promo
})

import React from 'react'
import './Button.scss'

const Button = ({ type = 'button', onClick, disabled, label }) => (
	<button className='button' type={type} onClick={onClick} disabled={disabled}>
		{label}
	</button>
)

export default Button

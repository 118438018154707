import { createSlice } from '@reduxjs/toolkit'
import { request } from 'api'

const initialState = {
	isLoad: false,
	promoList: [],
	promoPage: null
}

const promoSlice = createSlice({
	name: 'promo',
	initialState,
	reducers: {
		setIsLoad(state, action) {
			state.isLoad = action.payload
		},
		setPromoList(state, action) {
			state.promoList = action.payload.items.filter(page => !page?.deleted_at)
			state.isLoad = false
		},
		setPromoPage(state, action) {
			state.promoPage = action.payload
			state.isLoad = false
		}
	}
})

const {
	reducer: promo,
	actions: { setIsLoad, setPromoList, setPromoPage }
} = promoSlice

export const promoGetAll = () => async dispatch => {
	dispatch(setIsLoad(true))
	return await request
		.get('/admin/promopages')
		.then(res => dispatch(setPromoList(res.data)))
		.catch(() => dispatch(setIsLoad(false)))
		.finally(() => setIsLoad(false))
}

export const getPromoPage = id => async dispatch => {
	dispatch(setIsLoad(true))
	return await request
		.get(`/admin/promopages/${id}`)
		.then(res => dispatch(setPromoPage(res.data)))
		.catch(() => dispatch(setIsLoad(false)))
		.finally(() => setIsLoad(false))
}

export const createPromoCards = async data => {
	return await request.post(`/admin/promopages/description`, data)
}

export const createPromoPage = data => async dispatch => {
	return await request.post(`/admin/promopages`, data)
}

export const deletePromoPage = id => async dispatch => {
	return await request
		.delete(`/admin/promopages/${id}`)
		.then(() => dispatch(promoGetAll()))
}

export const deletePromoCard = async id => {
	return await request.delete(`/admin/promopages/description/${id}`)
}

export const editPromoCard = async (id, data) => {
	return await request.put(`/admin/promopages/description/${id}`, data)
}

export const editPromoPage = async (id, data) => {
	return await request.put(`/admin/promopages/${id}`, data)
}

export const sendCoverPromo = async (name, type, file) => {
	const formData = new FormData()
	formData.append(type, file)
	const config = {
		headers: {
			'content-type': 'multipart/form-data'
		}
	}
	return await request.post(
		`/admin/promopages/promopage/${name}`,
		formData,
		config
	)
}

export const sendCoverCard = async (name, type, file) => {
	const formData = new FormData()
	formData.append(type, file)
	const config = {
		headers: {
			'content-type': 'multipart/form-data'
		}
	}
	return await request.post(
		`/admin/promopages/promopageDescription/${name}`,
		formData,
		config
	)
}

export { promo, setPromoPage }

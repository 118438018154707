import OrderPage from 'pages/OrderPage/OrderPage'
import OrderCrtPage from 'pages/OrderCrtPage'
import OrderInfoPage from 'pages/OrderInfoPage'

import ErrorsPage from 'pages/ErrorsPage/ErrorsPage'

import { UserPage } from 'pages/UserPage'
import UserEditPage from 'pages/UserEditPage'

import { ProductsPage } from 'pages/ProductPage'
import { ProductCrtPage } from 'pages/ProductCrtPage'

import { DomainPage } from 'pages/DomainPage'
import { DomainCrtPage } from 'pages/DomainCrtPage'

import { AnalyticsPage } from 'pages/AnalyticsPage'
import { AnalyticsMidsPage } from 'pages/AnalyticsMidsPage'

import ShakerPage from 'pages/ShakerPage/ShakerPage'
import SberAlertPage from 'pages/SberAlertPage/SberAlertPage'

import CompanyPage from 'pages/CompanyPage/CompanyPage'
import CompanyCrtPage from 'pages/CompanyCrtPage'

import FilePage from 'pages/FilePage'
import { FileExactProductPage } from 'pages/FileExactProductPage'
import { FileCrtPage } from 'pages/FileCrtPage'
import { FileEditPage } from 'pages/FileEditPage'

import { AdminPage } from 'pages/AdminPage'
import { AdminCrtPage } from 'pages/AdminCrtPage'

import { PixelCrtPage } from 'pages/PixelCrtPage'
import { PixelEditPage } from 'pages/PixelEditPage'

import { StrikesCrtPage } from 'pages/StrikesCrtPage'
import { StrikesEditPage } from 'pages/StrikesEditPage'

import MailPage from 'pages/MailPage'

import Settings2FaPage from 'pages/Settings2FaPage'

import DashboardPage from 'pages/DashboardPage'

import { ProductEditPage } from 'pages/ProductEditPage'

import { GatewayPage } from 'pages/GatewayPage'
import GatewayCrtPage from 'pages/GatewayCrtPage'
import GatewayTransferPage from 'pages/GatewayTransferPage'

import SberOutPage from 'pages/SberOutPage/SberOutPage'
import StatisticsTabPage from 'pages/StatisticsTabPage/StatisticsTabPage'

import SberBillPage from 'pages/SberBillPage/SberBillPage'

import { RoleCrtPage } from 'pages/RoleCrtPage'

import PermissionDeniedPage from 'pages/PermissionDeniedPage'
import ChargeBackPage from 'pages/ChargeBackPage/ChargeBackPage'

import { BanksPage } from 'pages/BanksPage'

import { PromoCrtPage } from 'pages/PromoCrtPage'
import { PromoPage } from 'pages/PromoPage'

export const routes = permissions => {
	return [
		{
			path: ['/', '/auth', '/dashboard'],
			exact: true,
			component: permissions.admin.includes('view_admins')
				? DashboardPage
				: PermissionDeniedPage
		},
		{
			path: '/orders/:tabName',
			exact: true,
			component: permissions.order.includes('view_orders')
				? OrderPage
				: PermissionDeniedPage
		},
		{
			path: '/order/:id',
			exact: true,
			component: permissions.order.includes('view_orders')
				? OrderInfoPage
				: PermissionDeniedPage
		},
		{
			path: '/order/add',
			exact: true,
			component: permissions.order.includes('edit_orders')
				? OrderCrtPage
				: PermissionDeniedPage
		},
		{
			path: '/errors/:tabName',
			exact: true,
			component: permissions.order.includes('view_orders')
				? ErrorsPage
				: PermissionDeniedPage
		},
		{
			path: '/user',
			exact: true,
			component: permissions.user.includes('view_users')
				? UserPage
				: PermissionDeniedPage
		},
		{
			path: '/user/:id',
			exact: true,
			component: permissions.user.includes('edit_users')
				? UserEditPage
				: PermissionDeniedPage
		},
		{
			path: '/product',
			exact: true,
			component: permissions.product.includes('view_products')
				? ProductsPage
				: PermissionDeniedPage
		},
		{
			path: '/promo-pages',
			exact: true,
			component: permissions.product.includes('view_products')
				? PromoPage
				: PermissionDeniedPage
		},
		{
			path: '/promo-pages/page/:promoId',
			exact: true,
			component: permissions.product.includes('view_products')
				? PromoCrtPage
				: PermissionDeniedPage
		},
		{
			path: '/promo-pages/create',
			exact: true,
			component: permissions.product.includes('view_products')
				? PromoCrtPage
				: PermissionDeniedPage
		},
		{
			path: '/pixel/:productId/add',
			exact: true,
			component: permissions.product.includes('edit_products')
				? PixelCrtPage
				: PermissionDeniedPage
		},
		{
			path: '/pixel/edit/:id',
			exact: true,
			component: permissions.product.includes('edit_products')
				? PixelEditPage
				: PermissionDeniedPage
		},
		{
			path: '/strikes/:gatewayId/add',
			exact: true,
			component: permissions.gateway.includes('edit_gateways')
				? StrikesCrtPage
				: PermissionDeniedPage
		},
		{
			path: '/strikes/edit/:id',
			exact: true,
			component: permissions.gateway.includes('edit_gateways')
				? StrikesEditPage
				: PermissionDeniedPage
		},
		{
			path: '/product/add',
			exact: true,
			component: permissions.product.includes('edit_products')
				? ProductCrtPage
				: PermissionDeniedPage
		},
		{
			path: '/product/edit/:id',
			exact: true,
			component: permissions.product.includes('edit_products')
				? ProductEditPage
				: PermissionDeniedPage
		},
		{
			path: '/domain/add',
			exact: true,
			component: permissions.domain.includes('edit_domains')
				? DomainCrtPage
				: PermissionDeniedPage
		},
		{
			path: '/domain/edit/:id',
			exact: true,
			component: permissions.domain.includes('edit_domains')
				? DomainCrtPage
				: PermissionDeniedPage
		},
		{
			path: '/domain',
			exact: true,
			component: permissions.domain.includes('view_domains')
				? DomainPage
				: PermissionDeniedPage
		},
		{
			path: '/domain/sberbill',
			exact: true,
			component: permissions.domain.includes('edit_domains')
				? SberBillPage
				: PermissionDeniedPage
		},
		{
			path: '/chargebacks/:tabName',
			exact: true,
			component: permissions.payment.includes('refund_payments')
				? ChargeBackPage
				: PermissionDeniedPage
		},
		{
			path: '/stats',
			exact: true,
			component:
				permissions.analytics &&
				permissions.analytics.includes('view_analytics')
					? AnalyticsPage
					: PermissionDeniedPage
		},
		{
			path: '/statsmids',
			exact: true,
			component:
				permissions.gateway && permissions.gateway.includes('view_gateways')
					? AnalyticsMidsPage
					: PermissionDeniedPage
		},
		{
			path: '/shaker/:tabName',
			exact: true,
			component:
				permissions.shaker && permissions.shaker.includes('view_shakers')
					? ShakerPage
					: PermissionDeniedPage
		},
		{
			path: '/sberalert',
			exact: true,
			component:
				permissions.sberalert &&
				permissions.sberalert.includes('view_sberalerts')
					? SberAlertPage
					: PermissionDeniedPage
		},
		{
			path: '/company',
			exact: true,
			component:
				permissions.company && permissions.company.includes('view_companies')
					? CompanyPage
					: PermissionDeniedPage
		},
		{
			path: '/company/add',
			exact: true,
			component:
				permissions.company && permissions.company.includes('edit_companies')
					? CompanyCrtPage
					: PermissionDeniedPage
		},
		{
			path: '/company/edit/:id',
			exact: true,
			component:
				permissions.company && permissions.company.includes('edit_companies')
					? CompanyCrtPage
					: PermissionDeniedPage
		},
		{
			path: '/banks',
			exact: true,
			component:
				permissions.bank_issuer &&
				permissions.bank_issuer.includes('edit_banks')
					? BanksPage
					: PermissionDeniedPage
		},
		{
			path: '/gateway',
			exact: true,
			component: permissions.gateway.includes('view_gateways')
				? GatewayPage
				: PermissionDeniedPage
		},
		{
			path: '/gateway/add',
			exact: true,
			component: permissions.gateway.includes('edit_gateways')
				? GatewayCrtPage
				: PermissionDeniedPage
		},
		{
			path: '/gateway/edit/:id',
			exact: true,
			component: permissions.gateway.includes('edit_gateways')
				? GatewayCrtPage
				: PermissionDeniedPage
		},
		{
			path: '/gateway/transfer',
			exact: true,
			component: permissions.gateway.includes('edit_gateways')
				? GatewayTransferPage
				: PermissionDeniedPage
		},
		{
			path: '/gateway/sberout',
			exact: true,
			component: permissions.gateway.includes('edit_gateways')
				? SberOutPage
				: PermissionDeniedPage
		},
		{
			path: '/gateway/statistics',
			exact: true,
			component: permissions.gateway.includes('edit_gateways')
				? StatisticsTabPage
				: PermissionDeniedPage
		},
		{
			path: '/file',
			exact: true,
			component: permissions.file.includes('view_files')
				? FilePage
				: PermissionDeniedPage
		},
		{
			path: '/file/:productId/add',
			exact: true,
			component: permissions.file.includes('edit_files')
				? FileCrtPage
				: PermissionDeniedPage
		},
		{
			path: '/file/edit/:id',
			exact: true,
			component: permissions.file.includes('edit_files')
				? FileEditPage
				: PermissionDeniedPage
		},
		{
			path: '/file/:productId',
			exact: true,
			component: permissions.file.includes('edit_files')
				? FileExactProductPage
				: PermissionDeniedPage
		},
		{
			path: '/mail',
			exact: true,
			component: MailPage
		},
		{
			path: '/settings-2fa',
			exact: true,
			component: Settings2FaPage
		},
		{
			path: '/admin/add',
			exact: true,
			component: permissions.admin.includes('edit_admins')
				? AdminCrtPage
				: PermissionDeniedPage
		},
		{
			path: '/admin/:tabName',
			exact: true,
			component: permissions.admin.includes('view_admins')
				? AdminPage
				: PermissionDeniedPage
		},
		{
			path: '/admin/role/add',
			exact: true,
			component: permissions.admin.includes('edit_admins')
				? RoleCrtPage
				: PermissionDeniedPage
		},
		{
			path: '/admin/role/edit/:id',
			exact: true,
			component: permissions.admin.includes('edit_admins')
				? RoleCrtPage
				: PermissionDeniedPage
		},
		{
			path: '/admin/edit/:id',
			exact: true,
			component: permissions.admin.includes('edit_admins')
				? AdminCrtPage
				: PermissionDeniedPage
		},
		{
			path: '/:something',
			exact: false,
			component: DashboardPage
		}
	]
}

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Logo from 'assets/img/logo.svg';
import Input from 'assets/components/Input';
import Loader from 'assets/components/Loader';

const AuthPage = ({ authLogin, authCleanErrors, authAutintification, error, isLoad }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const is2faEnabled = useSelector((state) => state.auth.is2faEnabled);
  const errors = useSelector((state) => state.auth.errors);
  const [autintificationCode, setAutintificationCode] = useState('');

  const handleLogin = (e) => {
    e.preventDefault();
    authLogin({ username, password });
  };

  const handleLoginAutintification = (e) => {
    e.preventDefault();
    const _auth_code = Number(autintificationCode);
    authAutintification({ _auth_code });
  }

  return (
    <div className='auth'>
      {isLoad ? <Loader /> : ''}
      <img src={Logo} alt='logo' className='auth__logo' />
      {is2faEnabled ? <div className='auth__inner'>
        <h1 className='auth__title'>Авторизация</h1>
        <form onSubmit={handleLoginAutintification}>
          <Input
            value={autintificationCode}
            onChange={(e) => {
              setAutintificationCode(e.target.value);
            }}
            placeholder='пароль 2FA'
            type='text'
            name='autintificationCode'
            img='lock'
            error={errors || ''}
            inputItemCls='auth__input'
          />
          <button className='button'>войти</button>
        </form>
      </div> :
        <div className='auth__inner'>
          <h1 className='auth__title'>Авторизация</h1>
          <form onSubmit={handleLogin}>
            <Input
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
                if (error) authCleanErrors();
              }}
              error={error || ''}
              placeholder='email'
              type='text'
              name='email'
              img='user'
              inputItemCls='auth__input'
            />
            <Input
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder='пароль'
              type='password'
              name='password'
              img='lock'
              inputItemCls='auth__input'
            />
            <button className='button'>войти</button>
          </form>
        </div>}
    </div>
  );
};

export default AuthPage;

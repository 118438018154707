import { createSlice } from '@reduxjs/toolkit'
import { request } from 'api'
import { format } from 'date-fns'
import { setRedirect } from './redirect'

const initialState = {
	flag: false,
	flagChargeback: false,
	errorMessage: '',
	isLoad: false,
	list: [],
	statusLabels: [],
	statusLabelsOrderInfo: [],
	countriesList: [],
	payCountriesList: [],
	filters: {
		status: '',
		email: '',
		domain: '',
		dateFrom: format(new Date(), 'yyyy-MM-dd'),
		dateTo: format(new Date(), 'yyyy-MM-dd'),
		datePaymentFrom: '',
		datePaymentTo: '',
		id: '',
		number: '',
		firstSix: '',
		lastFour: '',
		webmaster: '',
		country: '',
		payCountry: '',
		externalId: '',
		type: '',
		typeOrder: '',
		typePayment: '',
		paymentGatewayIds: '',
		productId: '',
		companyId: '',
		issuer: '',
		transactionStatus: '',
		issuers: '',
		paymentSystem: '',
		statuses: '',
		companyIds: '',
		amount: '',
		userId: ''
	},
	pagination: {
		limit: '50',
		page: 1,
		pages: 1,
		total: 0
	},
	selectedOrder: {},
	selectedOrderRedirect: {},
	isLoadRedirect: false,
	isSuccessUnsubscribe: false,
	bankList: [],
	paymentErrorList: [],
	getReport: false,
	paymentStatus: false,
	paymentLoad: false,
	paymentError: ''
}

const orderSlice = createSlice({
	name: 'order',
	initialState,
	reducers: {
		setIsLoad(state, action) {
			state.isLoad = action.payload
		},
		setIsLoadRedirect(state, action) {
			state.isLoadRedirect = action.payload
		},
		setOrderData(state, action) {
			state.list = action.payload.items?.map(el => ({
				...el,
				createdAt: `${el.createdAt
					.slice(0, 10)
					.split('-')
					.reverse()
					.join('.')} ${el.createdAt.slice(-8)}`
			}))
			state.pagination.page = action.payload.pagination?.page
			state.pagination.pages = action.payload.pagination?.pages
			state.pagination.total = action.payload.pagination?.total
			state.isLoad = false
		},
		setOrderFilter(state, action) {
			state.filters.status = action.payload.status
			state.filters.email = action.payload.email
			state.filters.domain = action.payload.domain
			state.filters.dateFrom = action.payload.dateFrom
			state.filters.dateTo = action.payload.dateTo
			state.filters.datePaymentFrom = action.payload.datePaymentFrom
			state.filters.datePaymentTo = action.payload.datePaymentTo
			state.filters.id = action.payload.id
			state.filters.number = action.payload.number
			state.filters.firstSix = action.payload.firstSix
			state.filters.lastFour = action.payload.lastFour
			state.filters.webmaster = action.payload.webmaster
			state.filters.country = action.payload.country
			state.filters.payCountry = action.payload.payCountry
			state.filters.utmCampaign = action.payload.utmCampaign
			state.filters.externalId = action.payload.externalId
			state.filters.type = action.payload.type
			state.filters.typeOrder = action.payload.typeOrder
			state.filters.typePayment = action.payload.typePayment
			state.filters.paymentGatewayIds = action.payload.paymentGatewayIds
			state.filters.companyId = action.payload.companyId
			state.filters.productId = action.payload.productId
			state.filters.issuer = action.payload.issuer
			state.filters.transactionStatus = action.payload.transactionStatus
			state.filters.errors = action.payload.errors
			state.filters.issuers = action.payload.issuers
			state.filters.paymentSystem = action.payload.paymentSystem
			state.filters.statuses = action.payload.statuses
			state.filters.companyIds = action.payload.companyIds
			state.filters.amount = action.payload.amount
			state.filters.userId = action.payload.userId
			state.pagination.limit = action.payload.limit
			state.pagination.page = 1
		},
		setOrderOneData(state, action) {
			state.selectedOrder = {
				...action.payload,
				liteCreatedAt:
					action.payload?.liteCreatedAt &&
					`${action.payload.liteCreatedAt
						.slice(0, 10)
						.split('-')
						.reverse()
						.join('.')} ${action.payload.liteCreatedAt.slice(-8)}`,
				liteDeactivatedAt:
					action.payload?.liteDeactivatedAt &&
					`${action.payload.liteDeactivatedAt
						.slice(0, 10)
						.split('-')
						.reverse()
						.join('.')} ${action.payload.liteDeactivatedAt.slice(-8)}`,
				createdAt:
					action.payload.createdAt &&
					`${action.payload.createdAt
						.slice(0, 10)
						.split('-')
						.reverse()
						.join('.')} ${action.payload.createdAt.slice(-8)}`,
				deactivatedAt:
					action.payload.deactivatedAt &&
					`${action.payload.deactivatedAt
						.slice(0, 10)
						.split('-')
						.reverse()
						.join('.')} ${action.payload.deactivatedAt.slice(-8)}`,
				expiresAt:
					action.payload.expiresAt &&
					`${action.payload.expiresAt
						.slice(0, 10)
						.split('-')
						.reverse()
						.join('.')} ${action.payload.expiresAt.slice(-8)}`,
				expiresAtUnformatted: action.payload.expiresAt,
				nextPaymentAt:
					action.payload.nextPaymentAt &&
					`${action.payload.nextPaymentAt
						.slice(0, 10)
						.split('-')
						.reverse()
						.join('.')} ${action.payload.nextPaymentAt.slice(-8)}`
			}
			state.isLoad = false
		},
		setStatusLabelsOrderInfo(state, action) {
			state.statusLabelsOrderInfo = action.payload
		},
		setSelectedOrderOneData(state, action) {
			state.isLoadRedirect = false
			state.selectedOrderRedirect = action.payload
		},
		setOrderUnsubscribeOneSuccess(state) {
			state.isLoad = false
			state.isSuccessUnsubscribe = true
		},
		setOrderUnsubscribeOneFailure(state) {
			state.isLoad = false
			state.isSuccessUnsubscribe = false
		},
		setOrderUnsubscribeOneIsSuccess(state) {
			state.isSuccessUnsubscribe = false
		},
		setOrderInfoUpdateRequest(state) {
			state.isLoad = true
			state.flag = false
		},
		setOrderInfoUpdateSuccess(state) {
			state.isLoad = false
			state.flag = false
		},
		setOrderInfoUpdateFailure(state, action) {
			state.isLoad = false
			state.flag = true
			state.errorMessage = action.payload
		},
		setOrderChargeBackRequest(state) {
			state.isLoad = true
			state.flagChargeback = false
		},
		setOrderChargeBackSuccess(state) {
			state.isLoad = false
			state.flagChargeback = false
		},
		setOrderChargeBackFailure(state, action) {
			state.isLoad = false
			state.flagChargeback = true
			state.errorMessage = action.payload
		},
		setOrderReportGetAllSuccess(state) {
			state.isLoad = false
			state.getReport = false
		},
		setOrderReportGetAllFailure(state) {
			state.isLoad = false
			state.getReport = false
		},
		setBankListSuccess(state, action) {
			state.isLoad = false
			state.bankList = action.payload
		},
		setBankListFailure(state, action) {
			state.isLoad = false
			state.errorMessage = action.payload
		},
		setPaymentErrorListSuccess(state, action) {
			state.isLoad = false
			state.paymentErrorList = action.payload
		},
		setPaymentErrorListFailure(state, action) {
			state.isLoad = false
			state.errorMessage = action.payload
		},
		setOrderClearSelected(state) {
			state.selectedOrder = {}
		},
		setOrderPage(state, action) {
			state.pagination.page = action.payload
		},
		setOrderGetReport(state) {
			state.getReport = true
		},
		setStatusLabels(state, action) {
			state.statusLabels = action.payload
		},
		setPaymentStatus(state, action) {
			state.paymentStatus = action.payload
		},
		setIsLoadPayment(state, action) {
			state.paymentLoad = action.payload
		},
		setPaymentError(state, action) {
			state.paymentError = action.payload
		},
		setCountries(state, action) {
			state.countriesList = action.payload
		},
		setPayCountries(state, action) {
			state.payCountriesList = action.payload
		}
	}
})

const {
	reducer: order,
	actions: {
		setIsLoad,
		setIsLoadRedirect,
		setSelectedOrderOneData,
		setStatusLabelsOrderInfo,
		setOrderData,
		setOrderFilter,
		setOrderOneData,
		setOrderUnsubscribeOneSuccess,
		setOrderUnsubscribeOneFailure,
		setOrderUnsubscribeOneIsSuccess,
		setOrderInfoUpdateRequest,
		setOrderInfoUpdateSuccess,
		setOrderInfoUpdateFailure,
		setOrderChargeBackRequest,
		setOrderChargeBackSuccess,
		setOrderChargeBackFailure,
		setOrderReportGetAllSuccess,
		setOrderReportGetAllFailure,
		setBankListSuccess,
		setBankListFailure,
		setPaymentErrorListSuccess,
		setPaymentErrorListFailure,
		setOrderClearSelected,
		setStatusLabels,
		setOrderGetReport,
		setOrderPage,
		setPaymentStatus,
		setIsLoadPayment,
		setPaymentError,
		setCountries,
		setPayCountries
	}
} = orderSlice

export const orderGetAll = params => async dispatch => {
	dispatch(setIsLoad(true))
	return request
		.get('/admin/orders', { params: { ...params } })
		.then(res => dispatch(setOrderData(res.data)))
		.catch(() => dispatch(setIsLoad(false)))
}

export const getStatusLabelsOrderInfo = () => dispatch => {
	return request
		.get('/admin/payments/statuses')
		.then(res => dispatch(setStatusLabelsOrderInfo(res.data)))
}

export const orderGetOne = id => dispatch => {
	dispatch(setIsLoad(true))
	return request
		.get(`/admin/orders/${id}`)
		.then(resp => {
			dispatch(setOrderOneData(resp.data))
			dispatch(getStatusLabelsOrderInfo())
		})
		.catch(() => {
			dispatch(setIsLoad(false))
			dispatch(setRedirect('/orders/all'))
		})
}

export const orderRedirectGetOne = id => dispatch => {
	dispatch(setIsLoadRedirect(true))
	return request
		.get(`/admin/orders/${id}`)
		.then(resp => {
			dispatch(setSelectedOrderOneData(resp.data))
			request
				.get(resp.data.user.authUrl.slice(0, -1))
				.then(() =>
					window.open(`https://client.${resp.data.clientDomain}`, '_blank')
				)
		})
		.catch(() => {
			dispatch(setIsLoadRedirect(false))
			dispatch(setRedirect('/orders/all'))
		})
}

export const orderRedirectGetReport = id => dispatch => {
	return request
		.get(`/admin/orders/${id}`)
		.then(resp => window.open(`${resp.request.responseURL}/report`, '_blank'))
		.catch(() => {
			dispatch(setRedirect('/orders/all'))
		})
}

export const orderGetReportPDF = id => dispatch => {
	dispatch(setIsLoad(true))
	return request
		.get(`/admin/orders/${id}/report?returnPdf=1`, {
			responseType: 'arraybuffer'
		})
		.then(request => {
			const file = new Blob([request.data], { type: 'application/pdf' })
			const fileURL = URL.createObjectURL(file)
			const a = document.createElement('a')
			a.href = fileURL
			a.download = 'file.pdf'
			document.body.appendChild(a)
			a.click()
			a.remove()

			URL.revokeObjectURL(fileURL)
			dispatch(setIsLoad(false))
		})
		.catch(() => {
			dispatch(setIsLoad(false))
			alert('Произошла ошибка при скачивании ;(')
		})
}

export const orderUnsubscribeOne = id => dispatch => {
	dispatch(setIsLoad(true))
	return request
		.get(`admin/orders/${id}/unsubscribe`)
		.then(() => {
			dispatch(setOrderUnsubscribeOneSuccess())
		})
		.catch(() => {
			dispatch(setOrderUnsubscribeOneFailure())
		})
}

export const orderInfoUpdateStatus = (id, orderId) => async dispatch => {
	dispatch(setOrderInfoUpdateRequest())
	request
		.post(`/admin/payments/refund`, { id })
		.then(() => {
			dispatch(setOrderInfoUpdateSuccess())
			dispatch(orderGetOne(orderId))
		})
		.catch(error => {
			dispatch(setOrderInfoUpdateFailure(error.response.data.error))
		})
}

export const orderInfoChargebackStatus =
	(id, type, orderId) => async dispatch => {
		dispatch(setOrderChargeBackRequest())
		request
			.post(`/admin/payments/chargeback`, { id, type })
			.then(() => {
				dispatch(setOrderChargeBackSuccess())
				dispatch(orderGetOne(orderId))
			})
			.catch(error => {
				dispatch(setOrderChargeBackFailure(error.response.data.error))
			})
	}

export const orderReportGetAll = params => async dispatch => {
	dispatch(setIsLoad(true))
	return request
		.get('admin/orders/report', { params })
		.then(res => {
			const file = new Blob([res.data], { type: 'text/csv;charset=utf-8' })
			const fileURL = URL.createObjectURL(file)
			const a = document.createElement('a')
			a.href = fileURL
			a.download = 'statistics.csv'
			document.body.appendChild(a)
			a.click()
			a.remove()
			dispatch(setOrderReportGetAllSuccess())
			URL.revokeObjectURL(fileURL)
		})
		.catch(() => dispatch(setOrderReportGetAllFailure()))
}

export const getBankListStatus = () => async dispatch => {
	dispatch(setIsLoad(true))
	request
		.get(`/admin/banks`)
		.then(res => dispatch(setBankListSuccess(res.data)))
		.catch(error => dispatch(setBankListFailure(error.response.data.error)))
}

export const getPaymentErrorListStatus = () => async dispatch => {
	dispatch(setIsLoad(true))
	request
		.get(`/admin/orders/paymentErrors`)
		.then(res => dispatch(setPaymentErrorListSuccess(res.data)))
		.catch(error =>
			dispatch(setPaymentErrorListFailure(error.response.data.error))
		)
}

export const getStatusLabels = () => dispatch => {
	return request
		.get('/admin/orders/statuses')
		.then(res => dispatch(setStatusLabels(res.data)))
}

export const startPayment = payload => dispatch => {
	dispatch(setIsLoadPayment(true))
	return request
		.post('/admin/payments/create', payload)
		.then(() => {
			dispatch(setPaymentStatus(false))
			dispatch(setIsLoadPayment(false))
			dispatch(setPaymentError(''))
			payload.orderId && dispatch(orderGetOne(payload.orderId))
		})
		.catch(err => {
			dispatch(setIsLoadPayment(false))
			dispatch(setPaymentError(err.response?.data?.error?.message))
		})
}

export const startPayments = payload => dispatch => {
	dispatch(setIsLoadPayment(true))
	return request
		.post('/admin/payments/create', payload)
		.then(() => {
			dispatch(setIsLoadPayment(false))
			dispatch(setPaymentError(''))
		})
		.catch(err => {
			dispatch(setIsLoadPayment(false))
			dispatch(setPaymentError(err.response?.data?.error?.message))
		})
}

export const getCountries = () => dispatch => {
	return request
		.get('/admin/orders/listCountry')
		.then(res => dispatch(setCountries(res.data)))
		.catch(console.trace)
}

export const getPayCountries = () => dispatch => {
	return request
		.get('/admin/orders/payCountry')
		.then(res => dispatch(setPayCountries(res.data)))
		.catch(console.trace)
}

export {
	order,
	setOrderFilter,
	setOrderUnsubscribeOneIsSuccess,
	setOrderPage,
	setOrderClearSelected,
	setOrderGetReport,
	setPaymentStatus,
	setPaymentError,
	setCountries,
	setPayCountries
}

import React, { useEffect, useState } from 'react'
import { fileCleanErrors, fileCleanRedirect, fileCreate } from 'actions/file'
import {
	productGetOne,
	productGetAll,
	setProductCleanErrors
} from 'store/slices/product'

import Input from 'assets/components/Input'
import { FileInput } from 'assets/components/FileInput'
import ButtonLink from 'assets/components/ButtonLink'
import { useHistory } from 'react-router-dom'
import DropDown from '../../assets/components/DropDown'
import Loader from '../../assets/components/Loader'
import { useAppSelector, useAppDispatch } from 'store'
import { useParams } from 'react-router-dom'

const FileCrtPage = () => {
	const { productId } = useParams()
	const dispatch = useAppDispatch()
	const errors = useAppSelector(state => state.file.errors)
	const productName = useAppSelector(state => state.product.curProduct.name)
	const productError = useAppSelector(state => state.product.errors?.product)
	const product = useAppSelector(state => state.product.list)?.map(
		({ name, id }) => ({ name, id, check: false })
	)
	const redirect = useAppSelector(state => state.file.redirect)
	const isLoad = useAppSelector(state => state.file.isLoad)

	const [path, setPath] = useState('')
	const [name, setName] = useState('')
	const [number, setNumber] = useState('')

	const [products, setProducts] = useState([])
	const [fileBackgroundFile, setFileBackgroundFile] = useState('')
	const [previewBackgroundURL, setPreviewBackgroundURL] = useState('')
	const [fileCoverFile, setFileCoverFile] = useState('')
	const [previewCoverURL, setPreviewCoverURL] = useState('')

	const history = useHistory()

	useEffect(() => {
		if (productError) {
			dispatch(setProductCleanErrors('product'))
			history.push(`/file`)
		}

		if (redirect) {
			dispatch(fileCleanRedirect())
			history.goBack()
		}
	}, [redirect, productError])

	useEffect(() => {
		if (productId !== 'all') {
			dispatch(productGetOne(productId))
		} else {
			dispatch(productGetAll())
		}
	}, [productId])

	useEffect(() => {
		if (product.length) setProducts(product)
	}, [product.length])

	return (
		<>
			<div className='create'>
				{isLoad ? <Loader /> : ''}
				<h2 className='create__title'>Создание файла</h2>
				<Input
					value={name}
					onChange={e => {
						setName(e.target.value)
						if (errors.name) dispatch(fileCleanErrors('name'))
					}}
					error={errors.name || ''}
					placeholder='Название папки'
					type='text'
					name='name'
					inputCls='create__input'
					label='Название папки'
				/>
				<Input
					value={path}
					onChange={e => {
						setPath(e.target.value)
						if (errors.path) dispatch(fileCleanErrors('path'))
					}}
					error={errors.path || ''}
					placeholder='Путь к папке'
					type='text'
					name='path'
					inputCls='create__input'
					label='Путь к папке'
				/>
				<Input
					value={number}
					onChange={e => {
						setNumber(e.target.value)
						if (errors.number) dispatch(fileCleanErrors('number'))
					}}
					error={errors.number || ''}
					placeholder='Последовательность'
					type='text'
					name='name'
					inputCls='create__input'
					label='Последовательность'
				/>

				{productId === 'all' ? (
					<DropDown
						text='Прикрепить к продукту'
						arrayOfValues={products}
						onChange={setProducts}
						radio={true}
					/>
				) : (
					<p className='create__info'>
						Этот файл будет прикреплен к продукту {productName} (ID: {productId}
						)
					</p>
				)}
				<FileInput
					label='Загрузки файла фона'
					name='create-file-background'
					previewURL={previewBackgroundURL ? previewBackgroundURL : ''}
					onChange={e => {
						let reader = new FileReader()
						let file = e.target.files[0]
						reader.onloadend = () => {
							setFileBackgroundFile(file)
							setPreviewBackgroundURL(reader.result)
						}
						reader.readAsDataURL(file)
					}}
					inputCls='create-file-input'
				/>

				<FileInput
					label='Загрузки файла обложки'
					name='create-file-cover'
					previewURL={previewCoverURL ? previewCoverURL : ''}
					onChange={e => {
						let reader = new FileReader()
						let file = e.target.files[0]
						reader.onloadend = () => {
							setFileCoverFile(file)
							setPreviewCoverURL(reader.result)
						}
						reader.readAsDataURL(file)
					}}
					inputCls='create-file-input'
				/>

				{productId === 'all' ? (
					<button
						className='button'
						onClick={() =>
							dispatch(
								fileCreate({
									path,
									name,
									number,
									productId: products.find(({ check }) => check)?.id
								})
							)
						}
					>
						Сохранить
					</button>
				) : (
					<button
						className='button'
						onClick={() =>
							dispatch(
								fileCreate({
									path,
									name,
									number,
									productId,
									background: fileBackgroundFile,
									cover: fileCoverFile
								})
							)
						}
					>
						Сохранить
					</button>
				)}
				<ButtonLink
					text='Назад к списку'
					btnClass='create__prev'
					onClick={() => history.goBack()}
				/>
			</div>
		</>
	)
}

export default FileCrtPage

import * as React from 'react'
import './FieldLabel.scss'

export const FieldLabel = ({ label, children }) => {
	return (
		<label className='fieldLabel'>
			<span>{label}</span>
			{children}
		</label>
	)
}

import React, { useState } from 'react'
import cn from 'classnames'
import { landFooterOptions } from 'constants/dashboardOptions/dashboardOptions'
import { ReactComponent as Bin } from 'assets/img/bin.svg'
import { ReactComponent as Pen } from 'assets/img/pen.svg'
import { ReactComponent as Copy } from 'assets/img/copy.svg'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Input from 'assets/components/Input'
import { FormattedNumber } from 'react-intl'
import Select from 'react-select'
import { ReactComponent as RefundIcon } from 'assets/img/refund.svg'
import ReactTooltip from 'react-tooltip'
import { useAppDispatch } from 'store'
import { setSortDomainBill } from 'store/slices/domain'
import {
	setSortErrorListCount,
	setSortErrorListDate,
	setSortChainErrorListDate,
	setSortChainErrorListDateAndTime
} from 'store/slices/errors'
import { ReactComponent as Sort } from 'assets/img/sort.svg'

const selectStylesDomainEditPage = {
	container: provided => ({
		...provided,
		display: 'inline-block',
		width: '100%',
		height: '80px',
		minHeight: '1px',
		textAlign: 'left',
		border: 'none'
	}),
	control: provided => ({
		...provided,
		height: '80px'
	}),
	valueContainer: provided => ({
		...provided,
		height: '80px'
	})
}

const sections = [
	{
		section: 'admin',
		name: 'админ',
		viewName: 'view_admins',
		editName: 'edit_admins'
	},
	{
		section: 'domain',
		name: 'домены',
		viewName: 'view_domains',
		editName: 'edit_domains'
	},
	{
		section: 'file',
		name: 'файлы',
		viewName: 'view_files',
		editName: 'edit_files'
	},
	{
		section: 'gateway',
		name: 'шлюзы',
		viewName: 'view_gateways',
		editName: 'edit_gateways',
		moveName: 'move_gateways'
	},
	{ section: 'order', name: 'заказы', viewName: 'view_orders', editName: '' },
	{
		section: 'product',
		name: 'продукты',
		viewName: 'view_products',
		editName: 'edit_products'
	},
	{ section: 'stats', name: 'dashboard', viewName: 'view_stats', editName: '' },
	{
		section: 'user',
		name: 'клиенты',
		viewName: 'view_users',
		editName: 'edit_users'
	},
	{
		section: 'analytics',
		name: 'аналитика',
		viewName: 'view_analytics',
		editName: ''
	},
	{
		section: 'tags',
		name: 'теги',
		viewName: 'view_tags',
		editName: 'edit_tags'
	},
	{
		section: 'payment',
		name: 'платежи',
		refundName: 'refund_payments',
		chargebackName: 'chargeback_payments',
		createName: 'create_payments',
		editName: ''
	},
	{
		section: 'shaker',
		name: 'шейкер',
		viewName: 'view_shakers',
		editName: 'edit_shakers'
	},
	{
		section: 'sberalert',
		name: 'сбералерт',
		viewName: 'view_sberalerts',
		editName: 'edit_sberalerts'
	},
	{
		section: 'company',
		name: 'юрлица',
		viewName: 'view_companies',
		editName: 'edit_companies'
	},
	{
		section: 'bank_issuer',
		name: 'банки',
		viewName: 'view_banks',
		editName: 'edit_banks'
	},
	{
		section: 'admin_logs',
		name: 'логи',
		viewName: 'view_admin_logs',
		editName: ''
	}
]

const stopRebillOption = [
	{ label: 'Ребиллы включены', value: 0 },
	{ label: 'Все выключены', value: 1 },
	{ label: 'Все по Сберу выключены', value: 2 },
	{ label: 'Все по ТКС выключены', value: 10 },
	{ label: 'Все по Сберу и ТКС выключены', value: 11 },
	{ label: 'Выключены все кроме Сбера', value: 3 },
	{ label: 'Первые выключены', value: 4 },
	{ label: 'Первые по Сберу выключены', value: 5 },
	{ label: 'Первые кроме Сбера выключены', value: 6 },
	{ label: 'Повторные выключены', value: 7 },
	{ label: 'Повторные по Сберу выключены', value: 8 },
	{ label: 'Повторные кроме Сбера выключены', value: 9 },
	{ label: 'По дефолту', value: '' }
]

const paymentsType = t => {
	switch (t) {
		case 0:
			return 'SETUP'
		case 1:
			return 'ADDITIONAL'
		case 2:
			return 'RECURRING'
		case 3:
			return 'RENEWAL'
		case 4:
			return 'RECURRING_ADDITIONAL'
		case 5:
			return 'REFUND'
		case 6:
			return 'CHARGEBACK'
		case 7:
			return 'ARBITRATION'
		case 10:
			return 'lite'
		case 12:
			return 'PREPAID'
		case 13:
			return 'PREPAID_UNSUBSCRIBE'
		default:
			return t
	}
}

export const TitlesMapping = ({ titles = [] }) => {
	const dispatch = useAppDispatch()

	const chooseFunction = str => {
		switch (str) {
			case 'domainBill':
				return () => dispatch(setSortDomainBill())
			case 'titlesErrorsTopDate':
				return () => dispatch(setSortErrorListDate())
			case 'titlesErrorsTopCountError':
				return () => dispatch(setSortErrorListCount())
			case 'titlesErrorsChainsDate':
				return () => dispatch(setSortChainErrorListDate())
			case 'titlesErrorsChainsDateAndTime':
				return () => dispatch(setSortChainErrorListDateAndTime())
			default:
				return null
		}
	}

	return (
		<div id='myHeader' className='table__header'>
			<div className='tableRow'>
				{titles.map(({ mod, name, onClick, title }, ind) => (
					<div
						onClick={onClick ? chooseFunction(title) : null}
						key={ind}
						className={cn(
							'tableRow__item',
							'tableRow__item--header',
							mod
								? 'tableRow__item--' + mod
								: onClick
								? 'tableRow__item--sort'
								: ''
						)}
					>
						{name}
						{onClick && (
							<div className='logoSort'>
								<Sort />
							</div>
						)}
					</div>
				))}
			</div>
		</div>
	)
}
// 'tableRow__item--big'
export const ContentMapping = ({
	content = [],
	keyProp,
	btnDelete = () => {},
	productId = 0,
	gatewaysId = 0,
	handleChange = () => {},
	btnEdit = () => {},
	btnCopy = () => {},
	handleEditButton,
	handleEditRefundOrder,
	handleEditChargebackOrder,
	handleUpdateShakerButton,
	downloadStatistics,
	dateFrom,
	dateTo,
	handleUpdateSberAlertButton,
	optionsCompany,
	handleClickCheck,
	isCheck,
	isCheckAll,
	handleClickCheckSelectAll,
	handleClickStartEdit,
	productsObject,
	companiesObject,
	gatewaysObject,
	formatOptionLabel,
	getPaymentSummary
}) => {
	const { role } = useSelector(state => state.auth)
	const { statusLabelsOrderInfo } = useSelector(state => state.order)
	const rates = useSelector(state => state.gateway.rates)
	const [toggle, setToggle] = useState(0)
	const [toggleSecond, setToggleSecond] = useState(0)
	const availableEdit = useSelector(
		state => state.auth.role.permissions.payment
	)?.includes('create_payments')
	switch (keyProp) {
		case 'domain':
			return (
				<>
					<div className='tableRow tableRowCheck'>
						<input
							type='checkbox'
							name='selectAll'
							onChange={handleClickCheckSelectAll}
							checked={isCheckAll}
						/>
						<div className={cn('tableRow__item', 'tableRow__item--sm')}></div>
						<div
							className={cn(
								'tableRow__item',
								'tableRow__item--big',
								'checkAll__button'
							)}
						>
							Выбрать все
							<button disabled={!isCheck.length} onClick={handleClickStartEdit}>
								Редактировать
							</button>
						</div>
						<div className={cn('tableRow__item', 'tableRow__item--big')}></div>
						<div className='tableRow__item'>{`${
							content.length &&
							content
								?.map(item => item.statistics.active_bills)
								?.reduce((sum, current) => sum + current)
						} (Общее количество)`}</div>
						<div className='tableRow__item'></div>
						<div className={cn('tableRow__item', 'tableRow__item--big')}></div>
						<div className='tableRow__item'></div>
						<div className='tableRow__item'></div>
						<div
							className={cn(
								'tableRow__item',
								'tableRow__item--svg',
								'tableRow__item--m'
							)}
						>
							...
						</div>
					</div>
					{content.map(el => (
						<div className='tableRow' key={el.id}>
							<input
								key={el.id}
								type='checkbox'
								name={el.name}
								id={el.id}
								onChange={handleClickCheck}
								checked={isCheck.includes(el.id)}
							/>
							<div className={cn('tableRow__item', 'tableRow__item--sm')}>
								{el.id}
							</div>
							<div className={cn('tableRow__item', 'tableRow__item--big')}>
								{el.link}
							</div>
							<div className={cn('tableRow__item', 'tableRow__item--big')}>
								<ul>
									{el.products.map(item => (
										<li key={item.id}>{item.innerName}</li>
									))}
								</ul>
							</div>
							<div className={cn('tableRow__item', 'tableRow__item--big')}>
								{landFooterOptions?.find(
									o => o.value === el.options?.landFooter
								)?.label || null}
							</div>
							{
								<div className={cn('tableRow__item')}>
									{el.statistics.active_bills}
								</div>
							}
							<div className={cn('tableRow__item')}>
								<span
									className={cn('tableRow__item-btn', {
										'tableRow__item-btn--green': el.status == 1,
										'tableRow__item-btn--red': el.status == 2
									})}
								>
									{el.status == 1 ? 'Активный' : 'Неактивный'}
								</span>
							</div>
							<div className={cn('tableRow__item', 'tableRow__item--big')}>
								{el.paymentSettings &&
									el.paymentSettings.map((payment, idx) => (
										<div title={`noRef: ${payment.noRef}`} key={idx.toString()}>
											<span className={payment.noRef ? 'domain__noRef' : ''}>
												{payment.paymentSystem} : {payment.paymentGateway}{' '}
												{`(${payment.gatewayId})`}
											</span>
										</div>
									))}
								{!el.paymentSettings && ''}
							</div>
							<div className={cn('tableRow__item')}>{el.folder}</div>
							<div className='tableRow__item'>{el.createdAt}</div>
							<div
								className={cn(
									'tableRow__item',
									'tableRow__item--svg',
									'tableRow__item--m'
								)}
							>
								{role.permissions.domain.includes('edit_domains') && (
									<>
										<Link
											to={`/domain/edit/${el.id}`}
											className='tableRow__svg-pen'
										>
											<Pen />
										</Link>
										<span
											onClick={() =>
												btnDelete('Вы действительно хотите удалить домен?', {
													id: el.id
												})
											}
											className='tableRow__svg-bin'
										>
											<Bin />
										</span>
									</>
								)}
							</div>
						</div>
					))}
				</>
			)
		case 'banks':
			return content?.map((el, index) => (
				<div className='tableRow' key={index}>
					<div className={cn('tableRow__item', 'tableRow__item--big')}>
						{el.card_first_six}
					</div>
					<div className={cn('tableRow__item', 'tableRow__item--big')}>
						{el.cnt}
					</div>
					<div className={cn('tableRow__item')}>{el.issuer}</div>
					<div
						className={cn(
							'tableRow__item',
							'tableRow__svg-pen',
							'tableRow__item--svg'
						)}
					>
						<Pen onClick={() => handleClickCheck(el.card_first_six)} />
					</div>
				</div>
			))
		case 'errorsTop':
			return content?.map((el, index) => (
				<div className='tableRow' key={el.index}>
					<div className={cn('tableRow__item')}>
						{el.created_at.slice(0, 10).split('-').reverse().join('.')}
					</div>
					<div
						className={cn('tableRow__item')}
					>{`${el.err_message} (${el.error_code})`}</div>
					<div className={cn('tableRow__item')}>{el.err_count}</div>
					<div className={cn('tableRow__item')}>
						{gatewaysObject[el.gateway_id]?.paymentGatewayTitle}
					</div>
					<div className={cn('tableRow__item')}>{el.domain_id}</div>
					<div className={cn('tableRow__item')}>{el.webmaster}</div>
					<div className={cn('tableRow__item', 'tableRow__item--big')}>
						{productsObject[el.product_id]?.name}
					</div>
					<div className={cn('tableRow__item')}>
						{companiesObject[el.company_id]?.name}
					</div>
					<div className={cn('tableRow__item')}>{el.issuer}</div>
				</div>
			))
		case 'errorsChain':
			return (
				<>
					{availableEdit ? (
						<div className='tableRow tableRowCheck'>
							<input
								type='checkbox'
								name='selectAll'
								onChange={handleClickCheckSelectAll}
								checked={isCheckAll}
							/>
							<div className={cn('tableRow__item', 'tableRow__item--sm')}></div>
							<div
								className={cn(
									'tableRow__item',
									'tableRow__item--big',
									'checkAll__button'
								)}
							>
								Выбрать все
								<button
									disabled={!isCheck.length}
									onClick={handleClickStartEdit}
								>
									Массовый платёж
								</button>
							</div>
							<div
								className={cn('tableRow__item', 'tableRow__item--big')}
							></div>
							<div className='tableRow__item'></div>
							<div
								className={cn('tableRow__item', 'tableRow__item--big')}
							></div>
							<div className='tableRow__item'></div>
							<div className='tableRow__item'></div>
							<div
								className={cn(
									'tableRow__item',
									'tableRow__item--svg',
									'tableRow__item--m'
								)}
							>
								...
							</div>
						</div>
					) : null}
					{content?.map(el => (
						<div className='tableRow' key={el.id}>
							<input
								key={el.id}
								type='checkbox'
								name={el.name}
								id={el.id}
								onChange={handleClickCheck}
								checked={isCheck.includes(el.id)}
							/>
							<div className={cn('tableRow__item')}>
								{el.created_at.slice(0, 10).split('-').reverse().join('.')}
							</div>
							<div className={cn('tableRow__item')}>
								<Link to={`/gateway/edit/${el.gateway.id}`} target='_blank'>
									{el.gateway.id}
								</Link>
							</div>
							<div className={cn('tableRow__item')}>
								<Link to={`/order/${el.id}`} target='_blank'>
									{el.id}
								</Link>
							</div>
							<div className={cn('tableRow__item')}>
								<span>{el.issuer}</span>
							</div>
							<div className={cn('tableRow__item')}>
								<span
									className={cn('tableRow__item-btn', {
										'tableRow__item-btn--green': el.status == 'Активный',
										'tableRow__item-btn--red': el.status == 'Неактивный'
									})}
								>
									{el.status}
								</span>
							</div>
							<div className={cn('tableRow__item')}>
								{el.data.map((item, index) => (
									<div key={index}>
										<div>
											{item.op_cr_at
												.slice(0, 10)
												.split('-')
												.reverse()
												.join('.')}{' '}
											{item.op_cr_at.slice(-8)}
										</div>
										{el.data.length > 1 && <br />}
									</div>
								))}
							</div>
							<div className={cn('tableRow__item', 'tableRow__item--big')}>
								{el.data.map((item, index) => (
									<div key={index}>
										<div>
											{item.error_code && item.error_message
												? `${item.amount}  Ответ: ${el.paymentStatus} ${item.error_message} ; Код ошибки: ${item.error_code}`
												: item.error_message
												? `${item.amount}  Ответ: ${el.paymentStatus} ${item.error_message}`
												: `${item.amount} Ответ: ${el.paymentStatus}`}
										</div>
										{el.data.length > 1 && <br />}
									</div>
								))}
							</div>
						</div>
					))}
				</>
			)
		case 'company':
			return content?.map(el => (
				<div className='tableRow' key={el.id}>
					<div className={cn('tableRow__item', 'tableRow__item--sm')}>
						{el.id}
					</div>
					<div className={cn('tableRow__item')}>
						{optionsCompany.type ? optionsCompany?.type[el.type] : null}
					</div>
					<div className={cn('tableRow__item', 'tableRow__item--big')}>
						{el.name}
					</div>
					<div className={cn('tableRow__item')}>
						<span
							className={cn('tableRow__item-btn', {
								'tableRow__item-btn--green': el.status == 1,
								'tableRow__item-btn--red': el.status == 2
							})}
						>
							{el.status == 1 ? 'Активный' : 'Неактивный'}
						</span>
					</div>
					<div className={cn('tableRow__item', 'tableRow__item--big')}>
						{el.inn}
					</div>
					<div className={cn('tableRow__item', 'tableRow__item--big')}>
						{el.ogrn}
					</div>
					<div className={cn('tableRow__item', 'tableRow__item--big')}>
						{el.phone}
					</div>
					<div className='tableRow__item'>
						{optionsCompany.taxation
							? optionsCompany?.taxation[el.taxation]
							: null}
					</div>
					<div className={cn('tableRow__item', 'tableRow__item--big')}>
						{el.address}
					</div>
					<div className={cn('tableRow__item', 'tableRow__item--big')}>
						{el.comment}
					</div>
					<div
						className={cn(
							'tableRow__item',
							'tableRow__item--svg',
							'tableRow__item--m'
						)}
					>
						{role.permissions.company.includes('edit_companies') && (
							<>
								<Link
									to={`/company/edit/${el.id}`}
									className='tableRow__svg-pen'
								>
									<Pen />
								</Link>
								<span
									onClick={() =>
										btnDelete('Вы действительно хотите удалить ЮрЛицо?', {
											id: el.id
										})
									}
									className='tableRow__svg-bin'
								>
									<Bin />
								</span>
							</>
						)}
					</div>
				</div>
			))
		case 'order':
			return (
				<>
					{availableEdit ? (
						<div className='tableRow tableRowCheck'>
							<input
								type='checkbox'
								name='selectAll'
								onChange={handleClickCheckSelectAll}
								checked={isCheckAll}
							/>
							<div className={cn('tableRow__item', 'tableRow__item--sm')}></div>
							<div
								className={cn(
									'tableRow__item',
									'tableRow__item--big',
									'checkAll__button'
								)}
							>
								Выбрать все
								<button
									disabled={!isCheck.length}
									onClick={handleClickStartEdit}
								>
									Массовый платёж
								</button>
							</div>
							<div
								className={cn('tableRow__item', 'tableRow__item--big')}
							></div>
							<div className='tableRow__item'></div>
							<div
								className={cn('tableRow__item', 'tableRow__item--big')}
							></div>
							<div className='tableRow__item'></div>
							<div className='tableRow__item'></div>
							<div
								className={cn(
									'tableRow__item',
									'tableRow__item--svg',
									'tableRow__item--m'
								)}
							>
								...
							</div>
						</div>
					) : null}
					{content.map(el => (
						<div className='tableRow' key={el.id}>
							{availableEdit ? (
								<input
									key={el.id}
									type='checkbox'
									name={el.name}
									id={el.id}
									onChange={handleClickCheck}
									checked={isCheck.includes(el.id)}
								/>
							) : null}
							<div className={cn('tableRow__item', 'tableRow__item--mm')}>
								{el.id}
							</div>
							<div className={cn('tableRow__item')}>
								<Link to={`/gateway/edit/${el.gateway.id}`} target='_blank'>
									{el.gateway.id}
								</Link>
							</div>
							<Link
								to={`/order/${el.id}`}
								target='_blank'
								className={cn('tableRow__item', 'tableRow__item--mm')}
							>
								{el.number}
							</Link>
							<div className={cn('tableRow__item', 'tableRow__item--big')}>
								{el.user.email}
							</div>
							<div className='tableRow__item'>
								{el.status.toLowerCase() === 'продукт' ? (
									<Link
										to={`/product/edit/${el?.productId}`}
										target='_blank'
										className='tableRow__link'
									>
										{el?.productId}
									</Link>
								) : null}
							</div>
							<div className='tableRow__item'>
								<FormattedNumber
									value={el.total}
									style='currency'
									currency={el.currency}
								/>
							</div>
							<div className='tableRow__item'>
								<span
									className={cn('tableRow__item-btn', {
										'tableRow__item-btn--green': el.status === 'Активный',
										'tableRow__item-btn--red': el.status === 'Неактивный'
									})}
								>
									{el.status}
								</span>
							</div>
							<div className='tableRow__item'>{el.domain}</div>
							<div className='tableRow__item'>{el.cpa}</div>
							<div className='tableRow__item'>{JSON.parse(el.webmaster)}</div>
							<div className='tableRow__item'>{el.createdAt}</div>
						</div>
					))}
				</>
			)
		case 'order-refund':
			return content.map(el => (
				<>
					<div className='tableRow' key={el.id}>
						<div className={cn('tableRow__item', 'tableRow__item--mm')}>
							{el.id}
						</div>
						<div className={cn('tableRow__item', 'tableRow__item--medium')}>
							{el.orders[0] && (
								<>
									<Link
										to={`/order/${el.orders[0]}`}
										target='_blank'
										className='tableRow__link'
										key={`${el.orders[0]}`}
									>
										{el.orders[0]}
									</Link>
									<br />
								</>
							)}
							{el.orders[1] && (
								<>
									<Link
										to={`/order/${el.orders[1]}`}
										target='_blank'
										className='tableRow__link'
										key={`${el.orders[1]}`}
									>
										{el.orders[1]}
									</Link>
									<br />
								</>
							)}
							{el.orders.length > 2 && (
								<>
									<div
										className={cn(
											'tableRow__showmore-text',
											toggle === el.id ? 'slide' : ''
										)}
									>
										{el.orders.slice(2).map((item, index) => (
											<div key={`${item}`}>
												<Link
													to={`/order/${item}`}
													target='_blank'
													className='tableRow__link'
													key={`${item}-${index}`}
												>
													{item}
												</Link>
												<br />
											</div>
										))}
										<br />
									</div>
									<span
										className='tableRow__showmore'
										onClick={() => setToggle(toggle === el.id ? 0 : el.id)}
									>
										{toggle === el.id ? 'скрыть' : 'показать все'}
									</span>
								</>
							)}
						</div>
						<div className={cn('tableRow__item', 'tableRow__item--big')}>
							{el.email}
						</div>
						<div className={cn('tableRow__item', 'tableRow__item--big')}>
							{el.name}
						</div>
						<div className={cn('tableRow__item', 'tableRow__item--status')}>
							{el.status}
						</div>
						<div className={cn('tableRow__item', 'tableRow__item--date')}>
							{el.payment_date}
						</div>
						<div className={cn('tableRow__item', 'tableRow__item--reason')}>
							{el.reason}
						</div>
						<div className={cn('tableRow__item', 'tableRow__item--date')}>
							{el.created_at}
						</div>
						{el.status === 'Новая' ? (
							<div
								className={cn('tableRow__item__button', 'tableRow__item--m')}
								onClick={() => handleEditButton(el)}
							>
								Выполнить
							</div>
						) : (
							<div className={cn('tableRow__item', 'tableRow__item--m')}>
								Заявка выполнена
							</div>
						)}
					</div>
				</>
			))
		case 'product':
			return (
				<>
					<div className='tableRow tableRowCheck'>
						<input
							type='checkbox'
							name='selectAll'
							onChange={handleClickCheckSelectAll}
							checked={isCheckAll}
						/>
						<div className={cn('tableRow__item', 'tableRow__item--sm')}></div>
						<div
							className={cn(
								'tableRow__item',
								'tableRow__item--big',
								'checkAll__button'
							)}
						>
							Выбрать все
							<button disabled={!isCheck.length} onClick={handleClickStartEdit}>
								Редактировать
							</button>
						</div>
						<div className='tableRow__item'></div>
						<div className='tableRow__item'></div>
						<div className='tableRow__item'></div>
						<div className='tableRow__item'></div>
						<div className='tableRow__item'></div>
						<div className='tableRow__item'></div>
						<div className='tableRow__item'></div>
						<div className='tableRow__item'></div>
						<div className='tableRow__item'></div>
					</div>
					{content.map(el => (
						<div className='tableRow' key={el.id}>
							<input
								key={el.id}
								type='checkbox'
								name={el.name}
								id={el.id}
								onChange={handleClickCheck}
								checked={isCheck.includes(el.id)}
							/>
							<div className={cn('tableRow__item', 'tableRow__item--sm')}>
								{el.id}
							</div>
							<div className={cn('tableRow__item')}>
								<img style={{ width: 132 }} src={el.cover} alt='' />
							</div>
							<div className={cn('tableRow__item', 'tableRow__item--big')}>
								{el.innerName}
							</div>
							<div className={cn('tableRow__item')}>
								<span
									className={cn('tableRow__item-btn', {
										'tableRow__item-btn--green': el.status === 1,
										'tableRow__item-btn--red': el.status === 2
									})}
								>
									{el.status === 1 ? 'Активный' : 'Неактивный'}
								</span>
							</div>
							<div className={cn('tableRow__item')}>{el.currency}</div>
							<div className={cn('tableRow__item')}>{el.displayCurrency}</div>
							<div className='tableRow__item'>
								{el.price} | {el.oldPrice}
							</div>
							<div className='tableRow__item'>{el.tariff}</div>
							<div className={cn('tableRow__item', 'tableRow__item--big')}>
								{el.domains[0] && (
									<>
										<span>{el.domains[0].link}</span>
										<br />
									</>
								)}
								{el.domains[1] && (
									<>
										<span>{el.domains[1].link}</span>
										<br />
									</>
								)}
								{el.domains.length > 2 && (
									<>
										<div
											className={cn(
												'tableRow__showmore-text',
												toggle === el.id ? 'slide' : ''
											)}
										>
											{el.domains.slice(2).map(({ link }) => (
												<div key={`${el.id}-${Math.random()}`}>
													<span>{link}</span>
													<br />
												</div>
											))}
											<br />
										</div>
										<span
											className='tableRow__showmore'
											onClick={() => setToggle(toggle === el.id ? 0 : el.id)}
										>
											{toggle === el.id ? 'скрыть' : 'показать все'}
										</span>
									</>
								)}
							</div>
							<div
								className={cn(
									'tableRow__item',
									'tableRow__item--svg',
									'tableRow__item--m'
								)}
							>
								{role.permissions.product.includes('edit_products') && (
									<>
										<span
											onClick={() =>
												btnCopy(
													'Вы уверены что хотите создать копию?',
													{ id: el.id },
													'copy'
												)
											}
											className='tableRow__svg-bin'
										>
											<Copy />
										</span>
										<Link
											to={`/product/edit/${el.id}`}
											className='tableRow__svg-pen'
										>
											<Pen />
										</Link>
										<span
											onClick={() =>
												btnDelete('Вы действительно хотите удалить продукт?', {
													id: el.id
												})
											}
											className='tableRow__svg-bin'
										>
											<Bin />
										</span>
									</>
								)}
							</div>
						</div>
					))}
				</>
			)
		case 'analytics':
			function formatStatsNumber(num) {
				return num.toLocaleString('ru-RU', {
					minimumFractionDigits: 2,
					maximumFractionDigits: 2
				})
			}
			return (
				<>
					{Object.entries(content).map((el, index) => {
						return (
							<>
								<div className='tableRow' key={index}>
									<div className={cn('tableRow__item')}>{`${el[0]
										.slice(0, 10)
										.split('-')
										.reverse()
										.join('.')}`}</div>
									<div className={cn('tableRow__item')}>
										<ul>
											{Object.values(el[1]).map(item => {
												return Object.keys(item).map((nest, index) => {
													return <div key={`${nest}-${index}`}>{nest}</div>
												})
											})}
										</ul>
									</div>
									<div className={cn('tableRow__item')}>
										<ul>
											{Object.values(el[1]).map(item => {
												return Object.values(item).map((it, index) => {
													return (
														<li
															key={`${it.bill_count}-${index}`}
															style={{ textAlign: 'right' }}
														>
															{it.bill_count}
														</li>
													)
												})
											})}
										</ul>
									</div>
									<div className={cn('tableRow__item')}>
										<ul>
											{Object.values(el[1]).map(item => {
												return Object.values(item).map((it, index) => {
													return (
														<li
															key={`${it.bill_amount}-${index}`}
															style={{ textAlign: 'right' }}
														>
															{formatStatsNumber(it.bill_amount)}
														</li>
													)
												})
											})}
										</ul>
									</div>
									<div className={cn('tableRow__item')}>
										<ul>
											{Object.values(el[1]).map(item => {
												return Object.values(item).map((it, index) => {
													return (
														<li
															key={`${it.rebill_count}-${index}`}
															style={{ textAlign: 'right' }}
														>
															{it.rebill_count}
														</li>
													)
												})
											})}
										</ul>
									</div>
									<div className={cn('tableRow__item')}>
										<ul>
											{Object.values(el[1]).map(item => {
												return Object.values(item).map((it, index) => {
													return (
														<li
															key={`${it.rebill_amount}-${index}`}
															style={{ textAlign: 'right' }}
														>
															{formatStatsNumber(it.rebill_amount)}
														</li>
													)
												})
											})}
										</ul>
									</div>
									<div className={cn('tableRow__item')}>
										<ul>
											{Object.values(el[1]).map(item => {
												return Object.values(item).map((it, index) => {
													return (
														<li
															key={`${it.renew_count}-${index}`}
															style={{ textAlign: 'right' }}
														>
															{it.renew_count}
														</li>
													)
												})
											})}
										</ul>
									</div>
									<div className={cn('tableRow__item')}>
										<ul>
											{Object.values(el[1]).map(item => {
												return Object.values(item).map((it, index) => {
													return (
														<li
															key={`${it.renew_amount}-${index}`}
															style={{ textAlign: 'right' }}
														>
															{formatStatsNumber(it.renew_amount)}
														</li>
													)
												})
											})}
										</ul>
									</div>
									<div className={cn('tableRow__item')}>
										<ul>
											{Object.values(el[1]).map(item => {
												return Object.values(item).map((it, index) => {
													return (
														<li
															key={`${it.count}-${index}`}
															style={{ textAlign: 'right' }}
														>
															{it.count}
														</li>
													)
												})
											})}
										</ul>
									</div>
									<div className={cn('tableRow__item')}>
										<ul>
											{Object.values(el[1]).map(item => {
												return Object.values(item).map((nest, index) => {
													return (
														<li
															key={`${nest.sum}-${index}`}
															style={{ textAlign: 'right' }}
														>
															{formatStatsNumber(nest.sum)}
														</li>
													)
												})
											})}
										</ul>
									</div>
									<div className={cn('tableRow__item')}>
										<ul>
											{Object.entries(el[1]).map(item => {
												const currency = item[0]
												return Object.keys(item[1]).map((nest, index) => {
													return <li key={index}>{currency}</li>
												})
											})}
										</ul>
									</div>
								</div>
							</>
						)
					})}

					<div className='tableRow'>
						<div className={cn('tableRow__item')}>Итого:</div>
						<div className={cn('tableRow__item')}></div>
						<div className={cn('tableRow__item')}>
							<ul>
								{getPaymentSummary(content, 'bill_count')?.map(
									(item, index) => (
										<li key={index} style={{ textAlign: 'right' }}>
											{Object.values(item)?.[0]}
										</li>
									)
								)}
							</ul>
						</div>
						<div className={cn('tableRow__item')}>
							<ul>
								{getPaymentSummary(content, 'bill_amount')?.map(
									(item, index) => (
										<li key={index} style={{ textAlign: 'right' }}>
											{formatStatsNumber(Object.values(item)?.[0]) || null}
										</li>
									)
								)}
							</ul>
						</div>
						<div className={cn('tableRow__item')}>
							<ul>
								{getPaymentSummary(content, 'rebill_count')?.map(
									(item, index) => (
										<li key={index} style={{ textAlign: 'right' }}>
											{Object.values(item)?.[0]}
										</li>
									)
								)}
							</ul>
						</div>
						<div className={cn('tableRow__item')}>
							<ul>
								{getPaymentSummary(content, 'rebill_amount')?.map(
									(item, index) => (
										<li key={index} style={{ textAlign: 'right' }}>
											{formatStatsNumber(Object.values(item)?.[0]) || null}
										</li>
									)
								)}
							</ul>
						</div>
						<div className={cn('tableRow__item')}>
							<ul>
								{getPaymentSummary(content, 'renew_count')?.map(
									(item, index) => (
										<li key={index} style={{ textAlign: 'right' }}>
											{Object.values(item)?.[0]}
										</li>
									)
								)}
							</ul>
						</div>
						<div className={cn('tableRow__item')}>
							<ul>
								{getPaymentSummary(content, 'renew_amount')?.map(
									(item, index) => (
										<li key={index} style={{ textAlign: 'right' }}>
											{formatStatsNumber(Object.values(item)?.[0]) || null}
										</li>
									)
								)}
							</ul>
						</div>
						<div className={cn('tableRow__item')}>
							<ul>
								{getPaymentSummary(content, 'count')?.map((item, index) => (
									<li key={index} style={{ textAlign: 'right' }}>
										{Object.values(item)?.[0] || null}
									</li>
								))}
							</ul>
						</div>
						<div className={cn('tableRow__item')}>
							<ul>
								{getPaymentSummary(content, 'sum')?.map((item, index) => (
									<li key={index} style={{ textAlign: 'right' }}>
										{formatStatsNumber(Object.values(item)?.[0]) || null}
									</li>
								))}
							</ul>
						</div>
						<div className={cn('tableRow__item')}>
							<ul>
								{getPaymentSummary(content, 'sum')?.map((item, index) => (
									<li key={index}>{Object.keys(item)?.[0]}</li>
								))}
							</ul>
						</div>
					</div>
				</>
			)
		case 'analytics-mids':
			return content.map(el => {
				function formatNumber(num) {
					return num?.toLocaleString('ru-RU')
				}
				function formatNumber2(num) {
					return num.toLocaleString('ru-RU', {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2
					})
				}
				function calcPercent(num, purpose) {
					return Math.round((num * 100) / purpose)
				}
				function calcPercent2(num, purpose) {
					return formatNumber2((num * 100) / purpose)
				}
				function returnPercentFail(obj) {
					return Object.values(obj.stats)
						.map(item => item.fail_purchases)
						.reduce((sum, current) => sum + current)
				}
				function returnPercentSuccess(obj) {
					return Object.values(obj.stats)
						.map(item => item.success_purchases)
						.reduce((sum, current) => sum + current)
				}
				function returnPercentTotal(obj) {
					return Object.values(obj.stats)
						.map(item => item.total_purchases)
						.reduce((sum, current) => sum + current)
				}
				function returnPercentRefund(obj) {
					return Object.values(obj.stats)
						.map(item => item.refunds)
						.reduce((sum, current) => sum + current)
				}
				function returnPercentChargebacks(obj) {
					return Object.values(obj.stats)
						.map(item => item.chargebacks)
						.reduce((sum, current) => sum + current)
				}
				function returnPercentShake(obj) {
					return Object.values(obj.stats)
						.map(item => item.shake)
						.reduce((sum, current) => sum + current)
				}
				return (
					<>
						<div className='tableRow' key={`${el.name}-${el.gateway_id}`}>
							<div className={cn('tableRow__item')}>{el.payment_system}</div>
							<div className={cn('tableRow__item', 'tableRow__item--hm')}>
								<span
									className={
										el.status === '1' ? 'status-active' : 'status-inactive'
									}
								></span>
								<Link
									to={`/gateway/edit/${el.gateway_id}`}
									className='tableRow__svg-pen'
								>
									<span>{el.gateway}</span>
								</Link>
							</div>
							<div className={cn('tableRow__item')}>{el.bank}</div>
							<div className={cn('tableRow__item', 'tableRow__item--hm')}>
								{el.first_transacted_at
									? `${el.first_transacted_at
											.slice(0, 10)
											.split('-')
											.reverse()
											.join('-')} ${el.first_transacted_at.slice(-8)}`
									: null}
							</div>
							<div className={cn('tableRow__item', 'tableRow__item--m')}>
								{el.currency}
							</div>
							<div className={cn('tableRow__item', 'tableRow__item--bigw')}>
								{el.products[0] && (
									<>
										<Link
											to={`/product/edit/${el.products[0].id}`}
											className='tableRow__svg-pen'
										>
											<span>{el.products[0].name}</span>
										</Link>
										<br />
									</>
								)}
								{el.products[1] && (
									<>
										<Link
											to={`/product/edit/${el.products[1].id}`}
											className='tableRow__svg-pen'
										>
											<span>{el.products[1].name}</span>
										</Link>
										<br />
									</>
								)}
								{el.products.length > 2 && (
									<>
										<div
											className={cn(
												'tableRow__showmore-text',
												toggle === el.gateway_id ? 'slide' : ''
											)}
										>
											{el.products.slice(2).map(({ name, id }) => (
												<div key={`${id}-${Math.random()}`}>
													<Link
														to={`/product/edit/${id}`}
														className='tableRow__svg-pen'
													>
														<span>{name}</span>
													</Link>
													<br />
												</div>
											))}
											<br />
										</div>
										<span
											className='tableRow__showmore'
											onClick={() =>
												setToggle(toggle === el.gateway_id ? 0 : el.gateway_id)
											}
										>
											{toggle === el.gateway_id ? 'скрыть' : 'показать все'}
										</span>
									</>
								)}
							</div>
							<div className={cn('tableRow__item', 'tableRow__item--m')}>
								<ul>
									{Object.keys(el.stats).map(item => (
										<li
											style={{ fontFamily: 'monospace' }}
											key={`${item}-${Math.random()}`}
										>
											{item}
										</li>
									))}
									<li style={{ fontFamily: 'monospace', fontWeight: 'bold' }}>
										Total
									</li>
								</ul>
							</div>
							<div className='tableRow__item tableRow__item--rigthmr'>
								<ul>
									{Object.values(el.stats).map(item => (
										<li
											style={{ textAlign: 'right', fontFamily: 'monospace' }}
											key={`${item}-${Math.random()}`}
										>
											{formatNumber(item.total_purchases)}
										</li>
									))}
									<li className='li-mids'>
										{formatNumber(returnPercentTotal(el))}
									</li>
								</ul>
							</div>
							<div className='tableRow__item tableRow__item--rigthmm'>
								<ul>
									{Object.values(el.stats).map(item => (
										<li className='limids2' key={`${item}-${Math.random()}`}>
											<span>{formatNumber(item.success_purchases)}</span>
											<span
												title='%'
												style={{ minWidth: '25px', marginLeft: '5px' }}
											>{`${calcPercent(
												item.success_purchases,
												item.total_purchases
											)}`}</span>
										</li>
									))}
									<li className='limids2 limids--bold'>
										<span>{`${formatNumber(returnPercentSuccess(el))}`}</span>
										<span
											title='%'
											style={{ minWidth: '25px', marginLeft: '5px' }}
										>{`${calcPercent(
											returnPercentSuccess(el),
											returnPercentTotal(el)
										)}`}</span>
									</li>
								</ul>
							</div>
							<div className='tableRow__item tableRow__item--rigthm'>
								<ul>
									{Object.values(el.stats).map(item => (
										<li className='limids2' key={`${item}-${Math.random()}`}>
											<span>{formatNumber(item.fail_purchases)}</span>
											<span
												title='%'
												style={{ minWidth: '25px', marginLeft: '5px' }}
											>{`${calcPercent(
												item.fail_purchases,
												item.total_purchases
											)}`}</span>
										</li>
									))}
									<li className='limids2 limids--bold'>
										<span>{`${formatNumber(returnPercentFail(el))}`}</span>
										<span
											title='%'
											style={{ minWidth: '25px', marginLeft: '5px' }}
										>{`${calcPercent(
											returnPercentFail(el),
											returnPercentTotal(el)
										)}`}</span>
									</li>
								</ul>
							</div>
							<div className='tableRow__item tableRow__item--rigthm'>
								<ul>
									{Object.values(el.stats).map(item => (
										<li
											style={{ fontFamily: 'monospace' }}
											key={`${item}-${Math.random()}`}
										>
											{formatNumber(item.shake)}
										</li>
									))}
									<li className='li-mids'>{`${formatNumber(
										returnPercentShake(el)
									)}`}</li>
								</ul>
							</div>
							<div className='tableRow__item tableRow__item--rigthm'>
								<ul>
									{Object.values(el.stats).map(item => (
										<li className='limids' key={`${item}-${Math.random()}`}>
											<span>{formatNumber(item.refunds)}</span>
											<span
												title='%'
												style={{ minWidth: '25px', marginLeft: '5px' }}
											>{`${calcPercent2(
												item.refunds,
												item.total_purchases
											)}`}</span>
										</li>
									))}
									<li className='limids limids--bold'>
										<span>{`${formatNumber(returnPercentRefund(el))}`}</span>
										<span
											title='%'
											style={{ minWidth: '25px', marginLeft: '5px' }}
										>{`${calcPercent2(
											returnPercentRefund(el),
											returnPercentSuccess(el)
										)}`}</span>
									</li>
								</ul>
							</div>
							<div className='tableRow__item tableRow__item--chargeback'>
								<ul>
									{Object.values(el.stats).map(item => (
										<li className='limids' key={`${item}-${Math.random()}`}>
											<span>{formatNumber(item.chargebacks)}</span>
											<span
												title='%'
												style={{ minWidth: '25px', marginLeft: '5px' }}
											>{`${calcPercent2(
												item.chargebacks,
												item.total_purchases
											)}`}</span>
										</li>
									))}
									<li className='limids limids--bold'>
										<span>{`${formatNumber(
											returnPercentChargebacks(el)
										)}`}</span>
										<span
											title='%'
											style={{ minWidth: '25px', marginLeft: '5px' }}
										>{`${calcPercent2(
											returnPercentChargebacks(el),
											returnPercentSuccess(el)
										)}`}</span>
									</li>
								</ul>
							</div>
							<div className='tableRow__item tableRow__item--rigthm'>
								{el.appeals}
							</div>
							<div
								data-tip
								data-for={el.gateway_id}
								className='tableRow__item tableRow__item--rigthm'
							>
								{el.strikes.length}
							</div>
							<ReactTooltip place='left' id={el.gateway_id}>
								<>
									{el.strikes.map((item, index) => (
										<div
											className='strikes-tooltip'
											key={`${index}-${Math.random()}`}
										>
											<span>
												{item.stroke_at
													.slice(0, 10)
													.split('-')
													.reverse()
													.join('.')}
											</span>
											<pre>{item.description}</pre>
											<br />
										</div>
									))}
									<br />
								</>
							</ReactTooltip>
						</div>
					</>
				)
			})
		case 'chargeBacks-statistics':
			return content.map((el, index) => (
				<div className='tableRow' key={index}>
					<div className='tableRow__item tableRow__item--stats'>
						{el.utm_id}
					</div>
					<div className='tableRow__item tableRow__item--charge'>{`${
						el.chargebacks
					} / ${((el.chargebacks * 100) / el.counts).toFixed(3)}%`}</div>
					<div className='tableRow__item tableRow__item--charge2'>{`${
						el.arbitrations
					} / ${((el.arbitrations * 100) / el.counts).toFixed(3)}%`}</div>
					<div className='tableRow__item'>{el.counts}</div>
				</div>
			))
		case 'chargeBacks-webmaster':
			return content.map((el, index) => (
				<div className='tableRow' key={index}>
					<div className='tableRow__item'>{el.web_id}</div>
					<div className='tableRow__item tableRow__item--stats'>{el.email}</div>
					<div className='tableRow__item'>{el.type}</div>
					<div className='tableRow__item'>{`${el.created_at
						.slice(0, 10)
						.split('-')
						.reverse()
						.join('.')} ${el.created_at.slice(-8)}`}</div>
					<div className='tableRow__item tableRow__item--stats'>
						{el.product}
					</div>
					<div className='tableRow__item'>{el.amount}</div>
					<div className='tableRow__item tableRow__item--stats'>
						{el.landing}
					</div>
					<div className='tableRow__item tableRow__item--stats'>
						{el.equirer}
					</div>
					<div className='tableRow__item tableRow__item--svg'>
						<span
							onClick={() =>
								btnDelete('Вы действительно хотите удалить транзакцию?', {
									id: el.id
								})
							}
							className='tableRow__svg-bin'
						>
							<Bin />
						</span>
					</div>
				</div>
			))
		case 'analytics-mids-total':
			const totalAll = content
				?.map(item => item?.totalAll)
				?.reduce((sum, current) => sum + current)
			const totalSuccess = content
				?.map(item => item?.totalSuccess)
				?.reduce((sum, current) => sum + current)
			const totalRefund = content
				?.map(item => item?.totalRefund)
				?.reduce((sum, current) => sum + current)
			const totalChargeBacks = content
				?.map(item => item?.totalChargeBacks)
				?.reduce((sum, current) => sum + current)
			const totalShakes = content
				?.map(item => item?.totalShakes)
				?.reduce((sum, current) => sum + current)
			const totalFail = content
				?.map(item => item?.totalFail)
				?.reduce((sum, current) => sum + current)
			const totalMails = content
				?.map(item => Number(item.appeals))
				?.reduce((sum, current) => sum + current)
			const totalStrikes = content
				?.map(item => item.strikes.length)
				?.reduce((sum, current) => sum + current)
			function formatNumber(num) {
				return num?.toLocaleString('ru-RU')
			}
			function formatNumber2(num) {
				return num.toLocaleString('ru-RU', {
					minimumFractionDigits: 2,
					maximumFractionDigits: 2
				})
			}
			function calcPercent(num, purpose) {
				return Math.round((num * 100) / purpose)
			}
			function calcPercent2(num, purpose) {
				return formatNumber2((num * 100) / purpose)
			}
			return (
				content.length && (
					<>
						<div className='tableRow'>
							<div className={cn('tableRow__item')}></div>
							<div className={cn('tableRow__item', 'tableRow__item--hm')}></div>
							<div className={cn('tableRow__item')}></div>
							<div className={cn('tableRow__item', 'tableRow__item--hm')}></div>
							<div className={cn('tableRow__item', 'tableRow__item--m')}></div>
							<div
								className={cn('tableRow__item', 'tableRow__item--bigw')}
							></div>
							<div className={cn('tableRow__item', 'tableRow__item--m')}>
								<ul>
									<li style={{ fontFamily: 'monospace', fontWeight: 'bold' }}>
										Total
									</li>
								</ul>
							</div>
							<div className='tableRow__item tableRow__item--rigthmr'>
								<ul>
									<li className='li-mids'>{formatNumber(totalAll)}</li>
								</ul>
							</div>
							<div className='tableRow__item tableRow__item--rigthmm'>
								<ul>
									<li className='limids2 limids--bold'>
										<span>{formatNumber(totalSuccess)}</span>
										<span
											title='%'
											style={{ minWidth: '25px', marginLeft: '5px' }}
										>{`${calcPercent(totalSuccess, totalAll)}`}</span>
									</li>
								</ul>
							</div>
							<div className='tableRow__item tableRow__item--rigthm'>
								<ul>
									<li className='limids2 limids--bold'>
										<span>{formatNumber(totalFail)}</span>
										<span
											title='%'
											style={{ minWidth: '25px', marginLeft: '5px' }}
										>{`${calcPercent(totalFail, totalAll)}`}</span>
									</li>
								</ul>
							</div>
							<div className='tableRow__item tableRow__item--rigthm'>
								<ul>
									<li className='li-mids'>{formatNumber(totalShakes)}</li>
								</ul>
							</div>
							<div className='tableRow__item tableRow__item--rigthm'>
								<ul>
									<li className='limids limids--bold'>
										<span>{formatNumber(totalRefund)}</span>
										<span
											title='%'
											style={{ minWidth: '25px', marginLeft: '5px' }}
										>
											{calcPercent2(totalRefund, totalSuccess)}
										</span>
									</li>
								</ul>
							</div>
							<div className='tableRow__item tableRow__item--chargeback'>
								<ul>
									<li className='limids limids--bold'>
										<span>{formatNumber(totalChargeBacks)}</span>
										<span
											title='%'
											style={{ minWidth: '25px', marginLeft: '5px' }}
										>
											{calcPercent2(totalChargeBacks, totalSuccess)}
										</span>
									</li>
								</ul>
							</div>
							<div className='tableRow__item tableRow__item--rigthm'>
								{totalMails}
							</div>
							<div className='tableRow__item tableRow__item--rigthm'>
								{totalStrikes}
							</div>
						</div>
					</>
				)
			)
		case 'admin':
			return content.map(el => (
				<div className='tableRow' key={el.id}>
					<div className={cn('tableRow__item', 'tableRow__item--sm')}>
						{el.id}
					</div>
					<div className={cn('tableRow__item')}>{el.role.name}</div>
					<div className={cn('tableRow__item', 'tableRow__item--big')}>
						{el.username}
					</div>
					<div
						className={cn(
							'tableRow__item',
							'tableRow__item--svg',
							'tableRow__item--m'
						)}
					>
						{role.permissions.admin.includes('edit_admins') && (
							<>
								<Link to={`/admin/edit/${el.id}`} className='tableRow__svg-pen'>
									<Pen />
								</Link>
								<span
									onClick={() =>
										btnDelete('Вы действительно хотите удалить админа?', {
											id: el.id
										})
									}
									className='tableRow__svg-bin'
								>
									<Bin />
								</span>
							</>
						)}
					</div>
				</div>
			))
		case 'admin-role':
			return content.map(el => {
				return (
					<div className='tableRow' key={el.id}>
						<div className={cn('tableRow__item', 'tableRow__item--sm')}>
							{el.id}
						</div>
						<div className={cn('tableRow__item')}>{el.name}</div>
						<div className={cn('tableRow__item', 'tableRow__item--big')}>
							{sections
								.filter(section =>
									Object.keys(el.permissions).includes(section.section)
								)
								.map((item, idx) => {
									return (
										<div className='roles-tab'>
											<span className='roles-tab__name'>{item.name}</span>
											{item.viewName && (
												<span className='roles-tab__input'>
													<label>Чтение</label>
													<input
														type='checkbox'
														disabled={true}
														checked={el.permissions[item.section].includes(
															item.viewName
														)}
													/>
												</span>
											)}
											{item.editName && (
												<span className='roles-tab__input'>
													<label>Запись</label>
													<input
														type='checkbox'
														disabled={true}
														checked={el.permissions[item.section].includes(
															item.editName
														)}
													/>
												</span>
											)}
											{item.refundName && (
												<span className='roles-tab__input'>
													<label>Возврат</label>
													<input
														type='checkbox'
														disabled={true}
														checked={el.permissions[item.section].includes(
															item.refundName
														)}
													/>
												</span>
											)}
											{item.chargebackName && (
												<span className='roles-tab__input'>
													<label>Чарджбэк</label>
													<input
														type='checkbox'
														disabled={true}
														checked={el.permissions[item.section].includes(
															item.chargebackName
														)}
													/>
												</span>
											)}
											{item.moveName && (
												<span className='roles-tab__input'>
													<label>Перенос</label>
													<input
														type='checkbox'
														disabled={true}
														checked={el.permissions[item.section].includes(
															item.moveName
														)}
													/>
												</span>
											)}
											{item.createName && (
												<span className='roles-tab__input'>
													<label>Создание</label>
													<input
														type='checkbox'
														disabled={true}
														checked={el.permissions[item.section].includes(
															item.createName
														)}
													/>
												</span>
											)}
										</div>
									)
								})}
						</div>
						<div
							className={cn(
								'tableRow__item',
								'tableRow__item--svg',
								'tableRow__item--m'
							)}
						>
							{role.permissions.admin.includes('edit_admins') && (
								<>
									{/* hard cording for Администратора - типа супер админ нельзя редактировать */}
									{el.id === 1 ? null : (
										<>
											<Link
												to={`/admin/role/edit/${el.id}`}
												className='tableRow__svg-pen'
											>
												<Pen />
											</Link>
											<span
												onClick={() =>
													btnDelete('Вы действительно хотите удалить роль?', {
														id: el.id
													})
												}
												className='tableRow__svg-bin'
											>
												<Bin />
											</span>
										</>
									)}
								</>
							)}
						</div>
					</div>
				)
			})
		case 'user':
			return content.map(el => {
				return (
					<div className='tableRow' key={el.id}>
						<div className='tableRow__item tableRow__item--sm'>
							<Link
								to={`/orders/all?userId=${el.id}`}
								className='tableRow__svg-pen'
							>
								{el.id}
							</Link>
						</div>
						<div className='tableRow__item tableRow__item--big'>{el.email}</div>
						<div className='tableRow__item tableRow__item--big'>{el.name}</div>
						<div className='tableRow__item'>{el.countOrders}</div>
						<div className='tableRow__item'>{el.countPayments}</div>
						<div className='tableRow__item'>{el.total} ₽</div>
						<div className={cn('tableRow__item', 'tableRow__item--big')}>
							{el.createdAt}
						</div>
						<div className='tableRow__item'>{el.status}</div>
						<div
							className={cn(
								'tableRow__item',
								'tableRow__item--svg',
								'tableRow__item--m'
							)}
						>
							{role.permissions.user.includes('edit_users') && (
								<>
									<Link to={`/user/${el.id}`} className='tableRow__svg-pen'>
										<Pen />
									</Link>
									<span
										onClick={() =>
											btnDelete('Вы действительно хотите удалить клиента?')
										}
										className='tableRow__svg-bin'
									>
										<Bin />
									</span>
								</>
							)}
						</div>
					</div>
				)
			})
		case 'file':
			return (
				<>
					<div to={'/file/all'} className='tableRow'>
						<div className='tableRow__item' />
						<div className={cn('tableRow__item', 'tableRow__item--big')}>
							Все продукты
						</div>
						<div className={cn('tableRow__item', 'tableRow__item--big')}>
							{content.length}
						</div>
						<div
							className={cn(
								'tableRow__item',
								'tableRow__item--svg',
								'tableRow__item--m'
							)}
						>
							{role.permissions.file.includes('edit_files') && (
								<Link to={`/file/all`} className='tableRow__svg-pen'>
									<Pen />
								</Link>
							)}
						</div>
					</div>
					{content.map(el => (
						<div className='tableRow' key={el.id}>
							<div className='tableRow__item'>{el.id}</div>
							<div className={cn('tableRow__item', 'tableRow__item--big')}>
								{el.name}
							</div>
							<div className={cn('tableRow__item', 'tableRow__item--big')}>
								{el.countFiles}
							</div>
							<div
								className={cn(
									'tableRow__item',
									'tableRow__item--svg',
									'tableRow__item--m'
								)}
							>
								{role.permissions.file.includes('edit_files') && (
									<Link to={`/file/${el.id}`} className='tableRow__svg-pen'>
										<Pen />
									</Link>
								)}
							</div>
						</div>
					))}
				</>
			)
		case 'fileExactProduct':
			return content.map(el => (
				<div className='tableRow' key={el.id}>
					<div className={cn('tableRow__item', 'tableRow__item--sm')}>
						{el.id}
					</div>
					<div className={cn('tableRow__item')}>
						<img style={{ width: 132 }} src={el.cover} alt='' />
					</div>
					<div className='tableRow__item'>{el.name}</div>
					<div className='tableRow__item'>{el.number}</div>
					<div className={cn('tableRow__item', 'tableRow__item--big')}>
						{el.path}
					</div>
					<div className={cn('tableRow__item')}>
						{el.products?.length ? (
							<>
								{el.products.slice(0, 3).map(({ id, innerName }) => (
									<div>{`${innerName} (id: ${id})`}</div>
								))}
								{el.products?.length > 3 ? (
									<>
										<div
											className={cn(
												'tableRow__showmore-text',
												toggle === el.id ? 'slide' : ''
											)}
										>
											{el.products.slice(3).map(({ id, innerName }) => (
												<>
													<div>{`${innerName} (id: ${id})`}</div>
												</>
											))}
											<br />
										</div>
										<span
											className='tableRow__showmore'
											onClick={() => setToggle(toggle === el.id ? 0 : el.id)}
										>
											{toggle === el.id ? 'скрыть' : 'показать все'}
										</span>
									</>
								) : null}
							</>
						) : null}
					</div>
					<div
						className={cn(
							'tableRow__item',
							'tableRow__item--svg',
							'tableRow__item--m'
						)}
					>
						{role.permissions.file.includes('edit_files') && (
							<>
								<Link to={`/file/edit/${el.id}`} className='tableRow__svg-pen'>
									<Pen />
								</Link>
								<span
									onClick={() =>
										btnDelete('Вы действительно хотите удалить файл?', {
											id: el.id
										})
									}
									className='tableRow__svg-bin'
								>
									<Bin />
								</span>
							</>
						)}
					</div>
				</div>
			))
		case 'pixels':
			return content.length ? (
				content.map(el => (
					<div className='tableRow' key={el.id}>
						<div className='tableRow__item'>{el.id}</div>
						<div className={cn('tableRow__item', 'tableRow__item--big')}>
							{el.type}
						</div>
						<div className={cn('tableRow__item', 'tableRow__item--big')}>
							{el.value}
						</div>
						<div
							className={cn(
								'tableRow__item',
								'tableRow__item--svg',
								'tableRow__item--m'
							)}
						>
							{role.permissions.product.includes('edit_products') && (
								<>
									<Link
										to={`/pixel/edit/${el.id}`}
										className='tableRow__svg-pen'
									>
										<Pen />
									</Link>
									<span
										onClick={() =>
											btnDelete('Вы действительно хотите удалить пиксель?', {
												id: el.id,
												productId: +productId
											})
										}
										className='tableRow__svg-bin'
									>
										<Bin />
									</span>
								</>
							)}
						</div>
					</div>
				))
			) : (
				<div className='tableRow'>
					{' '}
					<div className={cn('tableRow__item', 'tableRow__item--big')}>
						У данного продукта ещё нет пикселей
					</div>
				</div>
			)
		case 'strikes':
			return content.length ? (
				content.map(el => (
					<div className='tableRow' key={el.id}>
						<div className={cn('tableRow__item', 'tableRow__item--big')}>
							{el.description}
						</div>
						<div className={cn('tableRow__item', 'tableRow__item--big')}>
							{el.strokeAt.slice(0, 10).split('-').reverse().join('.')}
						</div>
						<div
							className={cn(
								'tableRow__item',
								'tableRow__item--svg',
								'tableRow__item--m'
							)}
						>
							{role.permissions.gateway.includes('edit_gateways') && (
								<>
									<Link
										to={`/strikes/edit/${el.id}`}
										className='tableRow__svg-pen'
									>
										<Pen />
									</Link>
									<span
										onClick={() =>
											btnDelete('Вы действительно хотите удалить strikes?', {
												id: el.id,
												gatewaysId: +gatewaysId
											})
										}
										className='tableRow__svg-bin'
									>
										<Bin />
									</span>
								</>
							)}
						</div>
					</div>
				))
			) : (
				<div className='tableRow'>
					<div className={cn('tableRow__item', 'tableRow__item--big')}>
						У данного продукта ещё нет strikes
					</div>
				</div>
			)
		case 'gatewayTransfer':
			return content.length ? (
				content.map(el => (
					<div className='tableRow' key={el.id}>
						<div className='tableRow__item tableRow__item--big'>{`${el.created_at
							.slice(0, 10)
							.split('-')
							.reverse()
							.join('.')} ${el.created_at.slice(-8)}`}</div>
						<div className='tableRow__item tableRow__item--big'>
							{el?.admin_username}
						</div>
						<div className='tableRow__item'>{el.data.pgFromId}</div>
						<div className='tableRow__item'>{el.data.pgToId}</div>
						<div className='tableRow__item tableRow__item--big'>
							{el?.data?.issuer}
						</div>
						<div className='tableRow__item'>{el.data.orderIds}</div>
						<div className='tableRow__item tableRow__item--big'>
							{(el.data?.lastTransactionErrors?.[0] ||
								el.data?.lastTransactionErrors?.[0] === '') && (
								<>
									{
										<span>
											{el.data.lastTransactionErrors[0] === ''
												? 'Без ошибок'
												: el.data.lastTransactionErrors[0]}
										</span>
									}
									<br />
								</>
							)}
							{(el.data?.lastTransactionErrors?.[1] ||
								el.data?.lastTransactionErrors?.[1] === '') && (
								<>
									{
										<span>
											{el.data.lastTransactionErrors[1] === ''
												? 'Без ошибок'
												: el.data.lastTransactionErrors[1]}
										</span>
									}
									<br />
								</>
							)}
							{el.data?.lastTransactionErrors?.length > 2 && (
								<>
									<div
										className={cn(
											'tableRow__showmore-text',
											toggle === el.id ? 'slide' : ''
										)}
									>
										{el.data?.lastTransactionErrors
											.slice(2)
											.map((item, index) => (
												<div
													key={`${item === '' ? 'Без ошибок' : item}-${index}`}
												>
													<span>{item === '' ? 'Без ошибок' : item}</span>
													<br />
												</div>
											))}
										<br />
									</div>
									<span
										className='tableRow__showmore'
										onClick={() => setToggle(toggle === el.id ? 0 : el.id)}
									>
										{toggle === el.id ? 'скрыть' : 'показать все'}
									</span>
								</>
							)}
						</div>
						<div className={cn('tableRow__item')}>
							{!el.deleted_at && (
								<button
									onClick={() =>
										btnEdit(
											`Вы действительно хотите отменить изменения шлюза id: ${el.id} ?`,
											{ id: el.id }
										)
									}
									className='btn-status'
								>
									Отменить
								</button>
							)}
						</div>
					</div>
				))
			) : (
				<div className='tableRow'>
					<div className={cn('tableRow__item', 'tableRow__item--big')}>
						Логов нет
					</div>
				</div>
			)
		case 'productFiles':
			return content.map((el, idx) => (
				<div className='tableRow' key={el.id}>
					<div className='tableRow__item'>{el.id}</div>
					<div className='tableRow__item'>{el.name}</div>
					<div className='tableRow__item'>
						<Input
							value={el['name-fd'] ?? ''}
							onChange={e => {
								handleChange(idx, e.target.value, 'name-fd')
							}}
							type='text'
							inputCls='add-to-domain-payment-settings__input'
							disabled={el.disabled}
						/>
					</div>
					<div className='tableRow__item'>{el.number}</div>
					<div className='tableRow__item'>
						<Input
							value={el['number-fd'] ?? ''}
							onChange={e => {
								handleChange(idx, e.target.value, 'number-fd')
							}}
							type='number'
							inputCls='add-to-domain-payment-settings__input'
							disabled={el.disabled}
						/>
					</div>
					<div className={cn('tableRow__item', 'tableRow__item--big')}>
						{el.path}
					</div>
					<div className={cn('tableRow__item', 'tableRow__item--svg')}>
						{role.permissions.product.includes('edit_products') && (
							<>
								<span
									className='tableRow__svg-pen'
									onClick={() => btnEdit(idx)}
								>
									<Pen />
								</span>
								<span
									onClick={() =>
										btnDelete(
											`Вы уверены что удалить файл: ${el.name}?`,
											{ id: el.id },
											'deleteFile'
										)
									}
									className='tableRow__svg-bin'
								>
									<Bin />
								</span>
							</>
						)}
					</div>
				</div>
			))
		case 'productDomains':
			return content.map(el => (
				<div className='tableRow' key={el.id}>
					<div className='tableRow__item'>{el.id}</div>
					<div className='tableRow__item'>{el.name}</div>
					<div className='tableRow__item'>{el.link}</div>
				</div>
			))
		case 'promo':
			return content.map(el => (
				<div className='tableRow' key={el.id}>
					<div className='tableRow__item'>
						<Link
							to={`/promo-pages/page/${el.id}`}
							target='_blank'
							className={cn('tableRow__item')}
						>
							{el.id}
						</Link>
					</div>
					<div className='tableRow__item tableRow__item--charge2'>
						<Link to={`/product/edit/${el.productId}`}>{el.product}</Link>
					</div>
					<div className='tableRow__item tableRow__item--charge2'>
						{el.title}
					</div>
					<div className='tableRow__item tableRow__item--medium'>
						{el.durability}
					</div>
					<div className='tableRow__item tableRow__item--medium tableRow__item--svg'>
						<Link
							to={`/promo-pages/page/${el.id}`}
							className='tableRow__svg-pen'
						>
							<Pen />
						</Link>
						<span
							onClick={() =>
								btnDelete(
									'Вы действительно хотите удалить из promo-страницу?',
									{ id: el.id }
								)
							}
							className='tableRow__svg-bin'
						>
							<Bin />
						</span>
					</div>
				</div>
			))
		case 'orderPayments':
			return content.map(el => (
				<>
					<div className='tableRow' key={el.id}>
						<div className='tableRow__item'>{el.id}</div>
						<div className='tableRow__item tableRow__item--big'>
							{el.externalId}
						</div>
						<div className='tableRow__item tableRow__item--m'>
							{el.type === 7 || el.type === 6
								? ''
								: statusLabelsOrderInfo[el.status]}
						</div>
						<div className='tableRow__item'>{`${el.createdAt
							.slice(0, 10)
							.split('-')
							.reverse()
							.join('.')} | ${el.createdAt.slice(-8)}`}</div>
						<div className='tableRow__item'>{paymentsType(el.type)}</div>
						<div className='tableRow__item'>
							{el.type === 6 ? (
								''
							) : (
								<FormattedNumber
									value={el.amount}
									style='currency'
									currency={el.currency}
								/>
							)}
						</div>
						<div className='tableRow__item'>
							{!el.hasRefund && el.status === 1 && el.amount > 0 ? (
								<button onClick={() => handleEditRefundOrder(el)}>
									<RefundIcon />
								</button>
							) : null}
						</div>
						<div className='tableRow__item'>
							{(!el.hasRefund && el.status === 1 && el.amount > 0) ||
							el.refund.name === 'CHARGEBACK_ARBITRATION' ? (
								<button
									className='chargeback'
									onClick={() => handleEditChargebackOrder(el, 'chargeback')}
								>
									<RefundIcon />
								</button>
							) : null}
						</div>
						<div className='tableRow__item'>
							{(!el.hasRefund && el.status === 1 && el.amount > 0) ||
							(el.hasRefund &&
								el.refund.name === 'CHARGEBACK' &&
								el.status === 1 &&
								el.amount > 0) ? (
								<button
									className='arbitration'
									onClick={() => handleEditChargebackOrder(el, 'arbitration')}
								>
									<RefundIcon />
								</button>
							) : null}
						</div>
						<div className='tableRow__item'>{el.errorMessage}</div>
						<div className='tableRow__item'>{el.errorCode}</div>
						<div className='tableRow__item'>
							<Link
								to={`/gateway/edit/${el.paymentGatewayId}`}
								target='_blank'
								className={cn('tableRow__item')}
							>
								{el.paymentGatewayId}
							</Link>
						</div>
					</div>
				</>
			))
		case 'orderLogs':
		case 'fileLogs':
			return content.map(el => (
				<div className='tableRow' key={el.id}>
					<div className='tableRow__item'>{`${el.createdAt
						.slice(0, 10)
						.split('-')
						.reverse()
						.join('.')} ${el.createdAt.slice(-8)}`}</div>
					<div className='tableRow__item'>{el.ip}</div>
					<div className='tableRow__item tableRow__item--big'>{el.ua}</div>
				</div>
			))
		case 'authLogs':
			return content.map(el => (
				<div className='tableRow' key={el.id}>
					<div className='tableRow__item'>{el.id}</div>
					<div className='tableRow__item'>{`${el.createdAt
						.slice(0, 10)
						.split('-')
						.reverse()
						.join('.')} ${el.createdAt.slice(-8)}`}</div>
					<div className='tableRow__item'>{el.ip}</div>
					<div className='tableRow__item tableRow__item--big'>{el.ua}</div>
				</div>
			))
		case 'domainPaymentSettingsEdit':
			return content.map((el, i) => (
				<div className='tableRow' key={i}>
					<div className='tableRow__item'>{el.paymentSystem}</div>
					<div className='tableRow__item tableRow__item--big'>
						{el.paymentGateway}
					</div>
					<div className='tableRow__item tableRow__item--big'>{el.chance}</div>
					<div className='tableRow__item'>{el.noRef ? 'Вкл' : 'Выкл'}</div>
					<div className='tableRow__item'>{el.res ? 'Вкл' : 'Выкл'}</div>
					<div className='tableRow__item'>{el.sberbill ? 'Вкл' : 'Выкл'}</div>
					<div className='tableRow__item tableRow__item--svg'>
						<span onClick={() => btnDelete(i)} className='tableRow__svg-bin'>
							<Bin />
						</span>
					</div>
				</div>
			))
		case 'domainPaymentSettings':
			return content.map((el, i) => (
				<div className='tableRow' key={i}>
					<div className='tableRow__item'>{el.paymentSystem}</div>
					<div className='tableRow__item tableRow__item--big'>
						<Select
							styles={selectStylesDomainEditPage}
							options={el.paymentGatewayOptions}
							formatOptionLabel={formatOptionLabel}
							value={el.paymentGatewayOptions.filter(
								option => option.value === el.paymentGateway
							)}
							onChange={option =>
								handleChange(i, option.value, 'paymentGateway')
							}
						/>
					</div>
					<div className='tableRow__item'>
						<Input
							value={el.chance}
							onChange={e => {
								handleChange(i, e.target.value, 'chance')
							}}
							placeholder='ВЕРОЯТНОСТЬ'
							type='text'
							inputCls='add-to-domain-payment-settings__input'
						/>
					</div>
					<div className='tableRow__item tableRow__item--big'>
						{el.isDirty ? 'Попал в грязный список' : null} <br />
						{el.whiteGatewayId && `Текущий шлюз ${el.whiteGatewayId}`}
					</div>
					<div className='tableRow__item'>
						<input
							checked={el.noRef}
							type='checkbox'
							onChange={e => {
								handleChange(i, e.target.checked, 'noRef')
							}}
						/>
					</div>
					<div className='tableRow__item'>
						<input
							checked={el.sberbill}
							type='checkbox'
							onChange={e => {
								handleChange(i, e.target.checked, 'sberbill')
							}}
						/>
					</div>
					<div className='tableRow__item tableRow__item--svg'>
						{role.permissions.domain.includes('edit_domains') && (
							<>
								<span
									onClick={() => btnDelete(i)}
									className='tableRow__svg-bin'
								>
									<Bin />
								</span>
							</>
						)}
					</div>
				</div>
			))
		case 'sberBill':
			return content.map((el, i) => (
				<div className='tableRow' key={i}>
					<div className='tableRow__item tableRow__item--sm'>{el.id}</div>
					<div className='tableRow__item tableRow__item--big'>{el.name}</div>
					<div className='tableRow__item tableRow__item--big'>{el.comment}</div>
					<div className='tableRow__item tableRow__item--big'>
						{el.companyName}
					</div>
					<div className='tableRow__item tableRow__item--big'>
						{el.paymentSystem}
					</div>
					<div className='tableRow__item tableRow__item--svg tableRow__item--m'>
						<Link to={`/gateway/edit/${el.id}`} className='tableRow__svg-pen'>
							<Pen />
						</Link>
						<span
							onClick={() =>
								btnDelete(
									'Вы действительно хотите удалить из чистого списка?',
									{ id: el.id }
								)
							}
							className='tableRow__svg-bin'
						>
							<Bin />
						</span>
					</div>
				</div>
			))
		case 'sberalert':
			return content.map(el => (
				<div className='tableRow shaker-settings' key={el.gateway_id}>
					<div className={cn('tableRow__item')}>
						<span
							className={
								el.status === 'Включен' ? 'status-active' : 'status-inactive'
							}
						></span>
						{el.status}
					</div>
					<div className={cn('tableRow__item', 'tableRow__item--stats')}>
						{el.gateway_name}
					</div>
					<div className={cn('tableRow__item')}>{el.total}</div>
					<div className={cn('tableRow__item', 'tableRow__item--stats')}>
						{el.active}
					</div>
					<div className={cn('tableRow__item', 'tableRow__item--stats')}>
						{el.frozen}
					</div>
					<div className={cn('tableRow__item')}>
						<button
							onClick={() => handleUpdateSberAlertButton(el)}
							className='btn-status'
						>
							{el.status === 'Включен' ? 'Выключить' : 'Включить'}
						</button>
					</div>
				</div>
			))
		case 'shaker-settings':
			return content.map(el => (
				<div className='tableRow shaker-settings' key={el.gateway_id}>
					<div className={cn('tableRow__item')}>
						<span
							className={
								el.status === 'Включен' ? 'status-active' : 'status-inactive'
							}
						></span>
						{el.status}
					</div>
					<div className={cn('tableRow__item', 'tableRow__item--stats')}>
						{el.gateway_name}
					</div>
					<div className={cn('tableRow__item')}>{el.total}</div>
					<div className={cn('tableRow__item', 'tableRow__item--stats')}>
						{el.active}
					</div>
					<div className={cn('tableRow__item', 'tableRow__item--stats')}>
						{el.frozen}
					</div>
					<div className={cn('tableRow__item')}>
						<button
							onClick={() => handleUpdateShakerButton(el)}
							className='btn-status'
						>
							{el.status === 'Включен' ? 'Выключить' : 'Включить'}
						</button>
					</div>
				</div>
			))
		case 'shaker-statistics':
			return content.map(el => (
				<div className='tableRow' key={el.id}>
					<div className={cn('tableRow__item tableRow__item--stats')}>
						{el.name}
					</div>
					<div className={cn('tableRow__item tableRow__item--stats')}>
						{el.cnt_success}
					</div>
					<div className={cn('tableRow__item tableRow__item--stats')}>
						{el.cnt_failure}
					</div>
					<div className={cn('tableRow__item')}>
						{el.cnt_failure != 0 && dateTo != null && dateFrom != null ? (
							<button
								onClick={() => downloadStatistics(el)}
								className='btn-status-css'
							>
								Скачать
							</button>
						) : null}
					</div>
				</div>
			))
		case 'gateway':
			return (
				<>
					<div className='tableRow tableRowCheck'>
						<input
							type='checkbox'
							name='selectAll'
							onChange={handleClickCheckSelectAll}
							checked={isCheckAll}
						/>
						<div className={cn('tableRow__item', 'tableRow__item--sm')}></div>
						<div
							className={cn(
								'tableRow__item',
								'tableRow__item--big',
								'checkAll__button'
							)}
						>
							Выбрать все
							<button disabled={!isCheck.length} onClick={handleClickStartEdit}>
								Редактировать
							</button>
						</div>
						<div className='tableRow__item'></div>
						<div className='tableRow__item'></div>
						<div className='tableRow__item'></div>
						<div className='tableRow__item tableRow__item--big'></div>
						<div className='tableRow__item'></div>
						<div className='tableRow__item'></div>
						<div className='tableRow__item'></div>
						<div className='tableRow__item'></div>
						<div className='tableRow__item'></div>
					</div>
					{content.map(el => (
						<div className='tableRow' key={el.id}>
							<input
								key={el.id}
								type='checkbox'
								name={el.name}
								id={el.id}
								onChange={handleClickCheck}
								checked={isCheck.includes(el.id)}
							/>
							<div className='tableRow__item tableRow__item--sm'>{el.id}</div>
							<div className='tableRow__item'>{el.paymentSystem}</div>
							<div className='tableRow__item'>{el.name}</div>
							<div className={cn('tableRow__item')}>
								<span
									className={cn('tableRow__item-btn', {
										'tableRow__item-btn--green': el.status === 1,
										'tableRow__item-btn--red': el.status === 2
									})}
								>
									{el.status === 1 ? 'Активный' : 'Неактивный'}
								</span>
							</div>
							<div className='tableRow__item tableRow__item--big'>
								{el.comment}
							</div>
							<div className='tableRow__item tableRow__item--big'>
								{
									stopRebillOption.filter(
										item => item.value === el.reccurentStopped
									)?.[0]?.label
								}
							</div>
							<div className='tableRow__item'>{el.url}</div>
							<div className='tableRow__item'>{el.companyName}</div>
							<div className='tableRow__item tableRow__item--m'>
								{el.bankName}
							</div>
							<div className='tableRow__item tableRow__item--m'>
								<span
									className={cn(
										'tableRow__item-btn',
										el.sberbill
											? 'tableRow__item-btn--green'
											: 'tableRow__item-btn--red'
									)}
								>
									{el.sberbill ? 'ON' : 'OFF'}
								</span>
							</div>
							<div className='tableRow__item tableRow__item--m'>
								<span
									className={cn(
										'tableRow__item-btn',
										el.sberroute
											? 'tableRow__item-btn--green'
											: 'tableRow__item-btn--red'
									)}
								>
									{el.sberroute ? 'ON' : 'OFF'}
								</span>
							</div>
							<div className='tableRow__item tableRow__item--m'>
								{
									rates?.filter(item => Number(item.value) === el.scheme)[0]
										.label
								}
							</div>
							<div className='tableRow__item'>
								{el.domains[0] && (
									<>
										<span>{el.domains[0].link}</span>
										<br />
									</>
								)}
								{el.domains[1] && (
									<>
										<span>{el.domains[1].link}</span>
										<br />
									</>
								)}
								{el.domains.length > 2 && (
									<>
										<div
											className={cn(
												'tableRow__showmore-text',
												toggle === el.id ? 'slide' : ''
											)}
										>
											{el.domains.slice(2).map(({ link }) => (
												<div key={`${el.id}-${link}`}>
													<span>{link}</span>
													<br />
												</div>
											))}
											<br />
										</div>
										<span
											className='tableRow__showmore'
											onClick={() => setToggle(toggle === el.id ? 0 : el.id)}
										>
											{toggle === el.id ? 'скрыть' : 'показать все'}
										</span>
									</>
								)}
							</div>
							<div className='tableRow__item tableRow__item--big'>
								{el.data?.shopId && <div>shopId: {el.data.shopId}</div>}
								{el.data?.secret && <div>secret: {el.data.secret}</div>}

								{el.data?.publicId && <div>publicId: {el.data.publicId}</div>}
								{el.data?.apiKey && <div>apiKey: {el.data.apiKey}</div>}

								{el.data?.merchant && <div>merchant: {el.data.merchant}</div>}
								{el.data?.pass && <div>pass: {el.data.pass}</div>}
								{el.data?.terminal && <div>terminal: {el.data.terminal}</div>}
								{el.data?.terminal_3ds && (
									<div>terminal_3ds: {el.data.terminal_3ds}</div>
								)}
							</div>
							{/* <div className='tableRow__item'>{el.applepay ? 'Да' : 'Нет'}</div> */}
							<div className='tableRow__item tableRow__item--svg tableRow__item--m'>
								{role.permissions.gateway.includes('edit_gateways') && (
									<Link
										to={`/gateway/edit/${el.id}`}
										className='tableRow__svg-pen'
									>
										<Pen />
									</Link>
								)}
								{el.deletable &&
									role.permissions.gateway.includes('edit_gateways') && (
										<span
											onClick={() =>
												btnDelete('Вы действительно хотите удалить шлюз?', {
													id: el.id
												})
											}
											className='tableRow__svg-bin'
										>
											<Bin />
										</span>
									)}
							</div>
						</div>
					))}
				</>
			)
		case 'gateway-statistics':
			return content.map(el => (
				<div className='tableRow' key={el.gateway_id.toString()}>
					<div className='tableRow__item tableRow__item--sm'>
						{el.gateway_id}
					</div>
					<div className={cn('tableRow__item')}>
						<span
							className={cn('tableRow__item-btn', {
								'tableRow__item-btn--green': el.status === '1',
								'tableRow__item-btn--red': el.status === '2'
							})}
						>
							{el.status === '1' ? 'Активный' : 'Неактивный'}
						</span>
					</div>
					<div className='tableRow__item'>
						{el.date
							? `${el.date
									.slice(0, 10)
									.split('-')
									.reverse()
									.join('.')} ${el.date.slice(-8)}`
							: null}
					</div>
					<div className='tableRow__item tableRow__item--big'>
						{el.logs[0] && (
							<>
								<span>
									{el.logs[0].slice(0, 10).split('-').reverse().join('.')}{' '}
									{el.logs[0].slice(10)}
								</span>
								<br />
							</>
						)}
						{el.logs[1] && (
							<>
								<span>
									{el.logs[1].slice(0, 10).split('-').reverse().join('.')}{' '}
									{el.logs[1].slice(10)}
								</span>
								<br />
							</>
						)}
						{el.logs.length > 2 && (
							<>
								<div
									className={cn(
										'tableRow__showmore-text',
										toggle === el.gateway_id ? 'slide' : ''
									)}
								>
									{el.logs.slice(2).map((log, index) => (
										<div key={`${el.id}-${index}`}>
											<span>
												{log.slice(0, 10).split('-').reverse().join('.')}{' '}
												{log.slice(10)}
											</span>
											<br />
										</div>
									))}
									<br />
								</div>
								<span
									className='tableRow__showmore'
									onClick={() =>
										setToggle(toggle === el.gateway_id ? 0 : el.gateway_id)
									}
								>
									{toggle === el.gateway_id ? 'скрыть' : 'показать все'}
								</span>
							</>
						)}
					</div>
					<div className='tableRow__item tableRow__item--big'>
						{el.percent_sucess_rebills}
					</div>
					<div className='tableRow__item tableRow__item--big'>
						{el.time_leave_sberbill[0] && (
							<>
								<span>{el.time_leave_sberbill[0]}</span>
								<br />
							</>
						)}
						{el.time_leave_sberbill[1] && (
							<>
								<span>{el.time_leave_sberbill[1]}</span>
								<br />
							</>
						)}
						{el.time_leave_sberbill.length > 2 && (
							<>
								<div
									className={cn(
										'tableRow__showmore-text',
										toggleSecond === el.gateway_id ? 'slide' : ''
									)}
								>
									{el.time_leave_sberbill?.slice(2).map(
										(time_leave, index) =>
											time_leave && (
												<div key={`${el.gateway_id}-${index}`}>
													<span>{time_leave}</span>
													<br />
												</div>
											)
									)}
									<br />
								</div>
								<span
									className='tableRow__showmore'
									onClick={() =>
										setToggleSecond(
											toggleSecond === el.gateway_id ? 0 : el.gateway_id
										)
									}
								>
									{toggleSecond === el.gateway_id ? 'скрыть' : 'показать все'}
								</span>
							</>
						)}
					</div>
					<div className='tableRow__item'>{el.percent_unsubscribe}</div>
					<div className='tableRow__item tableRow__item--m'>
						{el.count_bills}
					</div>
					<div className='tableRow__item tableRow__item--m'>
						{el.count_rebills}
					</div>
					<div className='tableRow__item'>
						<input type='checkbox' disabled={true} checked={el.sberbill} />
					</div>
					<div className='tableRow__item'>
						<input type='checkbox' disabled={true} checked={el.sberroute} />
					</div>
				</div>
			))
		case 'sberOut':
			return content?.map((el, idx) => (
				<div className='tableRow' key={el.id.toString()}>
					<div className='tableRow__item tableRow__item--sm'>{idx + 1}</div>
					<div className='tableRow__item tableRow__item--sm'>{el.id}</div>
					<div className='tableRow__item'>{el.rating}</div>
					<div className='tableRow__item'>{el.paymentSystem}</div>
					<div className='tableRow__item'>{el.name}</div>
					<div className='tableRow__item'>{el.companyName}</div>
					<div className='tableRow__item tableRow__item--big'>
						{el.data?.shopId && <div>shopId: {el.data.shopId}</div>}
						{el.data?.secret && <div>secret: {el.data.secret}</div>}

						{el.data?.publicId && <div>publicId: {el.data.publicId}</div>}
						{el.data?.apiKey && <div>apiKey: {el.data.apiKey}</div>}

						{el.data?.merchant && <div>merchant: {el.data.merchant}</div>}
						{el.data?.pass && <div>pass: {el.data.pass}</div>}
						{el.data?.terminal && <div>terminal: {el.data.terminal}</div>}
						{el.data?.terminal_3ds && (
							<div>terminal_3ds: {el.data.terminal_3ds}</div>
						)}
					</div>
				</div>
			))
		default:
			return <div className='tableRow'>Ошибка</div>
	}
}

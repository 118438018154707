import React from 'react'
import './FilterInput.scss'

const FilterInput = ({
	type = 'text',
	onChange,
	value,
	disabled,
	placeholder,
	label
}) => (
	<div className='wrapper'>
		<label>
			<span>{label}</span>
			<input
				type={type}
				onChange={onChange}
				value={value}
				disabled={disabled}
				placeholder={placeholder}
			/>
		</label>
	</div>
)

export default FilterInput

import { request } from 'api'

export const getDataFromArrayIds = async (arr, api) => {
	const data = []

	const results = await Promise.all(
		arr.map(id => {
			return request.get(`${api}/${id}`)
		})
	)

	results.forEach(result => {
		data.push(result.data)
	})

	return data
}

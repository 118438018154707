import React from 'react'
import './selectWrapper.scss'

const SelectWrapper = ({ children, label }) => {
	const labelContentRef = React.useRef(null)
	const onClickLabel = React.useCallback(event => {
		if (event.nativeEvent.target !== labelContentRef.current) {
			event.preventDefault()
		}
	}, [])

	return (
		<div className='selectWrapper'>
			<label onClick={onClickLabel}>
				<span ref={labelContentRef}>{label}</span>
				{children}
			</label>
		</div>
	)
}

export default SelectWrapper

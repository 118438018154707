import { createSlice } from '@reduxjs/toolkit'
import { request } from 'api'
import axios from 'axios'

const initialState = {
	list: [],
	categories: [],
	errors: null,
	curProduct: {},
	redirect: false,
	isLoad: false,
	productStatusList: [],
	filters: {
		status: '1'
	},
	massUpdateStatus: ''
}

const productSlice = createSlice({
	name: 'product',
	initialState,
	reducers: {
		setIsLoad(state, action) {
			state.isLoad = action.payload
		},
		setProductsListAll(state, action) {
			state.isLoad = false
			state.list = action.payload
		},
		setProductCategories(state, action) {
			state.isLoad = false
			state.categories = action.payload
		},
		setProductMassUpdateStatus(state, action) {
			state.isLoad = false
			state.massUpdateStatus = action.payload
		},
		setProductMassUpdateError(state, action) {
			state.isLoad = false
			state.errors = action.payload
		},
		setProductCreateSuccess(state) {
			state.isLoad = false
			state.redirect = true
		},
		setProductCreateError(state, action) {
			state.errors = action.payload
			state.isLoad = false
		},
		setProductDuplicate(state, action) {
			state.isLoad = false
			state.list = action.payload
		},
		setProductUpdate(state) {
			state.isLoad = false
			state.redirect = true
		},
		setProductUpdateFailure(state, action) {
			state.errors = action.payload
		},
		setProductStatuses(state, action) {
			state.productStatusList = Object.keys(action.payload).map(el => ({
				value: el,
				label: action.payload[el]
			}))
		},
		setProductGetOne(state, action) {
			state.isLoad = false
			state.errors = {}
			state.curProduct = { ...action.payload }
		},
		setProductGetOneFailure(state) {
			state.isLoad = false
			state.errors = { product: 'не найден' }
			state.curProduct = {}
		},
		setProductFilters(state, action) {
			state.filters.status = action.payload.status
		},
		setProductCleanErrors(state, action) {
			state.errors =
				Object.keys(state.errors).length > 1
					? Object.fromEntries(
							Object.entries(state.errors).filter(
								el => !el.includes(action.payload)
							)
					  )
					: null
		},
		setProductCleanRedirect(state) {
			state.redirect = false
		}
	}
})

const {
	reducer: product,
	actions: {
		setIsLoad,
		setProductsListAll,
		setProductMassUpdateError,
		setProductCategories,
		setProductMassUpdateStatus,
		setProductCreateSuccess,
		setProductCreateError,
		setProductDuplicate,
		setProductUpdate,
		setProductUpdateFailure,
		setProductStatuses,
		setProductGetOne,
		setProductGetOneFailure,
		setProductFilters,
		setProductCleanErrors,
		setProductCleanRedirect
	}
} = productSlice

export const productGetAll = params => async dispatch => {
	dispatch(setIsLoad(true))
	request
		.get('/admin/products', { params })
		.then(res => {
			dispatch(setProductsListAll(res.data))
		})
		.catch(() => dispatch(setIsLoad(false)))
}

export const productCategoriesGetAll = () => async dispatch => {
	dispatch(setIsLoad(true))
	request
		.get('/admin/categories')
		.then(res => {
			dispatch(setProductCategories(res.data))
		})
		.catch(() => dispatch(setIsLoad(false)))
}

export const productMassUpdate = payload => async dispatch => {
	dispatch(setIsLoad(true))
	request
		.put(`/admin/products/batch/category`, payload)
		.then(() => {
			dispatch(setProductMassUpdateStatus('success'))
		})
		.catch(err => {
			dispatch(setProductMassUpdateError(err.response?.data?.error?.message))
			dispatch(setProductMassUpdateStatus('error'))
		})
}

/**
 *
 * @api  /admin/files/{type}/{id}
 * @type product или file
 * @content-type multipart/form-data
 * @formKey cover or background
 * @id product or file id
 */
export const productImage = (id, file, type) => async dispatch => {
	const formData = new FormData()
	formData.append(type, file) // cover or background
	const config = {
		headers: {
			'content-type': 'multipart/form-data'
		}
	}
	dispatch(setIsLoad(true))
	request
		.post(`/admin/files/product/${id}`, formData, config)
		.then(() => {
			dispatch(setIsLoad(false))
		})
		.catch(() => {
			dispatch(setIsLoad(false))
		})
}

export const productCreate = payload => async dispatch => {
	const { background, cover, ...fixPayload } = payload
	fixPayload.price =
		isNaN(Number(fixPayload.price)) || !fixPayload.price
			? 0
			: +(+fixPayload.price).toFixed(2)
	fixPayload.prepaidPrice =
		isNaN(Number(fixPayload.prepaidPrice)) || !fixPayload.prepaidPrice
			? null
			: +(+fixPayload.prepaidPrice).toFixed(2)
	fixPayload.initialPrice =
		isNaN(Number(fixPayload.initialPrice)) || !fixPayload.initialPrice
			? 0
			: +(+fixPayload.initialPrice).toFixed(2)
	fixPayload.initialPriceAMD =
		isNaN(Number(fixPayload.initialPriceAMD)) || !fixPayload.initialPriceAMD
			? 0
			: +(+fixPayload.initialPriceAMD).toFixed(2)
	fixPayload.oldPrice =
		isNaN(Number(fixPayload.oldPrice)) || !fixPayload.oldPrice
			? 0
			: +(+fixPayload.oldPrice).toFixed(2)
	fixPayload.displayPrice =
		isNaN(Number(fixPayload.displayPrice)) || !fixPayload.displayPrice
			? 0
			: +(+fixPayload.displayPrice).toFixed(2)
	fixPayload.displayOldPrice =
		isNaN(Number(fixPayload.displayOldPrice)) || !fixPayload.displayOldPrice
			? 0
			: +(+fixPayload.displayOldPrice).toFixed(2)
	fixPayload.recurrentPrice =
		isNaN(Number(fixPayload.recurrentPrice)) || !fixPayload.recurrentPrice
			? 0
			: +fixPayload.recurrentPrice
	fixPayload.initialInterval =
		isNaN(Number(fixPayload.initialInterval)) || !fixPayload.initialInterval
			? 0
			: +fixPayload.initialInterval
	fixPayload.followingInterval =
		isNaN(Number(fixPayload.followingInterval)) || !fixPayload.followingInterval
			? 0
			: +fixPayload.followingInterval
	dispatch(setIsLoad(true))
	request
		.post('/admin/products', fixPayload)
		.then(res => {
			if (background && cover) {
				dispatch(productImage(res.data.id, background, 'background'))
				dispatch(productImage(res.data.id, cover, 'cover'))
				dispatch(productGetAll())
				dispatch(setProductCreateSuccess())
			} else if (background) {
				dispatch(productImage(res.data.id, background, 'background'))
				dispatch(productGetAll())
				dispatch(setProductCreateSuccess())
			} else if (cover) {
				dispatch(productImage(res.data.id, cover, 'cover'))
				dispatch(productGetAll())
				dispatch(setProductCreateSuccess())
			} else {
				dispatch(productGetAll())
				dispatch(setProductCreateSuccess())
			}
		})
		.catch(err => {
			dispatch(setProductCreateError(err.response?.data?.error?.messages))
		})
}

export const productDuplicate = productId => async dispatch => {
	dispatch(setIsLoad(true))
	request
		.post(`admin/products/duplicate`, { productId })
		.then(() => {
			dispatch(productGetAll())
			dispatch(setProductDuplicate())
		})
		.catch(() => {
			dispatch(setIsLoad(false))
		})
}

export const productDelete = id => async dispatch => {
	dispatch(setIsLoad(true))
	request
		.delete(`/admin/products/${id}`)
		.then(() => {
			dispatch(productGetAll())
		})
		.catch(() => {
			dispatch(setIsLoad(false))
		})
}

export const productGetOne =
	(id = 0) =>
	async dispatch => {
		const fixId = isNaN(id) ? 0 : id
		dispatch(setIsLoad(true))
		axios
			.all([
				request.get('/admin/products/statuses'),
				request.get(`/admin/products/${fixId}`)
			])
			.then(
				axios.spread((statuses, product) => {
					dispatch(setProductStatuses(statuses.data))
					dispatch(setProductGetOne(product.data))
				})
			)
			.catch(() => dispatch(setProductGetOneFailure()))
	}

export const productUpdate = (id, payload) => async dispatch => {
	const { background, cover, ...fixPayload } = payload
	fixPayload.price =
		isNaN(Number(fixPayload.price)) || !fixPayload.price
			? 0
			: +(+fixPayload.price).toFixed(2)
	fixPayload.initialPrice =
		isNaN(Number(fixPayload.initialPrice)) || !fixPayload.initialPrice
			? 0
			: +(+fixPayload.initialPrice).toFixed(2)
	fixPayload.initialPriceAMD =
		isNaN(Number(fixPayload.initialPriceAMD)) || !fixPayload.initialPriceAMD
			? 0
			: +(+fixPayload.initialPriceAMD).toFixed(2)
	fixPayload.oldPrice =
		isNaN(Number(fixPayload.oldPrice)) || !fixPayload.oldPrice
			? 0
			: +(+fixPayload.oldPrice).toFixed(2)
	fixPayload.displayPrice =
		isNaN(Number(fixPayload.displayPrice)) || !fixPayload.displayPrice
			? 0
			: +(+fixPayload.displayPrice).toFixed(2)
	fixPayload.displayOldPrice =
		isNaN(Number(fixPayload.displayOldPrice)) || !fixPayload.displayOldPrice
			? 0
			: +(+fixPayload.displayOldPrice).toFixed(2)
	fixPayload.initialInterval =
		isNaN(Number(fixPayload.initialInterval)) || !fixPayload.initialInterval
			? 0
			: +fixPayload.initialInterval
	fixPayload.followingInterval =
		isNaN(Number(fixPayload.followingInterval)) || !fixPayload.followingInterval
			? 0
			: +fixPayload.followingInterval
	dispatch(setIsLoad(true))
	request
		.put(`/admin/products/${id}`, fixPayload)
		.then(() => {
			if (background && cover) {
				dispatch(productImage(id, background, 'background'))
				dispatch(productImage(id, cover, 'cover'))
				dispatch(productGetAll())
				dispatch(setProductUpdate())
			} else if (background) {
				dispatch(productImage(id, background, 'background'))
				dispatch(productGetAll())
				dispatch(setProductUpdate())
			} else if (cover) {
				dispatch(productImage(id, cover, 'cover'))
				dispatch(productGetAll())
				dispatch(setProductUpdate())
			} else {
				dispatch(productGetAll())
				dispatch(setProductUpdate())
			}
		})
		.catch(err => {
			dispatch(setProductUpdateFailure(err.response?.data?.error?.messages))
		})
		.finally(() => dispatch(setIsLoad(false)))
}

export {
	product,
	setProductFilters,
	setProductMassUpdateStatus,
	setProductCleanErrors,
	setProductCleanRedirect,
	setProductUpdateFailure
}

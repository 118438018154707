import * as React from 'react'
import Select from 'react-select'
import Header from 'assets/components/Header/Header'
import { CustomInput } from 'assets/components/CustomInput/CustomInput'
import { useAppDispatch } from 'store'
import './PromoPage.scss'
import SelectWrapper from 'assets/components/SelectWrapper'
import { FieldLabel } from 'assets/components/FieldLabel/FieldLabel'
import { productGetAll } from 'store/slices/product'
import { useAppSelector } from 'store'
import { createPromoPage, deletePromoCard } from 'store/slices/promo'
import { useHistory, useParams } from 'react-router-dom'
import {
	getPromoPage,
	createPromoCards,
	editPromoCard,
	editPromoPage,
	sendCoverPromo,
	sendCoverCard,
	setPromoPage
} from 'store/slices/promo'
import Loader from 'assets/components/Loader'
import { ReactComponent as DeleteIcon } from 'assets/img/delete.svg'
import { FileInput } from './FileInput/FileInput'

function getFileName(url, includeExtension) {
	var matches =
		url &&
		typeof url.match === 'function' &&
		url.match(/\/?([^/.]*)\.?([^/]*)$/)
	if (!matches) return null

	if (includeExtension && matches.length > 2 && matches[2]) {
		return matches.slice(1).join('.')
	}
	return matches[1]
}

const findDifferentObjects = (a, b) => {
	const differentObjects = []
	a.forEach((obj, index) => {
		if (
			JSON.stringify(Object.values(obj).sort()) !==
			JSON.stringify(Object.values(b[index]).sort())
		) {
			differentObjects.push(obj)
		}
	})

	return differentObjects
}

const initialState = {
	productId: '',
	title: '',
	firstWeek: '',
	durability: '',
	mainDescription: '',
	certificateName: '',
	certificateCredentials: ''
}

const courseFor = {
	title: '',
	text: '',
	type: 'courseFor'
}

const afterCourse = {
	title: '',
	text: '',
	type: 'afterCourse'
}

const callToAction = {
	title: '',
	text: '',
	type: 'callToAction'
}

export const PromoCrtPage = () => {
	const dispatch = useAppDispatch()
	const history = useHistory()
	const { promoId } = useParams()

	const promoPage = useAppSelector(state => state.promo.promoPage)
	const productList = useAppSelector(state => state.product.list)?.map(
		product => ({ value: product.id, label: product.innerName })
	)
	const [promoValue, setPromoValue] = React.useState(initialState)
	const [coverFile, setCoverFile] = React.useState(null)
	const [registrationFile, setRegistrationFile] = React.useState(null)
	const [courseForCard, setCourseForCard] = React.useState([
		courseFor,
		courseFor,
		courseFor
	])
	const [afterCourseCard, setAfterCourseCard] = React.useState([
		afterCourse,
		afterCourse,
		afterCourse
	])

	const [callToActionCard, setCallToActionCard] = React.useState([callToAction])
	const [cardsFile, setCardsFile] = React.useState([])
	const [isLoad, setIsLoad] = React.useState(false)

	const initialCardsFile = React.useMemo(() => {
		return promoPage?.descriptions
			?.filter(card => card.type === 'courseFor')
			.map(card => ({
				id: card.id,
				file: card.coverUrl || null
			}))
			.sort((a, b) => a.id > b.id)
	}, [promoPage])

	const regExpCourseFor = React.useMemo(() => {
		return {
			regExp: JSON.stringify(
				promoPage?.descriptions?.filter(card => card.type === 'courseFor')
			),
			initialCourseFor: promoPage?.descriptions
				?.filter(card => card.type === 'courseFor')
				.sort((a, b) => a.id > b.id)
		}
	}, [promoPage])

	const regExpAfterCourse = React.useMemo(() => {
		return {
			regExp: JSON.stringify(
				promoPage?.descriptions?.filter(card => card.type === 'afterCourse')
			),
			initialAfterCourse: promoPage?.descriptions
				?.filter(card => card.type === 'afterCourse')
				.sort((a, b) => a.id > b.id)
		}
	}, [promoPage])

	const regExpCallToAction = React.useMemo(() => {
		return {
			regExp: JSON.stringify(
				promoPage?.descriptions?.filter(card => card.type === 'callToAction')
			),
			initialCallToAction: promoPage?.descriptions
				?.filter(card => card.type === 'callToAction')
				.sort((a, b) => a.id > b.id)
		}
	}, [promoPage])

	React.useEffect(() => {
		dispatch(productGetAll())
	}, [dispatch])

	const handleCreate = () => {
		setIsLoad(true)
		if (promoId) {
			editPromoPage(promoId, {
				...promoValue,
				promoPageId: Number(promoId)
			}).then(() => setIsLoad(false))
		} else {
			dispatch(createPromoPage(promoValue)).then(resp =>
				history.push(`/promo-pages/page/${resp.data.id}`)
			)
		}
	}

	const handleAddCard = () => {
		setAfterCourseCard(prev => [...prev, afterCourse])
	}

	const handleDeleteCard = index => {
		if (afterCourseCard[index]?.id) {
			deletePromoCard(afterCourseCard[index].id)
			setAfterCourseCard(prev => prev.filter((_, idx) => idx !== index))
		} else {
			setAfterCourseCard(prev => prev.filter((_, idx) => idx !== index))
		}
	}

	const handleCreateCardsCourseFor = async () => {
		setIsLoad(true)
		const newCards = [...courseForCard].filter(card => !card.id)
		const initialCards = [...courseForCard]
			.filter(card => card.id)
			.sort((a, b) => a.id > b.id)
		if (!!initialCards.length) {
			const updateCards = findDifferentObjects(
				initialCards,
				regExpCourseFor.initialCourseFor
			)?.map(card => ({
				promoPageId: Number(promoId),
				title: card.title,
				text: card.text,
				promoPageDescriptionId: Number(card.id),
				type: card.type
			}))
			if (!!updateCards.length) {
				for (let i = 0; i < updateCards.length; i++) {
					await new Promise((resolve, reject) => {
						setTimeout(
							() =>
								editPromoCard(
									updateCards[i].promoPageDescriptionId,
									updateCards[i]
								).then(() => resolve()),
							500
						)
					})
				}
				setIsLoad(false)
			}
		}
		if (!!newCards.length) {
			const data = newCards.map(card => ({
				...card,
				promoPageId: Number(promoId)
			}))

			for (let i = 0; i < data.length; i++) {
				await new Promise((resolve, reject) => {
					setTimeout(() => createPromoCards(data[i]).then(() => resolve()), 500)
				})
			}
			setIsLoad(false)
		}
		dispatch(getPromoPage(promoId))
	}

	const handleCreateCardsCourseAfter = async () => {
		setIsLoad(true)
		const newCards = [...afterCourseCard].filter(card => !card.id)
		const initialCards = [...afterCourseCard].filter(card => card.id)

		if (!!initialCards.length) {
			const updateCards = findDifferentObjects(
				initialCards,
				regExpAfterCourse.initialAfterCourse
			)?.map(card => ({
				promoPageId: Number(promoId),
				title: card.title,
				text: card.text,
				promoPageDescriptionId: Number(card.id),
				type: card.type
			}))
			if (!!updateCards.length) {
				for (let i = 0; i < updateCards.length; i++) {
					await new Promise((resolve, reject) => {
						setTimeout(
							() =>
								editPromoCard(
									updateCards[i].promoPageDescriptionId,
									updateCards[i]
								).then(() => resolve()),
							500
						)
					})
				}
				setIsLoad(false)
			}
		}

		if (!!newCards.length) {
			const data = newCards.map(card => ({
				...card,
				promoPageId: Number(promoId)
			}))

			for (let i = 0; i < data.length; i++) {
				await new Promise((resolve, reject) => {
					setTimeout(() => createPromoCards(data[i]).then(() => resolve()), 500)
				})
			}
			setIsLoad(false)
		}

		dispatch(getPromoPage(promoId))
	}

	const handleCreateCardsCallToAction = async () => {
		setIsLoad(true)
		const newCards = [...callToActionCard].filter(card => !card.id)
		const initialCards = [...callToActionCard].filter(card => card.id)

		if (!!initialCards.length) {
			const updateCards = findDifferentObjects(
				initialCards,
				regExpCallToAction.initialCallToAction
			)?.map(card => ({
				promoPageId: Number(promoId),
				title: card.title,
				text: card.text,
				promoPageDescriptionId: Number(card.id),
				type: card.type
			}))
			if (!!updateCards.length) {
				for (let i = 0; i < updateCards.length; i++) {
					await new Promise((resolve, reject) => {
						setTimeout(
							() =>
								editPromoCard(
									updateCards[i].promoPageDescriptionId,
									updateCards[i]
								).then(() => resolve()),
							500
						)
					})
				}
				setIsLoad(false)
			}
		}

		if (!!newCards.length) {
			const data = newCards.map(card => ({
				...card,
				promoPageId: Number(promoId)
			}))

			for (let i = 0; i < data.length; i++) {
				await new Promise((resolve, reject) => {
					setTimeout(() => createPromoCards(data[i]).then(() => resolve()), 500)
				})
			}
			setIsLoad(false)
		}

		dispatch(getPromoPage(promoId))
	}

	React.useEffect(() => {
		if (promoId) {
			dispatch(getPromoPage(promoId))
		}
		return () => {
			dispatch(setPromoPage(null))
		}
	}, [promoId, dispatch])

	React.useEffect(() => {
		if (promoPage) {
			setPromoValue({
				productId: promoPage.productId,
				title: promoPage.title,
				firstWeek: promoPage.firstWeek,
				durability: promoPage.durability,
				mainDescription: promoPage.mainDescription,
				certificateName: promoPage.certificateName,
				certificateCredentials: promoPage.certificateCredentials
			})
			setCourseForCard(
				promoPage?.descriptions?.filter(card => card.type === 'courseFor')
					.length
					? promoPage?.descriptions
							?.filter(card => card.type === 'courseFor')
							.sort((a, b) => a.id > b.id)
					: Array.from({ length: 3 }, _ => {
							return courseFor
					  })
			)
			setAfterCourseCard(
				promoPage?.descriptions?.filter(card => card.type === 'afterCourse')
					.length
					? promoPage?.descriptions?.filter(card => card.type === 'afterCourse')
					: Array.from({ length: 3 }, _ => {
							return afterCourse
					  })
			)
			setCallToActionCard(
				promoPage?.descriptions?.filter(card => card.type === 'callToAction')
					.length
					? promoPage?.descriptions?.filter(
							card => card.type === 'callToAction'
					  )
					: [callToAction]
			)
			setCardsFile(
				promoPage?.descriptions
					?.filter(card => card.type === 'courseFor')
					.map(card => ({
						id: card.id,
						file: card.coverUrl || null
					}))
					.sort((a, b) => a.id > b.id)
			)
			setRegistrationFile(promoPage?.registrationUrl)
			setCoverFile(promoPage?.coverUrl)
		}
	}, [promoPage])

	return (
		<>
			{isLoad && <Loader />}
			<Header
				title={
					promoId ? 'Редактирование промо-страницы' : 'Создание промо-страницы'
				}
			/>
			{promoId ? (
				<div className='promoPage__wrapper'>
					<div className='promoPage__container'>
						<div className='promoPage__fields'>
							<SelectWrapper label='Продукт'>
								<Select
									value={productList.find(
										o => o.value === promoValue.productId
									)}
									onChange={option =>
										setPromoValue(prevState => ({
											...prevState,
											productId: option.value
										}))
									}
									options={productList}
									isSearchable={true}
									placeholder='Продукт'
								/>
							</SelectWrapper>
							<CustomInput
								type='text'
								label='Заголовок курса'
								name='title'
								value={promoValue.title}
								onChange={event =>
									setPromoValue({ ...promoValue, title: event.target.value })
								}
							/>
							<CustomInput
								label='Первая неделя за'
								name='firstWeek'
								type='text'
								value={promoValue.firstWeek}
								onChange={event =>
									setPromoValue({
										...promoValue,
										firstWeek: event.target.value
									})
								}
							/>
							<CustomInput
								label='Длительность обучения'
								name='durability'
								type='text'
								value={promoValue.durability}
								onChange={event =>
									setPromoValue({
										...promoValue,
										durability: event.target.value
									})
								}
							/>
							<CustomInput
								label='Название курса для сертификата'
								name='certificateName'
								type='text'
								value={promoValue.certificateName}
								onChange={event =>
									setPromoValue({
										...promoValue,
										certificateName: event.target.value
									})
								}
							/>
							<CustomInput
								label='Имя и Фамилия для сертификата'
								name='certificateCredentials'
								type='text'
								value={promoValue.certificateCredentials}
								onChange={event =>
									setPromoValue({
										...promoValue,
										certificateCredentials: event.target.value
									})
								}
							/>
						</div>
						<div className='promoPage__fields'>
							<FieldLabel label='Описание курса '>
								<textarea
									value={promoValue.mainDescription}
									onChange={event =>
										setPromoValue({
											...promoValue,
											mainDescription: event.target.value
										})
									}
								/>
							</FieldLabel>
						</div>
					</div>
					<div className='promoPage__button'>
						<button onClick={handleCreate}>Сохранить</button>
					</div>
				</div>
			) : (
				<div>
					<div className='promoPage__wrapper'>
						<div className='promoPage__container'>
							<div className='promoPage__fields'>
								<SelectWrapper label='Продукт'>
									<Select
										value={productList.find(
											o => o.value === promoValue.productId
										)}
										onChange={option =>
											setPromoValue(prevState => ({
												...prevState,
												productId: option.value
											}))
										}
										options={productList}
										isSearchable={true}
										placeholder='Продукт'
									/>
								</SelectWrapper>
								<CustomInput
									type='text'
									label='Заголовок курса'
									name='title'
									value={promoValue.title}
									onChange={event =>
										setPromoValue({ ...promoValue, title: event.target.value })
									}
								/>
								<CustomInput
									label='Первая неделя за'
									name='firstWeek'
									type='text'
									value={promoValue.firstWeek}
									onChange={event =>
										setPromoValue({
											...promoValue,
											firstWeek: event.target.value
										})
									}
								/>
								<CustomInput
									label='Длительность обучения'
									name='durability'
									type='text'
									value={promoValue.durability}
									onChange={event =>
										setPromoValue({
											...promoValue,
											durability: event.target.value
										})
									}
								/>
								<CustomInput
									label='Название курса для сертификата'
									name='certificateName'
									type='text'
									value={promoValue.certificateName}
									onChange={event =>
										setPromoValue({
											...promoValue,
											certificateName: event.target.value
										})
									}
								/>
								<CustomInput
									label='Имя и Фамилия для сертификата'
									name='certificateCredentials'
									type='text'
									value={promoValue.certificateCredentials}
									onChange={event =>
										setPromoValue({
											...promoValue,
											certificateCredentials: event.target.value
										})
									}
								/>
							</div>
							<div className='promoPage__fields'>
								<FieldLabel label='Описание курса '>
									<textarea
										value={promoValue.mainDescription}
										onChange={event =>
											setPromoValue({
												...promoValue,
												mainDescription: event.target.value
											})
										}
									/>
								</FieldLabel>
							</div>
						</div>
						<div className='promoPage__button'>
							<button onClick={handleCreate}>Сохранить</button>
						</div>
					</div>
				</div>
			)}

			{promoId && promoPage && (
				<div className='promoPage__wrapper promoPage__button promoPage__fields'>
					<h2>Обложка:</h2>
					<FileInput
						file={
							(coverFile && getFileName(coverFile)) || coverFile?.name
								? coverFile
								: null
						}
						setFile={setCoverFile}
					>
						<button
							disabled={!coverFile || coverFile === promoPage?.coverUrl}
							onClick={async () => {
								setIsLoad(true)
								sendCoverPromo(promoId, 'cover', coverFile)
									.then(() => {
										dispatch(getPromoPage(promoId))
									})
									.finally(() => {
										setIsLoad(false)
									})
							}}
						>
							Отправить
						</button>
						{coverFile && coverFile !== promoPage.coverUrl && (
							<button onClick={() => setCoverFile(promoPage.coverUrl)}>
								Сбросить
							</button>
						)}
					</FileInput>
				</div>
			)}
			{promoId && (
				<>
					<div className='promoPage__wrapper'>
						<h2>Этот курс для тех:</h2>
						<div>
							{courseForCard.map((item, index) => {
								return (
									<div className='promoPage__courseCard' key={index}>
										<div>
											<h3>{index + 1} Карточка:</h3>
											<div className='promoPage__card'>
												<CustomInput
													label='Заголовок'
													name='title'
													type='text'
													value={item.title}
													onChange={event =>
														setCourseForCard(prev =>
															prev.map((item, idx) => {
																if (index === idx) {
																	return {
																		...item,
																		title: event.target.value
																	}
																}
																return item
															})
														)
													}
												/>
												<CustomInput
													label='Текст'
													name='text'
													type='text'
													value={item.text}
													onChange={event =>
														setCourseForCard(prev =>
															prev.map((item, idx) => {
																if (index === idx) {
																	return { ...item, text: event.target.value }
																}
																return item
															})
														)
													}
												/>
											</div>
										</div>
										<div>
											<div className='promoPage__button promoPage__fields'>
												<FileInput
													setFile={changes =>
														setCardsFile(prev =>
															prev.map((item, idx) => {
																if (index === idx) {
																	return { ...item, file: changes }
																}
																return item
															})
														)
													}
													file={
														(cardsFile[index]?.file &&
															getFileName(cardsFile[index]?.file)) ||
														cardsFile[index]?.file?.name
															? cardsFile[index]?.file
															: null
													}
												>
													<button
														disabled={
															!cardsFile[index]?.file ||
															initialCardsFile[index]?.file ===
																cardsFile[index]?.file
														}
														onClick={() => {
															setIsLoad(true)
															sendCoverCard(
																cardsFile[index]?.id,
																'cover',
																cardsFile[index]?.file
															)
																.then(() => dispatch(getPromoPage(promoId)))
																.finally(() => setIsLoad(false))
														}}
													>
														Отправить
													</button>
													{cardsFile[index]?.file &&
														initialCardsFile[index]?.file !==
															cardsFile[index]?.file && (
															<button
																onClick={() =>
																	setCardsFile(prev =>
																		prev.map((item, idx) => {
																			if (index === idx) {
																				return {
																					...item,
																					file: initialCardsFile[index]?.file
																				}
																			}
																			return item
																		})
																	)
																}
															>
																Сбросить
															</button>
														)}
												</FileInput>
											</div>
										</div>
									</div>
								)
							})}
						</div>
						<div className='promoPage__button'>
							<button
								disabled={courseForCard.find(
									item =>
										Object.values(item).includes('') ||
										regExpCourseFor.regExp === JSON.stringify(courseForCard)
								)}
								onClick={handleCreateCardsCourseFor}
							>
								Сохранить
							</button>
						</div>
					</div>

					<div className='promoPage__wrapper'>
						<h2>После курса вы:</h2>
						<div>
							{afterCourseCard.map((item, index) => {
								return (
									<React.Fragment key={index}>
										<h3>{index + 1} Карточка:</h3>
										<div className='promoPage__card'>
											<CustomInput
												label='Заголовок'
												name='title'
												type='text'
												value={item.title}
												onChange={event =>
													setAfterCourseCard(prev =>
														prev.map((item, idx) => {
															if (index === idx) {
																return {
																	...item,
																	title: event.target.value
																}
															}
															return item
														})
													)
												}
											/>
											<CustomInput
												label='Текст'
												name='text'
												type='text'
												value={item.text}
												onChange={event =>
													setAfterCourseCard(prev =>
														prev.map((item, idx) => {
															if (index === idx) {
																return { ...item, text: event.target.value }
															}
															return item
														})
													)
												}
											/>
											<div>
												<DeleteIcon onClick={() => handleDeleteCard(index)} />
											</div>
										</div>
									</React.Fragment>
								)
							})}
						</div>
						<div className='promoPage__button'>
							<button
								disabled={afterCourseCard.find(
									item =>
										Object.values(item).includes('') ||
										regExpAfterCourse.regExp === JSON.stringify(afterCourseCard)
								)}
								onClick={handleCreateCardsCourseAfter}
							>
								Сохранить
							</button>
							<button onClick={handleAddCard}>Добавить карточку</button>
						</div>
					</div>

					<div className='promoPage__wrapper'>
						<h2>Призыв к действию:</h2>
						<div>
							{callToActionCard.map((item, index) => {
								return (
									<React.Fragment key={index}>
										<div className='promoPage__card'>
											<CustomInput
												label='Заголовок'
												name='title'
												type='text'
												value={item.title}
												onChange={event =>
													setCallToActionCard(prev =>
														prev.map((item, idx) => {
															if (index === idx) {
																return {
																	...item,
																	title: event.target.value
																}
															}
															return item
														})
													)
												}
											/>
											<CustomInput
												label='Текст'
												name='text'
												type='text'
												value={item.text}
												onChange={event =>
													setCallToActionCard(prev =>
														prev.map((item, idx) => {
															if (index === idx) {
																return { ...item, text: event.target.value }
															}
															return item
														})
													)
												}
											/>
										</div>
									</React.Fragment>
								)
							})}
						</div>
						<div className='promoPage__button'>
							<button
								disabled={callToActionCard.find(
									item =>
										Object.values(item).includes('') ||
										regExpCallToAction.regExp ===
											JSON.stringify(callToActionCard)
								)}
								onClick={handleCreateCardsCallToAction}
							>
								Сохранить
							</button>
						</div>
					</div>
				</>
			)}
			{promoId && (
				<div className='promoPage__wrapper promoPage__button promoPage__fields'>
					<h2>Форма регистрации:</h2>
					<FileInput
						file={
							(registrationFile && getFileName(registrationFile)) ||
							registrationFile?.name
								? registrationFile
								: null
						}
						setFile={setRegistrationFile}
					>
						<button
							disabled={
								!registrationFile ||
								registrationFile === promoPage?.registrationUrl
							}
							onClick={() => {
								setIsLoad(true)
								sendCoverPromo(promoId, 'registration', registrationFile)
									.then(() => dispatch(getPromoPage(promoId)))
									.finally(() => setIsLoad(false))
							}}
						>
							Отправить
						</button>
						{registrationFile &&
							registrationFile !== promoPage?.registrationUrl && (
								<button
									onClick={() => setRegistrationFile(promoPage.registrationUrl)}
								>
									Сбросить
								</button>
							)}
					</FileInput>
				</div>
			)}
			<div className='promoPage__wrapper promoPage__button'>
				<button onClick={() => history.push('/promo-pages')}>
					Вернуться назад
				</button>
			</div>
		</>
	)
}

import * as React from 'react'
import './CustomInput.scss'

export const CustomInput = ({ name, label, ...rest }) => {
	return (
		<div className='input-wrapper'>
			<label htmlFor={name}>{label}</label>
			<input id={name} {...rest} />
		</div>
	)
}

import * as React from 'react'
import Table from 'assets/components/Table'
import Header from 'assets/components/Header'
import { useAppSelector, useAppDispatch } from 'store'
import { productGetAll } from 'store/slices/product'
import { promoGetAll, deletePromoPage } from 'store/slices/promo'
import { modalCleanData } from 'store/slices/modal'

const titlesPromo = [
	{
		name: 'id'
	},
	{
		name: 'продукт',
		mod: 'charge2'
	},
	{
		name: 'название',
		mod: 'charge2'
	},
	{
		name: 'длительность обучения',
		mod: 'medium'
	},
	{
		name: '...',
		mod: 'medium'
	}
]

export const PromoPage = () => {
	const dispatch = useAppDispatch()
	const modalStatus = useAppSelector(state => state.modal.modalStatus)
	const infoPayload = useAppSelector(state => state.modal.infoPayload)
	const availableEdit = useAppSelector(
		state => state.auth.role
	)?.permissions.product.includes('edit_products')
	const promoList = useAppSelector(state => state.promo.promoList)
	const productList = useAppSelector(state => state.product.list)
	const [content, setContent] = React.useState([])

	React.useEffect(() => {
		dispatch(productGetAll())
		dispatch(promoGetAll())
	}, [])

	React.useEffect(() => {
		if (!!promoList.length && !!productList.length) {
			setContent(
				promoList?.map(item => ({
					...item,
					product: productList.filter(el => el.id === item?.productId)[0]
						?.innerName
				}))
			)
		}
	}, [promoList, productList])

	React.useEffect(() => {
		if (modalStatus === 'yes') {
			dispatch(deletePromoPage(infoPayload.id))
			dispatch(modalCleanData())
		}
	}, [modalStatus, dispatch])

	return (
		<>
			<Header
				title='PROMO-страниц'
				text={availableEdit ? 'Добавить промо' : null}
				href={availableEdit ? '/promo-pages/create' : null}
			/>
			<Table titles={titlesPromo} content={content} keyProp='promo' />
		</>
	)
}

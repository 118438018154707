import * as React from 'react'
import { useDropzone } from 'react-dropzone'

export const FileInput = ({ children, file, setFile }) => {
	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			'image/*': []
		},
		multiple: false,
		onDrop: acceptedFiles => {
			setFile(acceptedFiles[0])
		}
	})

	return (
		<>
			{file && (
				<div>
					<img
						src={typeof file === 'string' ? file : URL.createObjectURL(file)}
						className='promoPage__img'
						alt='img'
					/>
				</div>
			)}
			<div className='promoPage__container'>
				<div {...getRootProps()}>
					<button>Выбрать файл</button>
					<input {...getInputProps()} />
				</div>
				{children}
			</div>
		</>
	)
}

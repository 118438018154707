import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { authFail } from 'actions/auth'
import store from 'store'
import { request } from 'api'
import { toast } from 'react-toastify'
import { IntlProvider } from 'react-intl'
/**
 * Sentry
 */
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

if (process.env.NODE_ENV === 'production') {
	// setup Sentry
	Sentry.init({
		dsn: `https://a3a1c83d43bb4714be14d3c70768b106@sentry.skill-pad.com/14`,
		integrations: [new Integrations.BrowserTracing()],
		tracesSampleRate: 0
	})
}
request.interceptors.response.use(null, err => {
	if (err.response.status === 401) {
		store.dispatch(authFail())
	}
	if (err.response.status === 403) {
		toast.error('Недостаточно прав')
	}

	return Promise.reject(err)
})

ReactDOM.render(
	<IntlProvider locale='ru' defaultLocale='ru'>
		<BrowserRouter>
			<Provider store={store}>
				<App />
			</Provider>
		</BrowserRouter>
	</IntlProvider>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
